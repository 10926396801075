export const teamIdDefaultIds = [
	{
		teamId: "RBG",
		teamName: "Redbud Group",
		defaultId: "d09dc456-bd6d-4a3e-8911-5408a0970a3e",
		logo_path: "/assets/companyLogos/RBG_logo.svg",
	},
	{
		teamId: "SCOTTM",
		teamName: "Atlanta Communities",
		defaultId: "48a667f4-2816-4056-9486-3fcfe43bd8b3",
		logo_path: "/assets/companyLogos/ScottM_logo.svg",
	},
	{
		teamId: "LINKD",
		teamName: "Link'd Realty",
		defaultId: "bc88a0ef-4e85-47cb-b008-aa4d3738a560",
		logo_path: "/assets/companyLogos/Linkd_logo.svg",
	},
	{
		teamId: "TBRE",
		teamName: "Tom Bramhall",
		defaultId: "12fcaf16-87e7-45fb-a7ce-25d1faa1b97f",
		logo_path: "/assets/companyLogos/TBRE_logo.svg",
	},
	{
		teamId: "CAMH",
		teamName: "My Townhome",
		defaultId: "01c9affc-b36e-42f1-bc6a-1a85bee3f304",
		logo_path: "/assets/companyLogos/MyTownHome_logo.svg",
	},
	{
		teamId: "WPFS",
		teamName: "White Pickett Fence Services",
		defaultId: "22260816-1589-4fed-9a46-cf029ebb425f",
		logo_path: "/assets/companyLogos/WPFS_logo.svg",
	},
]
