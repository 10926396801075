import React, { useEffect, useState, useContext } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useParams } from "react-router-dom"
import { auth, registerWithEmailAndPassword } from "../firebase"
import {
	Box,
	Center,
	Divider,
	Flex,
	Heading,
	Image,
	Link,
	Stack,
	Spinner,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext.js"
import { RegistrationFirstStep } from "../Components/RegistrationFirstStep"
import { RegistrationSecondStep } from "../Components/RegistrationSecondStep"
import { VendorRegistrationSecondStep } from "../Components/VendorRegistrationSecondStep"
import { LeadsCard } from "../Components/LeadsCard"
import { VendorCard } from "../Components/VendorCard"
import { ReferralCard } from "../Components/ReferralCard"
import { teamIdDefaultIds } from "../Options/DefaultTeamIds.js"

export const Register = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [user, loading] = useAuthState(auth)
	const { userPlus } = useContext(UserContext)
	const [step, setStep] = useState(1)
	const [formData, setFormData] = useState({
		email: null,
		password: null,
		userType: null,
		name: null,
		phoneNumber: null,
	})

	const register = () => {
		registerWithEmailAndPassword(formData, id)
	}

	const nextStep = async () => {
		setStep(2)
	}

	const handleChange = (name, e) => {
		setFormData({ ...formData, [name]: e })
	}

	useEffect(() => {
		if (loading) return
		if (
			user &&
			userPlus !== "pending" &&
			userPlus !== null &&
			userPlus.userType &&
			userPlus.userType !== null
		) {
			if (userPlus.userType === "vendor") {
				navigate("/job-requests")
			} else {
				navigate("/vendors")
			}
		}
	}, [user, loading, userPlus])

	if (loading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	const team = teamIdDefaultIds.find((team) => team.teamId === id) || null

	return (
		<>
			<Box
				ml={{ base: "10px", sm: "40px", md: "96px" }}
				my={{ base: "15px", md: "40px" }}
				display="flex"
				alignItems="flex-end"
			>
				<Link to="/">
					<Flex>
						<Image
							src={
								team
									? team.logo_path
									: process.env.PUBLIC_URL + "/assets/logo_with_name.svg"
							}
						/>{" "}
						{team ? (
							<>
								<Divider
									orientation="vertical"
									borderColor="coolGray.400"
									borderWidth={1}
									height="80px"
									ml="20px"
									mr="20px"
								/>
								<Image src={"/assets/logo_with_name.svg"} />
							</>
						) : null}
					</Flex>
				</Link>
			</Box>
			<Box display={{ md: "flex" }} alignItems={{ md: "stretch" }}>
				<Box flex={1}>
					{step === 1 && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							The home services exchange for real estate agents, vendors and
							homeowners!
						</Heading>
					)}
					{step === 2 && formData.userType === "client" && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							Get access to your real estate agent's preferred vendors for your
							home services needs!
						</Heading>
					)}
					{step === 2 && formData.userType !== "client" && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							Sign up for free to grow your business with Quiplo!
						</Heading>
					)}
					<Box
						ml={{ base: "10px", sm: " 40px", md: "96px" }}
						mr={{ base: "10px", sm: " 40px", md: "96px" }}
						my={{ base: "15px", md: "30px" }}
					>
						{step === 1 && (
							<RegistrationFirstStep
								formData={formData}
								nextStep={nextStep}
								handleChange={handleChange}
								id={id}
							/>
						)}
						{step === 2 && formData.userType !== "vendor" && (
							<RegistrationSecondStep
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								register={register}
								id={id}
							/>
						)}
						{step === 2 && formData.userType === "vendor" && (
							<VendorRegistrationSecondStep
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								register={register}
								id={id}
							/>
						)}
					</Box>
				</Box>
				<Box
					flex={1}
					style={{
						backgroundImage: `linear-gradient(to bottom, #fafafa 1%, rgba(245, 246, 252, 0.01)), url(${process.env.PUBLIC_URL}/assets/stock_house_login.png)`,
						backgroundSize: "cover",
						height: "100vh",
					}}
					pt={{ base: "20px", md: "0px" }}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						pr="10px"
						pl="10px"
					>
						<VendorCard />
						<LeadsCard />
						<ReferralCard />
					</Box>
				</Box>
			</Box>
		</>
	)
}
