import { useContext, useState, useRef, useEffect } from "react"
import {
	Divider,
	Flex,
	FormControl,
	FormLabel,
	FormHelperText,
	Input,
	Image,
	RadioGroup,
	Radio,
	Stack,
	Text,
	Box,
	Button,
} from "@chakra-ui/react"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from "../firebase"
import { Select } from "chakra-react-select"
import { teamIdDefaultIds } from "../Options/DefaultTeamIds"
import "../select.css"
import { CheckCircleIcon } from "@chakra-ui/icons"
import { ReactMultiEmail } from "react-multi-email"
import "react-multi-email/dist/style.css"
import "../email-input.css"
import { UserContext } from "../Contexts/UserContext"
import { AgentPayWallModal } from "./AgentPayWall"

export const InviteTypeForm = ({
	nextStep,
	setValue,
	setInviteEmails,
	value,
	inviteEmails,
	tcTeam,
	tcAgent,
	setTcTeam,
	setTcAgent,
	onSubmit,
}) => {
	const inputRef = useRef()
	const { userPlus } = useContext(UserContext)
	const [modal, setModal] = useState(false)
	const [focused, setFocused] = useState(false)
	const [teamMembersSelect, setTeamMembersSelect] = useState([])

	useEffect(() => {
		if (!tcTeam) {
			return
		} else {
			const getAgentsInfo = async () => {
				try {
					// Query users with the specified teamId
					const q = query(
						collection(db, "users"),
						where("teamId", "==", tcTeam),
						where("userType", "==", "agent")
					)
					const doc = await getDocs(q)

					const teamMembersSelect = doc.docs.map((doc) => {
						return {
							label: doc.get("name") || doc.get("email"),
							value: doc.get("id"),
						}
					})
					setTeamMembersSelect(teamMembersSelect)
				} catch (error) {
					console.error("Error fetching user emails:", error)
				}
			}
			getAgentsInfo()
		}
	}, [tcTeam])

	const handleTypeOfInviteChange = (newValue) => {
		setValue(newValue)
	}

	const teamSelectOptions =
		userPlus?.userType === "TC" &&
		userPlus?.tcTeams.map((team) => {
			const teamName = teamIdDefaultIds.find(
				(teamDefault) => teamDefault.teamId === team
			)
			return { value: team, label: teamName.teamName || team }
		})

	return (
		<>
			<FormControl mt="20px">
				<FormLabel>Who are you inviting?</FormLabel>
				{(userPlus?.userType === "agent" || userPlus?.userType === "TC") && (
					<RadioGroup
						value={value}
						onChange={(e) => handleTypeOfInviteChange(e)}
						mb="30px"
					>
						<Stack direction="column">
							<Radio value="agent">
								<Text>Agents</Text>
							</Radio>
							<Radio value="vendor">
								<Text>Vendors</Text>
							</Radio>
							<Radio value="client">
								<Text>Homeowners</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				)}
				{userPlus?.userType === "vendor" && (
					<RadioGroup
						value={value}
						onChange={(e) => handleTypeOfInviteChange(e)}
						mb="30px"
					>
						<Stack direction="column">
							<Radio value="agent">
								<Text>Agents</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				)}
			</FormControl>

			{(value === "client" || value === "agent") &&
				userPlus.userType === "TC" && (
					<>
						<FormControl mb="20px">
							<FormLabel display="flex">
								Select the team you are adding the {value} to
							</FormLabel>
							<Select
								placeholder="Select team"
								options={teamSelectOptions}
								value={teamSelectOptions.find(
									(option) => tcTeam === option.value
								)}
								onChange={(e) => setTcTeam(e.value)}
							/>
						</FormControl>
						{value === "client" && (
							<FormControl mb="20px">
								<FormLabel display="flex">
									Select the Homeowner's agent
								</FormLabel>
								<Select
									placeholder="Select agent"
									options={teamMembersSelect}
									value={teamMembersSelect.find(
										(option) => option.value === tcAgent
									)}
									onChange={(e) => setTcAgent(e.value)}
								/>
							</FormControl>
						)}
					</>
				)}
			{(value === "client" || value === "agent") && (
				<>
					<FormControl mb="10px">
						<FormLabel display="flex">
							{" "}
							<Image src="/assets/link.svg" />
							<Text my="auto" ml="10px">
								Share Invite Link
							</Text>{" "}
						</FormLabel>
						<Flex>
							{userPlus.isAdmin && userPlus.userType === "agent" && (
								<Input
									bgColor="white"
									ref={inputRef}
									value={`https://app.quiploteams.com/register/${userPlus.teamId}`}
									readOnly
								/>
							)}
							{userPlus.isAdmin && userPlus.userType === "TC" && (
								<Input
									bgColor="white"
									ref={inputRef}
									value={`https://app.quiploteams.com/register/${
										tcAgent ? tcAgent : tcTeam ? tcTeam : ""
									}`}
									readOnly
									isDisabled={tcTeam === null}
								/>
							)}
							{!userPlus.isAdmin && userPlus.userType === "agent" && (
								<Input
									bgColor="white"
									ref={inputRef}
									value={`https://app.quiploteams.com/register/${userPlus.id}`}
									readOnly
								/>
							)}
							<Button
								ml="10px"
								bgColor="black"
								color="white"
								onClick={() => {
									navigator.clipboard.writeText(inputRef.current.value)
								}}
							>
								Copy
							</Button>
						</Flex>
					</FormControl>

					<Box
						position="relative"
						padding={{ base: "10px", md: "25px" }}
						w="100%"
					>
						<Flex alignItems="center">
							<Divider flex="1" borderColor="#6D6E70" height="2px" />
							<Box bgColor="#fafafa" px="4" mx="2">
								OR
							</Box>
							<Divider flex="1" borderColor="#6D6E70" height="2px" />
						</Flex>
					</Box>
				</>
			)}
			{value === "client" &&
				userPlus.planName === null &&
				userPlus.userType !== "TC" && (
					<Box mb="40px" width="100%">
						<Box display="flex">
							<CheckCircleIcon color="blue.700" my="auto" mr="10px" />
							<Text color="blue.700" fontWeight="semibold" fontSize="lg">
								Upgrade your Quiplo account
							</Text>
						</Box>
						<Text color="gray.500" mt="10px">
							Invite clients to get access to your preferred vendors and stay
							top of mind.{" "}
						</Text>
						<Button
							onClick={() => setModal(true)}
							bgColor="white"
							borderRadius="full"
							border="1px"
							color="gray.500"
							borderColor="gray.500"
							mt="10px"
						>
							Upgrade
						</Button>
					</Box>
				)}
			{(value === "agent" ||
				((value === "client" || value === "agent") &&
					userPlus.userType === "TC") ||
				(value === "client" && userPlus.planName !== null)) && (
				<>
					<FormControl>
						<FormLabel display="flex">
							{" "}
							<Image src="/assets/mail.svg" />
							<Text my="auto" ml="10px">
								Email address
							</Text>{" "}
						</FormLabel>
						<ReactMultiEmail
							placeholder="Enter email address"
							emails={inviteEmails}
							onChange={(_emails: string[]) => {
								setInviteEmails(_emails)
							}}
							autoFocus={true}
							onFocus={() => setFocused(true)}
							onBlur={() => setFocused(false)}
							getLabel={(email, index, removeEmail) => {
								return (
									<div data-tag key={index}>
										<div data-tag-item>{email}</div>
										<span data-tag-handle onClick={() => removeEmail(index)}>
											×
										</span>
									</div>
								)
							}}
						/>
						<FormHelperText>
							You can paste multiple emails at once.
						</FormHelperText>
						{value === "client" && (
							<FormHelperText color="red.800">
								Clients will only get access to you and your team's preferred
								vendor list when they create a Quiplo account. Make sure you
								keep your preferred vendor list updated.{" "}
							</FormHelperText>
						)}
					</FormControl>

					<Button
						onClick={onSubmit}
						w="100%"
						bgColor="red.500"
						color="white"
						mt="40px"
						mb="40px"
					>
						Send Invite
					</Button>
				</>
			)}
			{value === "vendor" && (
				<Button
					onClick={nextStep}
					w="100%"
					bgColor="red.500"
					color="white"
					mt="40px"
				>
					Continue
				</Button>
			)}
			<AgentPayWallModal isOpen={modal} setPaywallTriggered={setModal} />
		</>
	)
}
