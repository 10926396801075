import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react"

export const JobRequestResponseAwaitingResponseMobileCard = ({ vendor }) => {
	return (
		<Card w="100%" mb="20px" key={vendor.id}>
			<CardHeader fontWeight="semibold">
				{vendor.name ? vendor.name : vendor.email}
			</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Vendor Status:</Text>
						<Text>Awaiting Response</Text>
					</Box>
				</Stack>
			</CardBody>
		</Card>
	)
}
