import { createContext, useEffect, useState, useMemo } from "react"
import {
	collection,
	onSnapshot,
	query,
	where,
	getDocs,
	updateDoc,
} from "firebase/firestore"
import { db, auth } from "../firebase"
import axios from "axios"
import config from "../config.js"
import { PLAN } from "../enums"

const UserContext = createContext()

const UserProvider = ({ children }) => {
	const [userPlus, setUserPlus] = useState("pending")
	const [cachedSubscriptionData, setCachedSubscriptionData] = useState(null)

	const fetchSubscriptionData = useMemo(
		() => async (userInfo) => {
			try {
				const userEmail = userInfo.email
				const subscriptionStatusResult = await axios.post(
					`${config.baseURL}/api/subscription-check`,
					{ userEmail }
				)

				if (userInfo.userType === "vendor") {
					const servicesQuery = query(
						collection(db, "services"),
						where("userId", "==", userInfo.id)
					)
					const servicesSnapshot = await getDocs(servicesQuery)
					const services = servicesSnapshot.docs.map((doc) => doc.data())
					if (
						(services.length !== 0 &&
							!subscriptionStatusResult?.data?.subscriptionInfo &&
							services[0].subscriptionStatusActive !== false) ||
						services[0]?.subscriptionStatusActive !==
							subscriptionStatusResult?.data?.subscriptionInfo?.active
					) {
						servicesSnapshot.docs.forEach(async (doc) => {
							await updateDoc(doc.ref, {
								subscriptionStatusActive: subscriptionStatusResult?.data
									.subscriptionInfo?.active
									? true
									: false,
							})
						})
					}
				}

				return subscriptionStatusResult.data
			} catch (error) {
				console.error("Error getting stripe status:", error)
				return null
			}
		},
		[] // Empty dependency array to ensure the memoized function doesn't change
	)

	useEffect(() => {
		let unsubscribeSnapshot
		const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
			if (unsubscribeSnapshot) {
				unsubscribeSnapshot()
			}

			if (!authUser) {
				return setUserPlus(null)
			}

			setUserPlus("pending")

			const userInformationQuery = query(
				collection(db, "users"),
				where("uid", "==", authUser.uid)
			)

			unsubscribeSnapshot = onSnapshot(
				userInformationQuery,
				async (snapshot) => {
					const userInfo = snapshot.docs[0] ? snapshot.docs[0].data() : null
					const userDocRef = snapshot.docs[0] ? snapshot.docs[0].ref : null

					let subscriptionStatus = null

					if (authUser && !cachedSubscriptionData) {
						subscriptionStatus = await fetchSubscriptionData(userInfo)
						setCachedSubscriptionData(subscriptionStatus)
					} else if (cachedSubscriptionData) {
						subscriptionStatus = cachedSubscriptionData
					}

					let tcTeams
					if (userInfo && userInfo.userType === "TC") {
						const tcQuery = query(
							collection(db, "transactionCoordinatorTeams"),
							where("userId", "==", userInfo.id)
						)
						const tcSnapshot = await getDocs(tcQuery)
						const tcData = tcSnapshot.docs.map((doc) => doc.data())
						tcTeams = tcData[0].teamIds
					}

					setUserPlus({
						...authUser,
						...userInfo,
						userDocRef,
						teamId: tcTeams
							? tcTeams[0]
							: userInfo?.teamId
							? userInfo?.teamId
							: null,
						...(tcTeams && { tcTeams: tcTeams }),
						activePlan:
							subscriptionStatus?.subscriptionInfo?.active || userInfo?.teamId
								? true
								: false,
						planName: userInfo?.teamId
							? PLAN.AGENT_ENTERPRISE
							: subscriptionStatus?.subscriptionInfo?.name
							? subscriptionStatus?.subscriptionInfo?.name
							: null,
					})

					// Update services if subscription status changes
					if (userInfo?.userType === "vendor" && subscriptionStatus) {
						updateServices(subscriptionStatus, userInfo.id)
					}
				}
			)
		})

		return () => {
			unsubscribe()
			if (unsubscribeSnapshot) {
				unsubscribeSnapshot()
			}
		}
	}, [fetchSubscriptionData, cachedSubscriptionData])

	const updateServices = async (subscriptionStatus, userId) => {
		try {
			const servicesQuery = query(
				collection(db, "services"),
				where("userId", "==", userId)
			)
			const servicesSnapshot = await getDocs(servicesQuery)
			const services = servicesSnapshot.docs.map((doc) => doc.data())

			if (
				(services.length !== 0 &&
					!subscriptionStatus?.subscriptionInfo &&
					services[0].subscriptionStatusActive !== false) ||
				services[0]?.subscriptionStatusActive !==
					subscriptionStatus?.subscriptionInfo?.active
			) {
				servicesSnapshot.docs.forEach(async (doc) => {
					await updateDoc(doc.ref, {
						subscriptionStatusActive: subscriptionStatus?.subscriptionInfo
							?.active
							? true
							: false,
					})
				})
			}
		} catch (error) {
			console.error("Error updating services:", error)
		}
	}

	return (
		<UserContext.Provider value={{ userPlus, setUserPlus }}>
			{children}
		</UserContext.Provider>
	)
}

export { UserProvider, UserContext }
