import { PLAN } from "./enums"
export const dateToFormat = (firebaseTimestamp) => {
	const milliseconds =
		firebaseTimestamp.seconds * 1000 +
		Math.floor(firebaseTimestamp.nanoseconds / 1e6)
	const dateObject = new Date(milliseconds)
	const formattedDate = dateObject.toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	})
	return formattedDate
}

export const convertToAMPM = (time24) => {
	// Parse the time string and create a Date object
	const timeParts = time24.split(":")
	const hours = parseInt(timeParts[0], 10)
	const minutes = parseInt(timeParts[1], 10)
	const time = new Date(0, 0, 0, hours, minutes)

	// Format the time in AM/PM format
	const timeAMPM = time.toLocaleTimeString("en-US", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	})

	return timeAMPM
}

export const checkVendorSubscriptionServiceLimit = (
	userPlus,
	serviceTypesLength
) => {
	if (userPlus.planName === PLAN.VENDOR_BASE || userPlus.planName === null) {
		return userPlus.numberOfServices + serviceTypesLength <= 2
	}
	if (userPlus.planName === PLAN.VENDOR_GROWING) {
		return userPlus.numberOfServices + serviceTypesLength <= 4
	} else {
		return true
	}
}

export const formatPhoneNumber = (phoneNumber) => {
	// Remove all non-digit characters from the phone number
	let cleaned = ("" + phoneNumber).replace(/\D/g, "")

	// Remove the "1" at the beginning of the phone number if it exists
	if (cleaned.startsWith("1")) {
		cleaned = cleaned.substring(1)
	}

	// Format the phone number
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		return "(" + match[1] + ") " + match[2] + "-" + match[3]
	}
	return null // Return null if the phone number doesn't match the expected format
}
