import { useEffect, useContext } from "react"

import { useNavigate } from "react-router-dom"
import { UserContext } from "../../Contexts/UserContext.js"
import AgentJobRequestListView from "./AgentJobRequestListView"
import ClientJobRequestListView from "./ClientJobRequestListView"
import { VendorJobRequestListView } from "./VendorJobRequestListView.js"

export const JobRequestListWrapper = () => {
	const navigate = useNavigate()
	const { userPlus } = useContext(UserContext)

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}
		if (userPlus === null) {
			navigate("/login")
		}
	}, [userPlus])

	return (
		<>
			{userPlus?.userType === "vendor" && (
				<VendorJobRequestListView userPlus={userPlus} />
			)}
			{(userPlus?.userType === "agent" || userPlus?.userType === "TC") && (
				<AgentJobRequestListView userPlus={userPlus} />
			)}
			{userPlus?.userType === "client" && (
				<ClientJobRequestListView userPlus={userPlus} />
			)}
		</>
	)
}
