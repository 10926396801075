import { useState, useContext } from "react"
import {
	Box,
	Card,
	CardBody,
	Center,
	Text,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Button,
	Heading,
} from "@chakra-ui/react"
import { logUserEvent } from "../firebase"
import { analytic_events } from "../analytics"
import { UserContext } from "../Contexts/UserContext"

export const VendorPayWallModal = ({
	isOpen,
	setPaywallTriggered,
	location,
}) => {
	const { userPlus } = useContext(UserContext)
	const [selectedCard, setSelectedCard] = useState(null)
	const onClose = async () => {
		await logUserEvent(
			analytic_events.VENDOR_CLOSE_PAYWALL_MODAL,
			userPlus.id,
			{
				location: location,
			}
		)
		setPaywallTriggered(false)
	}

	const handleCardSelection = async (selection) => {
		await logUserEvent(
			analytic_events.VENDOR_PAYWALL_CARD_SELECTION,
			userPlus.id,
			{
				selection: selection,
				location: location,
			}
		)
		setSelectedCard(selection)
	}

	const handleContinue = async () => {
		await logUserEvent(analytic_events.VENDOR_STRIPE_CTA_CLICK, userPlus.id, {
			userId: userPlus.id,
			selection: selectedCard,
			location: location,
		})
	}
	const prodStripeUrls = [
		{ value: "Base", url: "https://buy.stripe.com/cN2dTRcRTgBa3xCaEJ" },
		{ value: "Growing", url: "https://buy.stripe.com/eVa8zxbNP0Cc6JOaEK" },
		{ value: "Scaling", url: "https://buy.stripe.com/28oaHFg4584E2ty28f" },
	]

	const testStripeUrls = [
		{ value: "Base", url: "https://buy.stripe.com/test_5kAdRc82Q10T1PO3cd" },
		{ value: "Growing", url: "https://buy.stripe.com/test_dR63cygzmaBt0LKfZ0" },
		{ value: "Scaling", url: "https://buy.stripe.com/test_6oE3cy5UI8tl1PO3cg" },
	]

	const stripeUrls =
		process.env.NODE_ENV === "development" ? testStripeUrls : prodStripeUrls
	return (
		<Modal onClose={onClose} size="full" isOpen={isOpen}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Center mt="30px">
						<Image src="/assets/logo_with_name.svg" />
					</Center>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Heading size="md" fontWeight="semibold" mb="10px" align="center">
						Unlock premium features to grow your business
					</Heading>
					<Heading size="sm" mb="20px" align="center">
						Choose the best plan to continue using Quiplo for your business.
						When you subscribe your business is marked verified so that agents
						and homeowners are confident you will be responsive to job requests.
					</Heading>
					<Box display={{ lg: "flex" }} justifyContent="center">
						<Box onClick={() => handleCardSelection("Base")}>
							<Card
								border={
									selectedCard === "Base"
										? "1px solid #EE5257"
										: "1px solid #E2E8F0"
								}
								mb="20px"
								maxW={{ lg: "318px" }}
								mr={{ lg: "25px" }}
							>
								<CardBody>
									<Box>
										<Heading fontWeight="bold">BASE</Heading>
										<Box display="flex">
											<Text fontWeight="bold" fontSize="lg">
												$24.99
											</Text>
											<Text>/mo</Text>
										</Box>
									</Box>
									<Text color="coolGray.600" mt="5px" mb="10px">
										Best for skilled vendors and small businesses who want to
										receive & respond to unlimited job requests for up to 2
										services they offer.
									</Text>
									<Box display="flex">
										<Image src="/assets/check.svg" />
										<Text ml="10px">Up to 2 Services</Text>
									</Box>
								</CardBody>
							</Card>
						</Box>
						<Box onClick={() => handleCardSelection("Growing")}>
							<Card
								border={
									selectedCard === "Growing"
										? "1px solid #EE5257"
										: "1px solid #E2E8F0"
								}
								mb="20px"
								maxW={{ lg: "318px" }}
								mr={{ lg: "25px" }}
							>
								<CardBody>
									<Box>
										<Heading fontWeight="bold">GROWING</Heading>
										<Box display="flex">
											<Text fontWeight="bold" fontSize="lg">
												$34.99
											</Text>
											<Text>/mo</Text>
										</Box>
									</Box>
									<Text color="coolGray.600" mt="5px" mb="10px">
										Best for skilled vendors and small businesses who want to
										receive & respond to unlimited job requests for up to 4
										services they offer.
									</Text>
									<Box display="flex">
										<Image src="/assets/check.svg" />
										<Text ml="10px">Up to 4 Services</Text>
									</Box>
								</CardBody>
							</Card>
						</Box>
						<Box onClick={() => handleCardSelection("Scaling")}>
							<Card
								border={
									selectedCard === "Scaling"
										? "1px solid #EE5257"
										: "1px solid #E2E8F0"
								}
								mb="20px"
								maxW={{ lg: "318px" }}
							>
								<CardBody>
									<Box>
										<Heading fontWeight="bold">SCALING</Heading>
										<Box display="flex">
											<Text fontWeight="bold" fontSize="lg">
												$49.99
											</Text>
											<Text>/mo</Text>
										</Box>
									</Box>
									<Text color="coolGray.600" mt="5px" mb="10px">
										Best for skilled vendors and small businesses who want to
										receive & respond to unlimited job requests for all of their
										skilled services.
									</Text>
									<Box display="flex">
										<Image src="/assets/check.svg" />
										<Text ml="10px">UNLIMITED Services</Text>
									</Box>
								</CardBody>
							</Card>
						</Box>
					</Box>
					<Center mt="20px">
						<Box m="auto" maxW="325px" width="100%">
							<Text align="center">Cancel subscription anytime.</Text>
							<a
								href={
									stripeUrls.find((item) => item.value === selectedCard)?.url
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Button
									color="white"
									onClick={handleContinue}
									background="red.500"
									mt="10px"
									width="100%"
									mb="40px"
								>
									CONTINUE
								</Button>
							</a>
						</Box>
					</Center>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
