import {
	Box,
	Button,
	Card,
	CardHeader,
	CardBody,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react"
import { formatPhoneNumber } from "../Utilities"

export const ServicesMobileCard = ({
	service,
	index,
	editService,
	removeService,
}) => {
	return (
		<Card w="100%" mb="20px" key={index}>
			<CardHeader fontWeight="semibold">{service.serviceType}</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Text style={{ wordWrap: "break-word" }}>
						{" "}
						{service.contactEmail && service.contactEmail}
					</Text>
					<Text>
						{service.phoneNumber && formatPhoneNumber(service.phoneNumber)}
					</Text>

					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Job Responses:</Text>
						<Text>
							{service.jobResponseCount ? service.jobResponseCount : 0}
						</Text>
					</Box>
				</Stack>
				<Button onClick={() => removeService(service)} w="100%" mt="20px">
					Remove Service
				</Button>
				<Button onClick={editService(service)} w="100%" mt="10px">
					Edit
				</Button>
			</CardBody>
		</Card>
	)
}
