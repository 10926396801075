import { Card, Image, Container, Heading, Text } from "@chakra-ui/react"

export const LeadsCard = () => {
	return (
		<Card mt="20px" display="flex" flexDirection="row" maxW="xl" p="20px">
			<Image
				src={process.env.PUBLIC_URL + "/assets/clipboard_icon.svg"}
				alt="Task Icon"
				w="65px"
				h="65px"
			/>
			<Container>
				<Heading size="md" fontWeight="bold" pb="5px">
					Grow your business as a Skilled Vendor
				</Heading>
				<Text>
					Grow your business with trusted leads, repeat business, and NO lead
					fees.
				</Text>
			</Container>
		</Card>
	)
}
