module.exports = {
	PLAN: {
		AGENT_GROWING: "GROWING - For Agents",
		AGENT_ENTERPRISE: "ENTERPRISE - For Agents",
		VENDOR_BASE: "BASE - For Vendor",
		VENDOR_GROWING: "GROWING - For Vendor",
		VENDOR_SCALING: "SCALING - For Vendor",
	},
	JOB_REQUEST_LIMIT: 5,
}
