export const analytic_events = {
	USER_CREATION: "USER_CREATION",
	INVITE_SENT: "INVITE_SENT",
	USER_REGISTRATION: "USER_REGISTRATION",
	JOB_REQUEST_CREATED: "JOB_REQUEST_CREATED",
	SERVICE_CREATED: "SERVICE_CREATED",
	AGENT_CLIENT_UNSCHEDULED: "AGENT_CLIENT_UNSCHEDULED",
	AGENT_CLIENT_SCHEDULED: "AGENT_CLIENT_SCHEDULED",
	JOB_RESPONSE_SUBMITTED: "JOB_RESPONSE_SUBMITTED",
	OPEN_PAYWALL: "OPEN_PAYWALL",
	VENDOR_CLOSE_PAYWALL_MODAL: "VENDOR_CLOSE_PAYWALL_MODAL",
	VENDOR_PAYWALL_CARD_SELECTION: "VENDOR_PAYWALL_CARD_SELECTION",
	VENDOR_STRIPE_CTA_CLICK: "VENDOR_STRIPE_CTA_CLICK",
	AGENT_CLOSE_PAYWALL_MODAL: "AGENT_CLOSE_PAYWALL_MODAL",
	AGENT_PAYWALL_CARD_SELECTION: "AGENT_PAYWALL_CARD_SELECTION",
	AGENT_STRIPE_CTA_CLICK: "AGENT_STRIPE_CTA_CLICK",
	USER_SERVICE_FILTER_SEARCH_VENDOR_PAGE:
		"USER_SERVICE_FILTER_SEARCH_VENDOR_PAGE",
}
