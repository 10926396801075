export const serviceTypes = [
	{ service: "Carpet Cleaning" },
	{ service: "Floor Cleaning" },
	{ service: "House Cleaning" },
	{ service: "Window Cleaning" },
	{ service: "Awnings" },
	{ service: "Chimney Cap Repair" },
	{ service: "Chimney Repair" },
	{ service: "Chimney Sweep" },
	{ service: "Concrete Driveways" },
	{ service: "Concrete Repair" },
	{ service: "Deck Staining & Cleaning" },
	{ service: "Decks & Porches Install & Repair" },
	{ service: "Driveway Gate Installation" },
	{ service: "Driveway Pavers" },
	{ service: "Excavating" },
	{ service: "Fencing" },
	{ service: "Garage Building" },
	{ service: "Garage Door Installation and Repair" },
	{ service: "Gutter Cleaning" },
	{ service: "Gutter Repair" },
	{ service: "Gutter Installation" },
	{ service: "Masonry" },
	{ service: "Outdoor Kitchens" },
	{ service: "Patio Enclosures" },
	{ service: "Patios" },
	{ service: "Pressure Washing" },
	{ service: "Stamped Concrete" },
	{ service: "Window Replacement" },
	{ service: "Window Treatment" },
	{ service: "Crawl Space Repair" },
	{ service: "Crawl Space Encapsulation" },
	{ service: "Exterior Painting" },
	{ service: "Carpet Installation" },
	{ service: "Epoxy Flooring" },
	{ service: "Floor Buffing" },
	{ service: "Hardwood Floor Installation & Repair" },
	{ service: "Foundation Repair" },
	{ service: "Mudjacking" },
	{ service: "Structural Engineering" },
	{ service: "Air Duct Cleaning" },
	{ service: "HVAC Installation & Repair" },
	{ service: "Dryer Vent Cleaning" },
	{ service: "General Inspection" },
	{ service: "Roof Inspection" },
	{ service: "Chimney Inspection" },
	{ service: "Electrical Inspection" },
	{ service: "Crawl Space Inspection" },
	{ service: "Lead-Based Paint Inspection" },
	{ service: "HVAC Inspection" },
	{ service: "Well & Septic Inspection" },
	{ service: "Pest Inspection" },
	{ service: "Radon Inspection" },
	{ service: "Soil Inspection" },
	{ service: "Foundation Inspection" },
	{ service: "Mold Inspection" },
	{ service: "Pool & Spa Inspection" },
	{ service: "Plumbing Inspection" },
	{ service: "Closet Design" },
	{ service: "Drywall Installation & Repair" },
	{ service: "Furniture Refinishing" },
	{ service: "Professional Organizers" },
	{ service: "Wallpaper Hanger" },
	{ service: "Wallpaper Removal" },
	{ service: "Home Staging" },
	{ service: "Interior Painting" },
	{ service: "Measurements" },
	{ service: "Hardscaping" },
	{ service: "Irrigation System" },
	{ service: "Landscape Lighting" },
	{ service: "Landscape Design" },
	{ service: "Lawn Care" },
	{ service: "Lawn Treatment" },
	{ service: "Leaf Removal" },
	{ service: "Mulch Delivering" },
	{ service: "Nurseries" },
	{ service: "Stone & Gravel" },
	{ service: "Tree Service" },
	{ service: "Standard Photography" },
	{ service: "Videographer" },
	{ service: "Matterport" },
	{ service: "Drain Cleaning" },
	{ service: "Drain Pipe Installation & Repair" },
	{ service: "Gas Leak Repair" },
	{ service: "Hot Water Heater Installation & Repair" },
	{ service: "General Plumbing" },
	{ service: "Pool Cleaning" },
	{ service: "Pool Installers" },
	{ service: "Roof Cleaning" },
	{ service: "General Roofing" },
	{ service: "General Contractor" },
	{ service: "Electrician" },
	{ service: "Septic & Well Repair" },
	{ service: "Handyman" },
	{ service: "Demolition" },
	{ service: "Trash Removal" },
	{ service: "Pest Control" },
	{ service: "Appliance Repair" },
	{ service: "Appliance Installation" },
	{ service: "Locksmith" },
	{ service: "Mold Remediation" },
	{ service: "Biohazard Cleanup" },
	{ service: "Lead Paint Removal" },
	{ service: "Asbestos Removal" },
	{ service: "Insulation Install" },
	{ service: "Solar Panel Installation" },
	{ service: "Plastering" },
	{ service: "Cabinet Replacement & Refacing" },
	{ service: "Countertop Installation" },
	{ service: "Bathroom Remodel" },
	{ service: "Kitchen Remodel" },
	{ service: "Basement Remodeling" },
	{ service: "Bathtub Refinishing" },
	{ service: "Home Remodeling" },
	{ service: "Stucco" },
	{ service: "Tile Installation" },
	{ service: "Water Damage Restoration" },
	{ service: "Closings" },
	{ service: "Title" },
	{ service: "Popcorn Ceiling Removal" },
	{ service: "Property Managers" },
	{ service: "Shower Glass" },
	{ service: "Carpentry" },
	{ service: "Siding Repair & Installation" },
	{ service: "Screen Enclosures" },
	{ service: "Outdoor Drainage" },
	{ service: "Property Survey" },
	{ service: "Mortgage Lender" },
	{ service: "Closing Attorney" },
	{ service: "Home Warranty" },
	{ service: "Homeowner Insurance" },
	{ service: "Marketing Services" },
	{ service: "Oil Tank Inspection" },
	{ service: "Floor Installation & Repair" },
	{ service: "Moving Services" },
	{ service: "Termite Control" },
	{ service: "Appraiser" },
	{ service: "Interior Design" },
	{ service: "Water Quality Testing" },
	{ service: "Sewer Line Inspection" },
	{ service: "Radon Remediation" },
]

export const serviceTypesOptions = serviceTypes.map((s) => {
	return { label: s.service, value: s.service }
})
