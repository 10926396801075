import { Box, Text } from "@chakra-ui/react"

export const Footer = () => {
	return (
		<Box
			w="100%"
			bg="white"
			color="coolGray.600"
			py={10}
			textAlign="right"
			fontSize="sm"
			pr={{ base: "10px", lg: "80px" }}
		>
			<Text>© 2024 Relay Scheduling LLC dba Quiplo.</Text>
			<Text>All Rights Reserved.</Text>
			<Text>For questions, email contact@quiploteams.com.</Text>
		</Box>
	)
}
