import React, { useEffect, useState } from "react"
import { useNavigate, NavLink } from "react-router-dom"
import {
	Box,
	Center,
	Image,
	Heading,
	HStack,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Stack,
	Button,
	Text,
	Table,
	Tag,
	TagLabel,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	useDisclosure,
} from "@chakra-ui/react"
import { EditIcon, UpDownIcon } from "@chakra-ui/icons"
import { Select } from "chakra-react-select"
import {
	collection,
	endBefore,
	getDocs,
	query,
	where,
	startAfter,
	limit,
	limitToLast,
	orderBy,
} from "firebase/firestore"
import { db } from "../../firebase"

import { dateToFormat } from "../../Utilities"
import { EmptyState } from "../../Components/EmptyState"
import { VendorJobRequestListMobileCard } from "../../Components/VendorJobRequestListMobileCard"
import { PaywallBanner } from "../../Components/PaywallBanner"
import { SkeletonTable } from "../../Components/SkeletonTable"

export const VendorJobRequestListView = ({ userPlus }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()
	const [pageLoading, setPageLoading] = useState(true)
	const [filtered, setFiltered] = useState(false)
	const [page, setPage] = useState(1)
	const pageSize = 10
	const [lastVisible, setLastVisible] = useState(null)
	const [firstVisible, setFirstVisible] = useState(null)
	const [hasMore, setHasMore] = useState(true)
	const [direction, setDirection] = useState("next")
	const [jobRequests, setJobRequests] = useState([])
	const [jobRequestIds, setJobRequestIds] = useState([])
	const [jobResponsesRequestIds, setJobResponsesRequestIds] = useState([])
	const [clearFilters, setClearFilters] = useState(true)
	const [serviceTypeFilter, setServiceTypeFilter] = useState([])
	const [statusFilter, setStatusFilter] = useState("")
	const [serviceTypesOptions, setServiceTypesOptions] = useState([])
	const [vendorServices, setVendorServices] = useState([])

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}
		const fetchData = async () => {
			setPageLoading(true)
			try {
				const servicesQuery = query(
					collection(db, "services"),
					where("userId", "==", userPlus.id)
				)
				const servicesDocs = await getDocs(servicesQuery)
				const services = servicesDocs.docs.map((doc) => doc.data().serviceType)
				setVendorServices(services)

				const serviceTypesOptions = servicesDocs.docs.map((doc) => ({
					value: doc.data().serviceType,
					label: doc.data().serviceType,
				}))

				setServiceTypesOptions(serviceTypesOptions)

				const jobRequestsQuery = query(
					collection(db, "jobRequests"),
					where("vendorIds", "array-contains", userPlus.id),
					orderBy("_createdAt"),
					direction === "next"
						? startAfter(lastVisible)
						: endBefore(firstVisible),
					direction === "next" ? limit(pageSize + 1) : limitToLast(pageSize + 1)
				)

				const doc = await getDocs(jobRequestsQuery)
				if (!doc.empty) {
					setLastVisible(doc.docs[doc.docs.length - 1])
					setFirstVisible(doc.docs[0])
					setHasMore(doc.docs.length > pageSize)

					const jobRequestsData = doc.docs.slice(0, pageSize).map((doc) => ({
						id: doc.id,
						...doc.data(),
					}))
					setJobRequests(jobRequestsData)
					const jobRequestIds = doc.docs.map((doc) => doc.data().id)
					// all the ids of all the job reqs this vendor has been sent
					setJobRequestIds(jobRequestIds)
				}
				setPageLoading(false)
			} catch (error) {
				console.error("Error fetching data:", error)
			} finally {
				setPageLoading(false)
				if (clearFilters === true) {
					setClearFilters(false)
					setFiltered(false)
					setPage(1)
					setDirection("next")
					setLastVisible(null)
					resetFilters()
				}
			}
		}

		fetchData()
	}, [userPlus, clearFilters, page])

	const resetFilters = () => {
		setServiceTypeFilter([])
		setStatusFilter("")
		setServiceTypeFilter([])
	}

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}

		const getResponseIds = async () => {
			try {
				const jobResponseQuery = query(
					collection(db, "jobRequestResponses"),
					where("userId", "==", userPlus.id)
				)
				const jobResponseDocs = await getDocs(jobResponseQuery)
				const jobResponsesRequestIds = jobResponseDocs.docs.map((doc) => ({
					responseId: doc.data().id,
					requestId: doc.data().requestId,
				}))

				// ids of requests that this vendor has responded to
				setJobResponsesRequestIds(jobResponsesRequestIds)
			} catch (error) {
				console.error("Error fetching Job Request Responses:", error)
			}
		}
		getResponseIds()
	}, [userPlus])

	const getFilteredList = async () => {
		try {
			let filteredJobRequests = []
			// Create a base query with the common condition for isActive
			let baseQuery = query(
				collection(db, "jobRequests"),
				where("vendorIds", "array-contains", userPlus.id)
			)
			// Add conditions based on serviceTypeFilter
			if (serviceTypeFilter.length > 0) {
				baseQuery = query(
					baseQuery,
					where("serviceType", "in", serviceTypeFilter)
				)
			}
			const docs = await getDocs(baseQuery)
			filteredJobRequests = docs.docs.map((doc) => doc.data())

			if (statusFilter !== "") {
				if (statusFilter === "todo") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) =>
							!jobResponsesRequestIds.some(
								(response) => response.requestId === request.id
							) && !request.selectedResponseId
					)
				}
				if (statusFilter === "won") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) =>
							request.selectedResponseId &&
							jobResponsesRequestIds.some(
								(response) =>
									response.responseId === request.selectedResponseId &&
									response.requestId === request.id
							)
					)
				}
				if (statusFilter === "received") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) =>
							jobResponsesRequestIds.some(
								(response) => response.requestId === request.id
							) && !request.selectedResponseId
					)
				}
				if (statusFilter === "unsuccesful") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) =>
							request.selectedResponseId &&
							!jobResponsesRequestIds.some(
								(response) => response.responseId === request.selectedResponseId
							)
					)
				}
			}
			setFiltered(true)
			setJobRequests(filteredJobRequests)
			onClose()
		} catch (error) {
			console.error("Error filtering job requests:", error)
		}
	}

	const statusFilterOptions = [
		{ label: "Response Needed", value: "todo" },
		{ label: "Jobs Won", value: "won" },
		{ label: "Client Received Response", value: "received" },
		{ label: "Unsuccesful Job Response", value: "unsuccesful" },
	]

	const openFilters = async () => {
		onOpen()
	}

	const goToNext = () => {
		setPage(page + 1)
		setDirection("next")
	}

	const goToPrevious = () => {
		setPage(page - 1)
		setDirection("previous")
	}

	if (userPlus === null) {
		navigate("/login")
	}

	return (
		<>
			{userPlus.planName === null && (
				<PaywallBanner location="vendorJobRequestList" />
			)}
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					Job Requests
				</Heading>
				<HStack
					display={["none", "none", "flex", "flex"]}
					justifyContent="space-between"
					mt="10px"
				>
					<Text>Track and manage your open job requests</Text>
					<Box display="flex" justifyContent="flex-end" gap={4}>
						<Button onClick={() => setClearFilters(true)} mt="15px">
							Clear Filters
						</Button>
						<Button
							onClick={() => openFilters()}
							rightIcon={<UpDownIcon />}
							bgColor="blue.900"
							color="white"
							mt="15px"
						>
							Filter
						</Button>
					</Box>
				</HStack>
				<Box
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					flexDirection="column"
				>
					<Text mb="10px">Track and manage your open job requests</Text>
					<Box display="flex" justifyContent="flex-end" mb="20px">
						<Button
							onClick={() => openFilters()}
							rightIcon={<UpDownIcon />}
							bgColor="background"
							mt="15px"
						>
							Filter
						</Button>
						<Button onClick={() => setClearFilters(true)} mt="15px">
							Clear Filters
						</Button>
					</Box>
					{jobRequests.length === 0 && !filtered && (
						<EmptyState helperText="You have not received any job requests yet - invite your clients to start receiving job requests!" />
					)}
					{jobRequests.length === 0 && filtered && (
						<Center m="auto">
							<Stack spacing={3} alignItems="center">
								<Image
									src="../../assets/search_empty.png"
									h="200px"
									w="200px"
								/>
								<Heading fontWeight="semibold" size="lg">
									No results found
								</Heading>
								<Text align="center">
									There were not any results found matching your search or you
									are not connected with skilled vendors. Invite your vendors to
									join!
								</Text>
							</Stack>
						</Center>
					)}
					{jobRequests.map((jobRequest, index) => {
						return (
							<VendorJobRequestListMobileCard
								jobRequest={jobRequest}
								jobResponsesRequestIds={jobResponsesRequestIds}
								jobRequestIds={jobRequestIds}
								vendorServices={vendorServices}
								userPlus={userPlus}
								index={index}
								key={index}
							/>
						)
					})}
				</Box>
				<Box
					display={["none", "none", "flex", "flex"]}
					flexDirection="column"
					mt="30px"
				>
					{pageLoading && <SkeletonTable />}
					{!pageLoading && (
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>Job Info</Th>
									<Th>Service Type</Th>
									<Th>Preferred Dates</Th>
									<Th>Job Status</Th>
									<Th>Actions</Th>
								</Tr>
							</Thead>
							<Tbody>
								{jobRequests.map((jobRequest, index) => {
									if (
										filtered &&
										(!vendorServices.includes(jobRequest.serviceType) ||
											userPlus.planName === null)
									) {
										return null
									}
									return (
										<Tr key={index}>
											<Td
												filter="auto"
												blur={
													!vendorServices.includes(jobRequest.serviceType) ||
													userPlus.planName === null
														? "3px"
														: null
												}
											>
												<>
													<Text fontWeight="semibold">
														{jobRequest.locationAddress}
													</Text>
													<Text>{jobRequest.userEmail}</Text>
												</>
											</Td>
											<Td>{jobRequest.serviceType}</Td>
											<Td>
												{jobRequest?.servicePreferredDates &&
													jobRequest.servicePreferredDates.map(
														(entry, index) => (
															<HStack gap={5} mb="5px" key={index}>
																<Text key={index}>
																	{dateToFormat(entry.date)}
																</Text>
															</HStack>
														)
													)}
											</Td>
											<Td>
												{jobRequest.selectedResponseId &&
													jobResponsesRequestIds.some(
														(response) =>
															response.responseId ===
																jobRequest.selectedResponseId &&
															response.requestId === jobRequest.id
													) && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="green"
															mr="5px"
															mb="5px"
														>
															<TagLabel>
																Request to schedule -{" "}
																{dateToFormat(jobRequest.scheduleDate)}
															</TagLabel>
														</Tag>
													)}
												{jobResponsesRequestIds.some(
													(response) => response.requestId === jobRequest.id
												) &&
													!jobRequest.selectedResponseId && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="
														#FDE8C8"
															color="black"
															mr="5px"
															mb="5px"
														>
															<TagLabel>Client Received</TagLabel>
														</Tag>
													)}
												{!jobResponsesRequestIds.some(
													(response) => response.requestId === jobRequest.id
												) &&
													!jobRequest.selectedResponseId && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="
													red.500"
															mr="5px"
															mb="5px"
														>
															<TagLabel>Response Needed</TagLabel>
														</Tag>
													)}
												{jobRequest.selectedResponseId &&
													!jobResponsesRequestIds.some(
														(response) =>
															response.responseId ===
															jobRequest.selectedResponseId
													) && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="
													#gray.500"
															mr="5px"
															mb="5px"
														>
															<TagLabel>Unsuccessful</TagLabel>
														</Tag>
													)}
											</Td>
											<Td>
												{!jobResponsesRequestIds.some(
													(response) => response.requestId === jobRequest.id
												) && (
													<IconButton
														as={NavLink}
														bgColor="background"
														to={`/job-request/${jobRequest.id}`}
														aria-label="Respond to Job Request"
														icon={<EditIcon />}
													/>
												)}
											</Td>
										</Tr>
									)
								})}
							</Tbody>
						</Table>
					)}
					{jobRequests.length === 0 && !filtered && (
						<EmptyState helperText="You have not received any job requests yet - invite your clients to start receiving job requests!" />
					)}
					{jobRequests.length === 0 && filtered && (
						<Center m="auto">
							<Stack spacing={3} alignItems="center">
								<Image
									src="../../assets/search_empty.png"
									h="200px"
									w="200px"
								/>
								<Heading fontWeight="semibold" size="lg">
									No results found
								</Heading>
								<Text align="center">
									There were not any results found matching your search or you
									are not connected with skilled vendors. Invite your vendors to
									join!
								</Text>
							</Stack>
						</Center>
					)}
				</Box>
				{jobRequests.length > 0 && (
					<Box display="flex" justifyContent="flex-end" gap={3} my="15px">
						<Button
							onClick={() => goToPrevious()}
							isDisabled={page === 1 || filtered}
						>
							Previous
						</Button>
						<Button
							onClick={() => goToNext()}
							isDisabled={!hasMore || filtered}
						>
							Next
						</Button>
					</Box>
				)}
			</Box>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "full", lg: "2xl" }}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Filter Job Requests</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack gap={2}>
							<Box>
								<Select
									placeholder="Service Type"
									value={serviceTypesOptions.filter((option) =>
										serviceTypeFilter.includes(option.value)
									)}
									isMulti
									options={serviceTypesOptions}
									onChange={(options) => {
										setServiceTypeFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Job Request Status"
									value={statusFilterOptions.filter(
										(option) => option.value === statusFilter
									)}
									options={statusFilterOptions}
									onChange={(options) => {
										setStatusFilter(options.value)
									}}
								/>
							</Box>
						</Stack>
					</ModalBody>
					<ModalFooter margin="auto">
						<Button bgColor="red.500" color="white" onClick={getFilteredList}>
							Apply Filters
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
