export const NorthCarolinaLocations = [
	{
		city: "Charlotte",
		state: "NC",
	},
	{
		city: "Raleigh",
		state: "NC",
	},
	{
		city: "Winston-Salem",
		state: "NC",
	},
	{
		city: "Durham",
		state: "NC",
	},
	{
		city: "Greensboro",
		state: "NC",
	},
	{
		city: "Fayetteville",
		state: "NC",
	},
	{
		city: "Asheville",
		state: "NC",
	},
	{
		city: "Concord",
		state: "NC",
	},
	{
		city: "Wilmington",
		state: "NC",
	},
	{
		city: "Hickory",
		state: "NC",
	},
	{
		city: "Gastonia",
		state: "NC",
	},
	{
		city: "Cary",
		state: "NC",
	},
	{
		city: "High Point",
		state: "NC",
	},
	{
		city: "Burlington",
		state: "NC",
	},
	{
		city: "Greenville",
		state: "NC",
	},
	{
		city: "Jacksonville",
		state: "NC",
	},
	{
		city: "Chapel Hill",
		state: "NC",
	},
	{
		city: "Huntersville",
		state: "NC",
	},
	{
		city: "Apex",
		state: "NC",
	},
	{
		city: "Rocky Mount",
		state: "NC",
	},
	{
		city: "Kannapolis",
		state: "NC",
	},
	{
		city: "Mooresville",
		state: "NC",
	},
	{
		city: "Wilson",
		state: "NC",
	},
	{
		city: "Wake Forest",
		state: "NC",
	},
	{
		city: "Holly Springs",
		state: "NC",
	},
	{
		city: "Indian Trail",
		state: "NC",
	},
	{
		city: "Salisbury",
		state: "NC",
	},
	{
		city: "Monroe",
		state: "NC",
	},
	{
		city: "Goldsboro",
		state: "NC",
	},
	{
		city: "Fuquay-Varina",
		state: "NC",
	},
	{
		city: "New Bern",
		state: "NC",
	},
	{
		city: "Cornelius",
		state: "NC",
	},
	{
		city: "Garner",
		state: "NC",
	},
	{
		city: "Sanford",
		state: "NC",
	},
	{
		city: "Matthews",
		state: "NC",
	},
	{
		city: "Morrisville",
		state: "NC",
	},
	{
		city: "Statesville",
		state: "NC",
	},
	{
		city: "Thomasville",
		state: "NC",
	},
	{
		city: "Asheboro",
		state: "NC",
	},
	{
		city: "Kernersville",
		state: "NC",
	},
	{
		city: "Mint Hill",
		state: "NC",
	},
	{
		city: "Clayton",
		state: "NC",
	},
	{
		city: "Leland",
		state: "NC",
	},
	{
		city: "Shelby",
		state: "NC",
	},
	{
		city: "Carrboro",
		state: "NC",
	},
	{
		city: "Clemmons",
		state: "NC",
	},
	{
		city: "Kinston",
		state: "NC",
	},
	{
		city: "Waxhaw",
		state: "NC",
	},
	{
		city: "Lexington",
		state: "NC",
	},
	{
		city: "Lumberton",
		state: "NC",
	},
	{
		city: "Knightdale",
		state: "NC",
	},
	{
		city: "Elizabeth City",
		state: "NC",
	},
	{
		city: "Harrisburg",
		state: "NC",
	},
	{
		city: "Lenoir",
		state: "NC",
	},
	{
		city: "Boone",
		state: "NC",
	},
	{
		city: "Havelock",
		state: "NC",
	},
	{
		city: "Hope Mills",
		state: "NC",
	},
	{
		city: "Morganton",
		state: "NC",
	},
	{
		city: "Mount Holly",
		state: "NC",
	},
	{
		city: "Pinehurst",
		state: "NC",
	},
	{
		city: "Mebane",
		state: "NC",
	},
	{
		city: "Graham",
		state: "NC",
	},
	{
		city: "Albemarle",
		state: "NC",
	},
	{
		city: "Murraysville",
		state: "NC",
	},
	{
		city: "Stallings",
		state: "NC",
	},
	{
		city: "Eden",
		state: "NC",
	},
	{
		city: "Roanoke Rapids",
		state: "NC",
	},
	{
		city: "Laurinburg",
		state: "NC",
	},
	{
		city: "Southern Pines",
		state: "NC",
	},
	{
		city: "Piney Green",
		state: "NC",
	},
	{
		city: "Henderson",
		state: "NC",
	},
	{
		city: "Hendersonville",
		state: "NC",
	},
	{
		city: "Davidson",
		state: "NC",
	},
	{
		city: "Anderson Creek",
		state: "NC",
	},
	{
		city: "Reidsville",
		state: "NC",
	},
	{
		city: "Belmont",
		state: "NC",
	},
	{
		city: "Lewisville",
		state: "NC",
	},
	{
		city: "Newton",
		state: "NC",
	},
	{
		city: "Weddington",
		state: "NC",
	},
	{
		city: "Lake Norman of Catawba",
		state: "NC",
	},
	{
		city: "Archdale",
		state: "NC",
	},
	{
		city: "Myrtle Grove",
		state: "NC",
	},
	{
		city: "Spring Lake",
		state: "NC",
	},
	{
		city: "Smithfield",
		state: "NC",
	},
	{
		city: "Elon",
		state: "NC",
	},
	{
		city: "Summerfield",
		state: "NC",
	},
	{
		city: "Lincolnton",
		state: "NC",
	},
	{
		city: "Kings Mountain",
		state: "NC",
	},
	{
		city: "Tarboro",
		state: "NC",
	},
	{
		city: "Mount Airy",
		state: "NC",
	},
	{
		city: "Winterville",
		state: "NC",
	},
	{
		city: "Pineville",
		state: "NC",
	},
	{
		city: "Spout Springs",
		state: "NC",
	},
	{
		city: "Waynesville",
		state: "NC",
	},
	{
		city: "Wendell",
		state: "NC",
	},
	{
		city: "Washington",
		state: "NC",
	},
	{
		city: "Morehead City",
		state: "NC",
	},
	{
		city: "St. Stephens",
		state: "NC",
	},
	{
		city: "Hillsborough",
		state: "NC",
	},
	{
		city: "Rockingham",
		state: "NC",
	},
	{
		city: "Kings Grant",
		state: "NC",
	},
	{
		city: "Rolesville",
		state: "NC",
	},
	{
		city: "Gibsonville",
		state: "NC",
	},
	{
		city: "Wesley Chapel",
		state: "NC",
	},
	{
		city: "Oxford",
		state: "NC",
	},
	{
		city: "Dunn",
		state: "NC",
	},
	{
		city: "Ogden",
		state: "NC",
	},
	{
		city: "Half Moon",
		state: "NC",
	},
	{
		city: "Conover",
		state: "NC",
	},
	{
		city: "Black Mountain",
		state: "NC",
	},
	{
		city: "Aberdeen",
		state: "NC",
	},
	{
		city: "Butner",
		state: "NC",
	},
	{
		city: "Oak Island",
		state: "NC",
	},
	{
		city: "Clinton",
		state: "NC",
	},
	{
		city: "Roxboro",
		state: "NC",
	},
	{
		city: "Fletcher",
		state: "NC",
	},
	{
		city: "Woodfin",
		state: "NC",
	},
	{
		city: "Siler City",
		state: "NC",
	},
	{
		city: "Brevard",
		state: "NC",
	},
	{
		city: "Marion",
		state: "NC",
	},
	{
		city: "Kill Devil Hills",
		state: "NC",
	},
	{
		city: "Etowah",
		state: "NC",
	},
	{
		city: "Oak Ridge",
		state: "NC",
	},
	{
		city: "Forest City",
		state: "NC",
	},
	{
		city: "King",
		state: "NC",
	},
	{
		city: "Porters Neck",
		state: "NC",
	},
	{
		city: "Mills River",
		state: "NC",
	},
	{
		city: "Hampstead",
		state: "NC",
	},
	{
		city: "Trinity",
		state: "NC",
	},
	{
		city: "Zebulon",
		state: "NC",
	},
	{
		city: "Unionville",
		state: "NC",
	},
	{
		city: "Cullowhee",
		state: "NC",
	},
	{
		city: "Carolina Beach",
		state: "NC",
	},
	{
		city: "Marvin",
		state: "NC",
	},
	{
		city: "Selma",
		state: "NC",
	},
	{
		city: "St. James",
		state: "NC",
	},
	{
		city: "Hamlet",
		state: "NC",
	},
	{
		city: "Cherryville",
		state: "NC",
	},
	{
		city: "Stokesdale",
		state: "NC",
	},
	{
		city: "Boiling Spring Lakes",
		state: "NC",
	},
	{
		city: "Dallas",
		state: "NC",
	},
	{
		city: "East Flat Rock",
		state: "NC",
	},
	{
		city: "Mocksville",
		state: "NC",
	},
	{
		city: "Silver Lake",
		state: "NC",
	},
	{
		city: "Nashville",
		state: "NC",
	},
	{
		city: "Walkertown",
		state: "NC",
	},
	{
		city: "Northchase",
		state: "NC",
	},
	{
		city: "Bessemer City",
		state: "NC",
	},
	{
		city: "James City",
		state: "NC",
	},
	{
		city: "Angier",
		state: "NC",
	},
	{
		city: "Williamston",
		state: "NC",
	},
	{
		city: "Cramerton",
		state: "NC",
	},
	{
		city: "Wadesboro",
		state: "NC",
	},
	{
		city: "Sawmills",
		state: "NC",
	},
	{
		city: "Ayden",
		state: "NC",
	},
	{
		city: "Pleasant Garden",
		state: "NC",
	},
	{
		city: "Ahoskie",
		state: "NC",
	},
	{
		city: "Moyock",
		state: "NC",
	},
	{
		city: "Granite Falls",
		state: "NC",
	},
	{
		city: "Creedmoor",
		state: "NC",
	},
	{
		city: "Whiteville",
		state: "NC",
	},
	{
		city: "Long View",
		state: "NC",
	},
	{
		city: "Whispering Pines",
		state: "NC",
	},
	{
		city: "Archer Lodge",
		state: "NC",
	},
	{
		city: "Midway",
		state: "NC",
	},
	{
		city: "Raeford",
		state: "NC",
	},
	{
		city: "Wrightsboro",
		state: "NC",
	},
	{
		city: "Valdese",
		state: "NC",
	},
	{
		city: "Briar Chapel",
		state: "NC",
	},
	{
		city: "Royal Pines",
		state: "NC",
	},
	{
		city: "Erwin",
		state: "NC",
	},
	{
		city: "Swannanoa",
		state: "NC",
	},
	{
		city: "Randleman",
		state: "NC",
	},
	{
		city: "Midland",
		state: "NC",
	},
	{
		city: "Boiling Springs",
		state: "NC",
	},
	{
		city: "Weaverville",
		state: "NC",
	},
	{
		city: "Farmville",
		state: "NC",
	},
	{
		city: "Beaufort",
		state: "NC",
	},
	{
		city: "Edenton",
		state: "NC",
	},
	{
		city: "Carolina Shores",
		state: "NC",
	},
	{
		city: "Pittsboro",
		state: "NC",
	},
	{
		city: "Ranlo",
		state: "NC",
	},
	{
		city: "China Grove",
		state: "NC",
	},
	{
		city: "Lillington",
		state: "NC",
	},
	{
		city: "Canton",
		state: "NC",
	},
	{
		city: "Newport",
		state: "NC",
	},
	{
		city: "Locust",
		state: "NC",
	},
	{
		city: "Forest Oaks",
		state: "NC",
	},
	{
		city: "Westport",
		state: "NC",
	},
	{
		city: "North Wilkesboro",
		state: "NC",
	},
	{
		city: "Brices Creek",
		state: "NC",
	},
	{
		city: "Mount Olive",
		state: "NC",
	},
	{
		city: "Spindale",
		state: "NC",
	},
	{
		city: "Franklin",
		state: "NC",
	},
	{
		city: "Sunset Beach",
		state: "NC",
	},
	{
		city: "Shallotte",
		state: "NC",
	},
	{
		city: "Trent Woods",
		state: "NC",
	},
	{
		city: "Elkin",
		state: "NC",
	},
	{
		city: "Seven Lakes",
		state: "NC",
	},
	{
		city: "Stanley",
		state: "NC",
	},
	{
		city: "Holly Ridge",
		state: "NC",
	},
	{
		city: "Mountain Home",
		state: "NC",
	},
	{
		city: "Benson",
		state: "NC",
	},
	{
		city: "Emerald Isle",
		state: "NC",
	},
	{
		city: "Southport",
		state: "NC",
	},
	{
		city: "Elroy",
		state: "NC",
	},
	{
		city: "Wingate",
		state: "NC",
	},
	{
		city: "Glen Raven",
		state: "NC",
	},
	{
		city: "Hudson",
		state: "NC",
	},
	{
		city: "Dana",
		state: "NC",
	},
	{
		city: "Tabor City",
		state: "NC",
	},
	{
		city: "Gamewell",
		state: "NC",
	},
	{
		city: "Jamestown",
		state: "NC",
	},
	{
		city: "Rutherfordton",
		state: "NC",
	},
	{
		city: "Kitty Hawk",
		state: "NC",
	},
	{
		city: "Eastover",
		state: "NC",
	},
	{
		city: "Maiden",
		state: "NC",
	},
	{
		city: "Lowell",
		state: "NC",
	},
	{
		city: "Surf City",
		state: "NC",
	},
	{
		city: "Landis",
		state: "NC",
	},
	{
		city: "Swansboro",
		state: "NC",
	},
	{
		city: "Bethlehem",
		state: "NC",
	},
	{
		city: "Wilkesboro",
		state: "NC",
	},
	{
		city: "Troutman",
		state: "NC",
	},
	{
		city: "Enochville",
		state: "NC",
	},
	{
		city: "East Rockingham",
		state: "NC",
	},
	{
		city: "Buies Creek",
		state: "NC",
	},
	{
		city: "Skippers Corner",
		state: "NC",
	},
	{
		city: "Mountain View",
		state: "NC",
	},
	{
		city: "Fairview",
		state: "NC",
	},
	{
		city: "Flat Rock",
		state: "NC",
	},
	{
		city: "Windsor",
		state: "NC",
	},
	{
		city: "Wallace",
		state: "NC",
	},
	{
		city: "Welcome",
		state: "NC",
	},
	{
		city: "Tyro",
		state: "NC",
	},
	{
		city: "Rural Hall",
		state: "NC",
	},
	{
		city: "Red Oak",
		state: "NC",
	},
	{
		city: "Lake Park",
		state: "NC",
	},
	{
		city: "Plymouth",
		state: "NC",
	},
	{
		city: "Spencer",
		state: "NC",
	},
	{
		city: "Avery Creek",
		state: "NC",
	},
	{
		city: "Lowesville",
		state: "NC",
	},
	{
		city: "River Road",
		state: "NC",
	},
	{
		city: "Rockfish",
		state: "NC",
	},
	{
		city: "Lake Royale",
		state: "NC",
	},
	{
		city: "Biscoe",
		state: "NC",
	},
	{
		city: "Elizabethtown",
		state: "NC",
	},
	{
		city: "Red Springs",
		state: "NC",
	},
	{
		city: "Mineral Springs",
		state: "NC",
	},
	{
		city: "Burgaw",
		state: "NC",
	},
	{
		city: "Nags Head",
		state: "NC",
	},
	{
		city: "Bayshore",
		state: "NC",
	},
	{
		city: "Southern Shores",
		state: "NC",
	},
	{
		city: "Wallburg",
		state: "NC",
	},
	{
		city: "Lake Junaluska",
		state: "NC",
	},
	{
		city: "Bermuda Run",
		state: "NC",
	},
	{
		city: "Green Level",
		state: "NC",
	},
	{
		city: "Belville",
		state: "NC",
	},
	{
		city: "Granite Quarry",
		state: "NC",
	},
	{
		city: "Troy",
		state: "NC",
	},
	{
		city: "Louisburg",
		state: "NC",
	},
	{
		city: "River Bend",
		state: "NC",
	},
	{
		city: "Fairfield Harbour",
		state: "NC",
	},
	{
		city: "Pembroke",
		state: "NC",
	},
	{
		city: "Yanceyville",
		state: "NC",
	},
	{
		city: "Denver",
		state: "NC",
	},
	{
		city: "Mars Hill",
		state: "NC",
	},
	{
		city: "Yadkinville",
		state: "NC",
	},
	{
		city: "Emma",
		state: "NC",
	},
	{
		city: "Grifton",
		state: "NC",
	},
	{
		city: "Warsaw",
		state: "NC",
	},
	{
		city: "Richlands",
		state: "NC",
	},
	{
		city: "Cape Carteret",
		state: "NC",
	},
	{
		city: "Grandy",
		state: "NC",
	},
	{
		city: "Edneyville",
		state: "NC",
	},
	{
		city: "Cajah's Mountain",
		state: "NC",
	},
	{
		city: "Carthage",
		state: "NC",
	},
	{
		city: "Polkton",
		state: "NC",
	},
	{
		city: "Laurel Park",
		state: "NC",
	},
	{
		city: "Liberty",
		state: "NC",
	},
	{
		city: "Wentworth",
		state: "NC",
	},
	{
		city: "Norwood",
		state: "NC",
	},
	{
		city: "Fearrington Village",
		state: "NC",
	},
	{
		city: "La Grange",
		state: "NC",
	},
	{
		city: "Sylva",
		state: "NC",
	},
	{
		city: "Wrightsville Beach",
		state: "NC",
	},
	{
		city: "Murfreesboro",
		state: "NC",
	},
	{
		city: "Tobaccoville",
		state: "NC",
	},
	{
		city: "Haw River",
		state: "NC",
	},
	{
		city: "Mar-Mac",
		state: "NC",
	},
	{
		city: "South Rosemary",
		state: "NC",
	},
	{
		city: "Jonesville",
		state: "NC",
	},
	{
		city: "Marshville",
		state: "NC",
	},
	{
		city: "Cricket",
		state: "NC",
	},
	{
		city: "Wilson's Mills",
		state: "NC",
	},
	{
		city: "Youngsville",
		state: "NC",
	},
	{
		city: "Kure Beach",
		state: "NC",
	},
	{
		city: "Brogden",
		state: "NC",
	},
	{
		city: "Maggie Valley",
		state: "NC",
	},
	{
		city: "Mayodan",
		state: "NC",
	},
	{
		city: "Walnut Cove",
		state: "NC",
	},
	{
		city: "Franklinton",
		state: "NC",
	},
	{
		city: "Fairmont",
		state: "NC",
	},
	{
		city: "Swepsonville",
		state: "NC",
	},
	{
		city: "Fruitland",
		state: "NC",
	},
	{
		city: "Taylorsville",
		state: "NC",
	},
	{
		city: "Spruce Pine",
		state: "NC",
	},
	{
		city: "Oakboro",
		state: "NC",
	},
	{
		city: "Kenly",
		state: "NC",
	},
	{
		city: "Rockwell",
		state: "NC",
	},
	{
		city: "Pine Level",
		state: "NC",
	},
	{
		city: "Neuse Forest",
		state: "NC",
	},
	{
		city: "Valley Hill",
		state: "NC",
	},
	{
		city: "Connelly Springs",
		state: "NC",
	},
	{
		city: "Madison",
		state: "NC",
	},
	{
		city: "Sneads Ferry",
		state: "NC",
	},
	{
		city: "Broad Creek",
		state: "NC",
	},
	{
		city: "Maxton",
		state: "NC",
	},
	{
		city: "Mulberry",
		state: "NC",
	},
	{
		city: "Horse Shoe",
		state: "NC",
	},
	{
		city: "Four Oaks",
		state: "NC",
	},
	{
		city: "Calabash",
		state: "NC",
	},
	{
		city: "Coats",
		state: "NC",
	},
	{
		city: "St. Pauls",
		state: "NC",
	},
	{
		city: "Cedar Point",
		state: "NC",
	},
	{
		city: "Burnsville",
		state: "NC",
	},
	{
		city: "Cherokee",
		state: "NC",
	},
	{
		city: "Snow Hill",
		state: "NC",
	},
	{
		city: "Hertford",
		state: "NC",
	},
	{
		city: "Enfield",
		state: "NC",
	},
	{
		city: "Scotland Neck",
		state: "NC",
	},
	{
		city: "Mount Pleasant",
		state: "NC",
	},
	{
		city: "Pumpkin Center",
		state: "NC",
	},
	{
		city: "Hemby Bridge",
		state: "NC",
	},
	{
		city: "Dudley",
		state: "NC",
	},
	{
		city: "Andrews",
		state: "NC",
	},
	{
		city: "Wanchese",
		state: "NC",
	},
	{
		city: "Sparta",
		state: "NC",
	},
	{
		city: "Badin",
		state: "NC",
	},
	{
		city: "Governors Club",
		state: "NC",
	},
	{
		city: "Claremont",
		state: "NC",
	},
	{
		city: "Hildebran",
		state: "NC",
	},
	{
		city: "Bladenboro",
		state: "NC",
	},
	{
		city: "Glen Alpine",
		state: "NC",
	},
	{
		city: "Stedman",
		state: "NC",
	},
	{
		city: "Bethel",
		state: "NC",
	},
	{
		city: "Rose Hill",
		state: "NC",
	},
	{
		city: "Governors Village",
		state: "NC",
	},
	{
		city: "Manteo",
		state: "NC",
	},
	{
		city: "Pilot Mountain",
		state: "NC",
	},
	{
		city: "Icard",
		state: "NC",
	},
	{
		city: "Navassa",
		state: "NC",
	},
	{
		city: "Foxfire",
		state: "NC",
	},
	{
		city: "Bryson City",
		state: "NC",
	},
	{
		city: "Clyde",
		state: "NC",
	},
	{
		city: "Ramseur",
		state: "NC",
	},
	{
		city: "Pinebluff",
		state: "NC",
	},
	{
		city: "Drexel",
		state: "NC",
	},
	{
		city: "Atlantic Beach",
		state: "NC",
	},
	{
		city: "Biltmore Forest",
		state: "NC",
	},
	{
		city: "Murphy",
		state: "NC",
	},
	{
		city: "Princeville",
		state: "NC",
	},
	{
		city: "Hoopers Creek",
		state: "NC",
	},
	{
		city: "Sea Breeze",
		state: "NC",
	},
	{
		city: "Jefferson",
		state: "NC",
	},
	{
		city: "Chadbourn",
		state: "NC",
	},
	{
		city: "Fairplains",
		state: "NC",
	},
	{
		city: "Bent Creek",
		state: "NC",
	},
	{
		city: "Robbins",
		state: "NC",
	},
	{
		city: "Salem",
		state: "NC",
	},
	{
		city: "Stony Point",
		state: "NC",
	},
	{
		city: "Buxton",
		state: "NC",
	},
	{
		city: "Dobson",
		state: "NC",
	},
	{
		city: "Sharpsburg",
		state: "NC",
	},
	{
		city: "Weldon",
		state: "NC",
	},
	{
		city: "Robersonville",
		state: "NC",
	},
	{
		city: "Lake Waccamaw",
		state: "NC",
	},
	{
		city: "Lake Lure",
		state: "NC",
	},
	{
		city: "Moravian Falls",
		state: "NC",
	},
	{
		city: "Spring Hope",
		state: "NC",
	},
	{
		city: "Beulaville",
		state: "NC",
	},
	{
		city: "Princeton",
		state: "NC",
	},
	{
		city: "Advance",
		state: "NC",
	},
	{
		city: "Stanfield",
		state: "NC",
	},
	{
		city: "Boonville",
		state: "NC",
	},
	{
		city: "Walnut Creek",
		state: "NC",
	},
	{
		city: "West Jefferson",
		state: "NC",
	},
	{
		city: "Cherry Branch",
		state: "NC",
	},
	{
		city: "Belhaven",
		state: "NC",
	},
	{
		city: "Alamance",
		state: "NC",
	},
	{
		city: "Franklinville",
		state: "NC",
	},
	{
		city: "Millers Creek",
		state: "NC",
	},
	{
		city: "Star",
		state: "NC",
	},
	{
		city: "Maury",
		state: "NC",
	},
	{
		city: "Banner Elk",
		state: "NC",
	},
	{
		city: "Vander",
		state: "NC",
	},
	{
		city: "Foscoe",
		state: "NC",
	},
	{
		city: "Tryon",
		state: "NC",
	},
	{
		city: "West Canton",
		state: "NC",
	},
	{
		city: "Denton",
		state: "NC",
	},
	{
		city: "Springdale",
		state: "NC",
	},
	{
		city: "Broadway",
		state: "NC",
	},
	{
		city: "Nebo",
		state: "NC",
	},
	{
		city: "Elm City",
		state: "NC",
	},
	{
		city: "Pine Knoll Shores",
		state: "NC",
	},
	{
		city: "Brandywine Bay",
		state: "NC",
	},
	{
		city: "Toast",
		state: "NC",
	},
	{
		city: "Pinetops",
		state: "NC",
	},
	{
		city: "Barker Heights",
		state: "NC",
	},
	{
		city: "Harkers Island",
		state: "NC",
	},
	{
		city: "Hays",
		state: "NC",
	},
	{
		city: "East Spencer",
		state: "NC",
	},
	{
		city: "Cordova",
		state: "NC",
	},
	{
		city: "Balfour",
		state: "NC",
	},
	{
		city: "Chocowinity",
		state: "NC",
	},
	{
		city: "Cove Creek",
		state: "NC",
	},
	{
		city: "Five Points",
		state: "NC",
	},
	{
		city: "Rocky Point",
		state: "NC",
	},
	{
		city: "Saxapahaw",
		state: "NC",
	},
	{
		city: "Southmont",
		state: "NC",
	},
	{
		city: "Cypress Landing",
		state: "NC",
	},
	{
		city: "Northlakes",
		state: "NC",
	},
	{
		city: "Arrowhead Beach",
		state: "NC",
	},
	{
		city: "Stoneville",
		state: "NC",
	},
	{
		city: "Faith",
		state: "NC",
	},
	{
		city: "Stem",
		state: "NC",
	},
	{
		city: "Bayboro",
		state: "NC",
	},
	{
		city: "Belwood",
		state: "NC",
	},
	{
		city: "Middlesex",
		state: "NC",
	},
	{
		city: "Columbus",
		state: "NC",
	},
	{
		city: "Garland",
		state: "NC",
	},
	{
		city: "Candor",
		state: "NC",
	},
	{
		city: "Laurel Hill",
		state: "NC",
	},
	{
		city: "Fremont",
		state: "NC",
	},
	{
		city: "Rutherford College",
		state: "NC",
	},
	{
		city: "Vanceboro",
		state: "NC",
	},
	{
		city: "Cleveland",
		state: "NC",
	},
	{
		city: "Highlands",
		state: "NC",
	},
	{
		city: "Vass",
		state: "NC",
	},
	{
		city: "Mount Gilead",
		state: "NC",
	},
	{
		city: "Rowland",
		state: "NC",
	},
	{
		city: "Bonnetsville",
		state: "NC",
	},
	{
		city: "Blowing Rock",
		state: "NC",
	},
	{
		city: "Brunswick",
		state: "NC",
	},
	{
		city: "Norlina",
		state: "NC",
	},
	{
		city: "Magnolia",
		state: "NC",
	},
	{
		city: "South Henderson",
		state: "NC",
	},
	{
		city: "Gorman",
		state: "NC",
	},
	{
		city: "White Plains",
		state: "NC",
	},
	{
		city: "McLeansville",
		state: "NC",
	},
	{
		city: "Dortches",
		state: "NC",
	},
	{
		city: "Faison",
		state: "NC",
	},
	{
		city: "McAdenville",
		state: "NC",
	},
	{
		city: "Alliance",
		state: "NC",
	},
	{
		city: "Maysville",
		state: "NC",
	},
	{
		city: "Bunnlevel",
		state: "NC",
	},
	{
		city: "West Marion",
		state: "NC",
	},
	{
		city: "Oriental",
		state: "NC",
	},
	{
		city: "JAARS",
		state: "NC",
	},
	{
		city: "Plain View",
		state: "NC",
	},
	{
		city: "Castle Hayne",
		state: "NC",
	},
	{
		city: "Woodlawn",
		state: "NC",
	},
	{
		city: "Holden Beach",
		state: "NC",
	},
	{
		city: "Wagram",
		state: "NC",
	},
	{
		city: "Hollister",
		state: "NC",
	},
	{
		city: "Ellerbe",
		state: "NC",
	},
	{
		city: "Warrenton",
		state: "NC",
	},
	{
		city: "Rougemont",
		state: "NC",
	},
	{
		city: "Greenevers",
		state: "NC",
	},
	{
		city: "New Hope",
		state: "NC",
	},
	{
		city: "Efland",
		state: "NC",
	},
	{
		city: "White Lake",
		state: "NC",
	},
	{
		city: "Richfield",
		state: "NC",
	},
	{
		city: "Lucama",
		state: "NC",
	},
	{
		city: "Barker Ten Mile",
		state: "NC",
	},
	{
		city: "Patterson Springs",
		state: "NC",
	},
	{
		city: "Roseboro",
		state: "NC",
	},
	{
		city: "Garysburg",
		state: "NC",
	},
	{
		city: "Black Creek",
		state: "NC",
	},
	{
		city: "Ingold",
		state: "NC",
	},
	{
		city: "Rhodhiss",
		state: "NC",
	},
	{
		city: "Robbinsville",
		state: "NC",
	},
	{
		city: "Jackson Heights",
		state: "NC",
	},
	{
		city: "Kenansville",
		state: "NC",
	},
	{
		city: "Germanton",
		state: "NC",
	},
	{
		city: "Conway",
		state: "NC",
	},
	{
		city: "Aulander",
		state: "NC",
	},
	{
		city: "Cooleemee",
		state: "NC",
	},
	{
		city: "Moncure",
		state: "NC",
	},
	{
		city: "Varnamtown",
		state: "NC",
	},
	{
		city: "Ocracoke",
		state: "NC",
	},
	{
		city: "Pinnacle",
		state: "NC",
	},
	{
		city: "Pleasant Hill",
		state: "NC",
	},
	{
		city: "Beech Mountain",
		state: "NC",
	},
	{
		city: "East Bend",
		state: "NC",
	},
	{
		city: "Bogue",
		state: "NC",
	},
	{
		city: "Light Oak",
		state: "NC",
	},
	{
		city: "North Topsail Beach",
		state: "NC",
	},
	{
		city: "Northwest",
		state: "NC",
	},
	{
		city: "Prospect",
		state: "NC",
	},
	{
		city: "Clarkton",
		state: "NC",
	},
	{
		city: "Gaston",
		state: "NC",
	},
	{
		city: "Silver City",
		state: "NC",
	},
	{
		city: "Ocean Isle Beach",
		state: "NC",
	},
	{
		city: "Newland",
		state: "NC",
	},
	{
		city: "Camden",
		state: "NC",
	},
	{
		city: "Teachey",
		state: "NC",
	},
	{
		city: "Hightsville",
		state: "NC",
	},
	{
		city: "Ellenboro",
		state: "NC",
	},
	{
		city: "Winfall",
		state: "NC",
	},
	{
		city: "Taylortown",
		state: "NC",
	},
	{
		city: "Rich Square",
		state: "NC",
	},
	{
		city: "Barnardsville",
		state: "NC",
	},
	{
		city: "Dobbins Heights",
		state: "NC",
	},
	{
		city: "Fallston",
		state: "NC",
	},
	{
		city: "Winton",
		state: "NC",
	},
	{
		city: "Peletier",
		state: "NC",
	},
	{
		city: "Hookerton",
		state: "NC",
	},
	{
		city: "Misenheimer",
		state: "NC",
	},
	{
		city: "Rosman",
		state: "NC",
	},
	{
		city: "Ansonville",
		state: "NC",
	},
	{
		city: "Frisco",
		state: "NC",
	},
	{
		city: "Hoffman",
		state: "NC",
	},
	{
		city: "Bolton",
		state: "NC",
	},
	{
		city: "South Weldon",
		state: "NC",
	},
	{
		city: "Red Cross",
		state: "NC",
	},
	{
		city: "Seaboard",
		state: "NC",
	},
	{
		city: "Marshall",
		state: "NC",
	},
	{
		city: "Manns Harbor",
		state: "NC",
	},
	{
		city: "Jackson",
		state: "NC",
	},
	{
		city: "New London",
		state: "NC",
	},
	{
		city: "Gibson",
		state: "NC",
	},
	{
		city: "Cashiers",
		state: "NC",
	},
	{
		city: "High Shoals",
		state: "NC",
	},
	{
		city: "Woodland",
		state: "NC",
	},
	{
		city: "Saluda",
		state: "NC",
	},
	{
		city: "Roper",
		state: "NC",
	},
	{
		city: "Old Fort",
		state: "NC",
	},
	{
		city: "Bakersville",
		state: "NC",
	},
	{
		city: "Cape Colony",
		state: "NC",
	},
	{
		city: "Catawba",
		state: "NC",
	},
	{
		city: "Pink Hill",
		state: "NC",
	},
	{
		city: "Carolina Meadows",
		state: "NC",
	},
	{
		city: "Mamers",
		state: "NC",
	},
	{
		city: "Lawndale",
		state: "NC",
	},
	{
		city: "Lattimore",
		state: "NC",
	},
	{
		city: "Deep Run",
		state: "NC",
	},
	{
		city: "Alexis",
		state: "NC",
	},
	{
		city: "Bowdens",
		state: "NC",
	},
	{
		city: "Lilesville",
		state: "NC",
	},
	{
		city: "Elk Park",
		state: "NC",
	},
	{
		city: "Sandyfield",
		state: "NC",
	},
	{
		city: "Duck",
		state: "NC",
	},
	{
		city: "Newton Grove",
		state: "NC",
	},
	{
		city: "Conetoe",
		state: "NC",
	},
	{
		city: "Grover",
		state: "NC",
	},
	{
		city: "Whitsett",
		state: "NC",
	},
	{
		city: "Cliffside",
		state: "NC",
	},
	{
		city: "Pikeville",
		state: "NC",
	},
	{
		city: "Wade",
		state: "NC",
	},
	{
		city: "Cove City",
		state: "NC",
	},
	{
		city: "Riegelwood",
		state: "NC",
	},
	{
		city: "Atlantic",
		state: "NC",
	},
	{
		city: "Ronda",
		state: "NC",
	},
	{
		city: "South Mills",
		state: "NC",
	},
	{
		city: "Hatteras",
		state: "NC",
	},
	{
		city: "Stantonsburg",
		state: "NC",
	},
	{
		city: "Potters Hill",
		state: "NC",
	},
	{
		city: "Marble",
		state: "NC",
	},
	{
		city: "Peachland",
		state: "NC",
	},
	{
		city: "Harmony",
		state: "NC",
	},
	{
		city: "Grantsboro",
		state: "NC",
	},
	{
		city: "Micro",
		state: "NC",
	},
	{
		city: "Ruth",
		state: "NC",
	},
	{
		city: "Iron Station",
		state: "NC",
	},
	{
		city: "Yadkin College",
		state: "NC",
	},
	{
		city: "Hot Springs",
		state: "NC",
	},
	{
		city: "Polkville",
		state: "NC",
	},
	{
		city: "Staley",
		state: "NC",
	},
	{
		city: "Ruffin",
		state: "NC",
	},
	{
		city: "Columbia",
		state: "NC",
	},
	{
		city: "Gloucester",
		state: "NC",
	},
	{
		city: "Deercroft",
		state: "NC",
	},
	{
		city: "Kingstown",
		state: "NC",
	},
	{
		city: "Washington Park",
		state: "NC",
	},
	{
		city: "Aurora",
		state: "NC",
	},
	{
		city: "Aquadale",
		state: "NC",
	},
	{
		city: "Sedalia",
		state: "NC",
	},
	{
		city: "Littleton",
		state: "NC",
	},
	{
		city: "Caswell Beach",
		state: "NC",
	},
	{
		city: "Bunn",
		state: "NC",
	},
	{
		city: "Caroleen",
		state: "NC",
	},
	{
		city: "Ossipee",
		state: "NC",
	},
	{
		city: "Whitakers",
		state: "NC",
	},
	{
		city: "Arapahoe",
		state: "NC",
	},
	{
		city: "Pantego",
		state: "NC",
	},
	{
		city: "Bridgeton",
		state: "NC",
	},
	{
		city: "Sugar Mountain",
		state: "NC",
	},
	{
		city: "Bell Arthur",
		state: "NC",
	},
	{
		city: "Millingport",
		state: "NC",
	},
	{
		city: "Oak City",
		state: "NC",
	},
	{
		city: "Seven Devils",
		state: "NC",
	},
	{
		city: "Brookford",
		state: "NC",
	},
	{
		city: "Scotch Meadows",
		state: "NC",
	},
	{
		city: "Goldston",
		state: "NC",
	},
	{
		city: "Ivanhoe",
		state: "NC",
	},
	{
		city: "Hayesville",
		state: "NC",
	},
	{
		city: "Minnesott Beach",
		state: "NC",
	},
	{
		city: "Cameron",
		state: "NC",
	},
	{
		city: "Seagrove",
		state: "NC",
	},
	{
		city: "Bailey",
		state: "NC",
	},
	{
		city: "Topsail Beach",
		state: "NC",
	},
	{
		city: "Turkey",
		state: "NC",
	},
	{
		city: "Fair Bluff",
		state: "NC",
	},
	{
		city: "Chowan Beach",
		state: "NC",
	},
	{
		city: "Calypso",
		state: "NC",
	},
	{
		city: "Webster",
		state: "NC",
	},
	{
		city: "Henrietta",
		state: "NC",
	},
	{
		city: "Sims",
		state: "NC",
	},
	{
		city: "Dublin",
		state: "NC",
	},
	{
		city: "Bennett",
		state: "NC",
	},
	{
		city: "Morven",
		state: "NC",
	},
	{
		city: "Jamesville",
		state: "NC",
	},
	{
		city: "Kelly",
		state: "NC",
	},
	{
		city: "Simpson",
		state: "NC",
	},
	{
		city: "Grimesland",
		state: "NC",
	},
	{
		city: "St. Helena",
		state: "NC",
	},
	{
		city: "Casar",
		state: "NC",
	},
	{
		city: "Old Hundred",
		state: "NC",
	},
	{
		city: "Parkton",
		state: "NC",
	},
	{
		city: "East Arcadia",
		state: "NC",
	},
	{
		city: "Marshallberg",
		state: "NC",
	},
	{
		city: "Momeyer",
		state: "NC",
	},
	{
		city: "Avon",
		state: "NC",
	},
	{
		city: "Atkinson",
		state: "NC",
	},
	{
		city: "Pollocksville",
		state: "NC",
	},
	{
		city: "Lewiston Woodville",
		state: "NC",
	},
	{
		city: "Altamahaw",
		state: "NC",
	},
	{
		city: "Swan Quarter",
		state: "NC",
	},
	{
		city: "Cofield",
		state: "NC",
	},
	{
		city: "Montreat",
		state: "NC",
	},
	{
		city: "Saratoga",
		state: "NC",
	},
	{
		city: "Bayview",
		state: "NC",
	},
	{
		city: "Rennert",
		state: "NC",
	},
	{
		city: "Cerro Gordo",
		state: "NC",
	},
	{
		city: "Forest Hills",
		state: "NC",
	},
	{
		city: "Dover",
		state: "NC",
	},
	{
		city: "Bethania",
		state: "NC",
	},
	{
		city: "Elrod",
		state: "NC",
	},
	{
		city: "East Laurinburg",
		state: "NC",
	},
	{
		city: "Stonewall",
		state: "NC",
	},
	{
		city: "Earl",
		state: "NC",
	},
	{
		city: "Linville",
		state: "NC",
	},
	{
		city: "Colerain",
		state: "NC",
	},
	{
		city: "Danbury",
		state: "NC",
	},
	{
		city: "Macclesfield",
		state: "NC",
	},
	{
		city: "Cedar Rock",
		state: "NC",
	},
	{
		city: "Kelford",
		state: "NC",
	},
	{
		city: "Coinjock",
		state: "NC",
	},
	{
		city: "Gold Hill",
		state: "NC",
	},
	{
		city: "Waco",
		state: "NC",
	},
	{
		city: "Valle Crucis",
		state: "NC",
	},
	{
		city: "Castalia",
		state: "NC",
	},
	{
		city: "Salemburg",
		state: "NC",
	},
	{
		city: "Bostic",
		state: "NC",
	},
	{
		city: "Hallsboro",
		state: "NC",
	},
	{
		city: "Fairfield",
		state: "NC",
	},
	{
		city: "Hiddenite",
		state: "NC",
	},
	{
		city: "Farmington",
		state: "NC",
	},
	{
		city: "Ashley Heights",
		state: "NC",
	},
	{
		city: "Stovall",
		state: "NC",
	},
	{
		city: "Walstonburg",
		state: "NC",
	},
	{
		city: "Stokes",
		state: "NC",
	},
	{
		city: "Lansing",
		state: "NC",
	},
	{
		city: "Fountain",
		state: "NC",
	},
	{
		city: "Sandy Creek",
		state: "NC",
	},
	{
		city: "Hobgood",
		state: "NC",
	},
	{
		city: "Raemon",
		state: "NC",
	},
	{
		city: "Shannon",
		state: "NC",
	},
	{
		city: "Boardman",
		state: "NC",
	},
	{
		city: "Crouse",
		state: "NC",
	},
	{
		city: "Watha",
		state: "NC",
	},
	{
		city: "Parmele",
		state: "NC",
	},
	{
		city: "Gatesville",
		state: "NC",
	},
	{
		city: "Middleburg",
		state: "NC",
	},
	{
		city: "White Oak",
		state: "NC",
	},
	{
		city: "Vandemere",
		state: "NC",
	},
	{
		city: "Autryville",
		state: "NC",
	},
	{
		city: "Severn",
		state: "NC",
	},
	{
		city: "Roxobel",
		state: "NC",
	},
	{
		city: "Gerton",
		state: "NC",
	},
	{
		city: "Davis",
		state: "NC",
	},
	{
		city: "Lasker",
		state: "NC",
	},
	{
		city: "Engelhard",
		state: "NC",
	},
	{
		city: "Powellsville",
		state: "NC",
	},
	{
		city: "Vann Crossroads",
		state: "NC",
	},
	{
		city: "Centerville",
		state: "NC",
	},
	{
		city: "Dillsboro",
		state: "NC",
	},
	{
		city: "Roberdel",
		state: "NC",
	},
	{
		city: "Jackson Springs",
		state: "NC",
	},
	{
		city: "Halifax",
		state: "NC",
	},
	{
		city: "Bath",
		state: "NC",
	},
	{
		city: "Mooresboro",
		state: "NC",
	},
	{
		city: "Indian Beach",
		state: "NC",
	},
	{
		city: "Glenwood",
		state: "NC",
	},
	{
		city: "Bolivia",
		state: "NC",
	},
	{
		city: "Askewville",
		state: "NC",
	},
	{
		city: "Harrellsville",
		state: "NC",
	},
	{
		city: "Hamilton",
		state: "NC",
	},
	{
		city: "Norman",
		state: "NC",
	},
	{
		city: "Milwaukee",
		state: "NC",
	},
	{
		city: "Eureka",
		state: "NC",
	},
	{
		city: "Salvo",
		state: "NC",
	},
	{
		city: "Mesic",
		state: "NC",
	},
	{
		city: "Trenton",
		state: "NC",
	},
	{
		city: "Falkland",
		state: "NC",
	},
	{
		city: "Falcon",
		state: "NC",
	},
	{
		city: "Harrells",
		state: "NC",
	},
	{
		city: "Kittrell",
		state: "NC",
	},
	{
		city: "Creswell",
		state: "NC",
	},
	{
		city: "Keener",
		state: "NC",
	},
	{
		city: "Bald Head Island",
		state: "NC",
	},
	{
		city: "Belvoir",
		state: "NC",
	},
	{
		city: "Everetts",
		state: "NC",
	},
	{
		city: "Crossnore",
		state: "NC",
	},
	{
		city: "Milton",
		state: "NC",
	},
	{
		city: "Godwin",
		state: "NC",
	},
	{
		city: "Delco",
		state: "NC",
	},
	{
		city: "Rodanthe",
		state: "NC",
	},
	{
		city: "Chimney Rock Village",
		state: "NC",
	},
	{
		city: "Proctorville",
		state: "NC",
	},
	{
		city: "Linden",
		state: "NC",
	},
	{
		city: "Sunbury",
		state: "NC",
	},
	{
		city: "Marietta",
		state: "NC",
	},
	{
		city: "Wakulla",
		state: "NC",
	},
	{
		city: "Tar Heel",
		state: "NC",
	},
	{
		city: "Glenville",
		state: "NC",
	},
	{
		city: "Smithtown",
		state: "NC",
	},
	{
		city: "McDonald",
		state: "NC",
	},
	{
		city: "Love Valley",
		state: "NC",
	},
	{
		city: "Lake Santeetlah",
		state: "NC",
	},
	{
		city: "Butters",
		state: "NC",
	},
	{
		city: "Como",
		state: "NC",
	},
	{
		city: "Lowgap",
		state: "NC",
	},
	{
		city: "Macon",
		state: "NC",
	},
	{
		city: "Blue Clay Farms",
		state: "NC",
	},
	{
		city: "Bear Grass",
		state: "NC",
	},
	{
		city: "Lumber Bridge",
		state: "NC",
	},
	{
		city: "Speed",
		state: "NC",
	},
	{
		city: "Gulf",
		state: "NC",
	},
	{
		city: "McFarlan",
		state: "NC",
	},
	{
		city: "Orrum",
		state: "NC",
	},
	{
		city: "Evergreen",
		state: "NC",
	},
	{
		city: "Bowmore",
		state: "NC",
	},
	{
		city: "Pinetown",
		state: "NC",
	},
	{
		city: "Long Creek",
		state: "NC",
	},
	{
		city: "Leggett",
		state: "NC",
	},
	{
		city: "Dundarrach",
		state: "NC",
	},
	{
		city: "Graingers",
		state: "NC",
	},
	{
		city: "Waves",
		state: "NC",
	},
	{
		city: "Rex",
		state: "NC",
	},
	{
		city: "Raynham",
		state: "NC",
	},
	{
		city: "Fontana Dam",
		state: "NC",
	},
	{
		city: "Seven Springs",
		state: "NC",
	},
	{
		city: "Dellview",
		state: "NC",
	},
	{
		city: "Delway",
		state: "NC",
	},
	{
		city: "Hassell",
		state: "NC",
	},
	{
		city: "Chinquapin",
		state: "NC",
	},
	{
		city: "Spencer Mountain",
		state: "NC",
	},
	{
		city: "Hobucken",
		state: "NC",
	},
	{
		city: "Whittier",
		state: "NC",
	},
]
const SouthCarolinaLocations = [
	{
		city: "Charleston",
		state: "SC",
	},
	{
		city: "Columbia",
		state: "SC",
	},
	{
		city: "Greenville",
		state: "SC",
	},
	{
		city: "Myrtle Beach",
		state: "SC",
	},
	{
		city: "Rock Hill",
		state: "SC",
	},
	{
		city: "Spartanburg",
		state: "SC",
	},
	{
		city: "Mauldin",
		state: "SC",
	},
	{
		city: "North Charleston",
		state: "SC",
	},
	{
		city: "Florence",
		state: "SC",
	},
	{
		city: "Mount Pleasant",
		state: "SC",
	},
	{
		city: "Sumter",
		state: "SC",
	},
	{
		city: "Summerville",
		state: "SC",
	},
	{
		city: "Goose Creek",
		state: "SC",
	},
	{
		city: "Hilton Head Island",
		state: "SC",
	},
	{
		city: "Greer",
		state: "SC",
	},
	{
		city: "Aiken",
		state: "SC",
	},
	{
		city: "Anderson",
		state: "SC",
	},
	{
		city: "Bluffton",
		state: "SC",
	},
	{
		city: "Socastee",
		state: "SC",
	},
	{
		city: "Carolina Forest",
		state: "SC",
	},
	{
		city: "Fort Mill",
		state: "SC",
	},
	{
		city: "North Augusta",
		state: "SC",
	},
	{
		city: "Taylors",
		state: "SC",
	},
	{
		city: "Simpsonville",
		state: "SC",
	},
	{
		city: "Lexington",
		state: "SC",
	},
	{
		city: "Easley",
		state: "SC",
	},
	{
		city: "Greenwood",
		state: "SC",
	},
	{
		city: "Conway",
		state: "SC",
	},
	{
		city: "St. Andrews",
		state: "SC",
	},
	{
		city: "Wade Hampton",
		state: "SC",
	},
	{
		city: "Hanahan",
		state: "SC",
	},
	{
		city: "Five Forks",
		state: "SC",
	},
	{
		city: "North Myrtle Beach",
		state: "SC",
	},
	{
		city: "West Columbia",
		state: "SC",
	},
	{
		city: "Clemson",
		state: "SC",
	},
	{
		city: "Red Hill",
		state: "SC",
	},
	{
		city: "Berea",
		state: "SC",
	},
	{
		city: "Seven Oaks",
		state: "SC",
	},
	{
		city: "Dentsville",
		state: "SC",
	},
	{
		city: "Gantt",
		state: "SC",
	},
	{
		city: "Ladson",
		state: "SC",
	},
	{
		city: "Cayce",
		state: "SC",
	},
	{
		city: "Lake Wylie",
		state: "SC",
	},
	{
		city: "Parker",
		state: "SC",
	},
	{
		city: "Beaufort",
		state: "SC",
	},
	{
		city: "Orangeburg",
		state: "SC",
	},
	{
		city: "Port Royal",
		state: "SC",
	},
	{
		city: "Moncks Corner",
		state: "SC",
	},
	{
		city: "Gaffney",
		state: "SC",
	},
	{
		city: "Tega Cay",
		state: "SC",
	},
	{
		city: "Oak Grove",
		state: "SC",
	},
	{
		city: "James Island",
		state: "SC",
	},
	{
		city: "Irmo",
		state: "SC",
	},
	{
		city: "Forest Acres",
		state: "SC",
	},
	{
		city: "Garden City",
		state: "SC",
	},
	{
		city: "Boiling Springs",
		state: "SC",
	},
	{
		city: "Newberry",
		state: "SC",
	},
	{
		city: "Fountain Inn",
		state: "SC",
	},
	{
		city: "Red Bank",
		state: "SC",
	},
	{
		city: "Little River",
		state: "SC",
	},
	{
		city: "Powdersville",
		state: "SC",
	},
	{
		city: "Murrells Inlet",
		state: "SC",
	},
	{
		city: "Sangaree",
		state: "SC",
	},
	{
		city: "Laurens",
		state: "SC",
	},
	{
		city: "Lugoff",
		state: "SC",
	},
	{
		city: "Woodfield",
		state: "SC",
	},
	{
		city: "Seneca",
		state: "SC",
	},
	{
		city: "Litchfield Beach",
		state: "SC",
	},
	{
		city: "Lancaster",
		state: "SC",
	},
	{
		city: "Georgetown",
		state: "SC",
	},
	{
		city: "York",
		state: "SC",
	},
	{
		city: "Sans Souci",
		state: "SC",
	},
	{
		city: "Union",
		state: "SC",
	},
	{
		city: "Burton",
		state: "SC",
	},
	{
		city: "Clinton",
		state: "SC",
	},
	{
		city: "Travelers Rest",
		state: "SC",
	},
	{
		city: "Camden",
		state: "SC",
	},
	{
		city: "Valley Falls",
		state: "SC",
	},
	{
		city: "Hartsville",
		state: "SC",
	},
	{
		city: "Welcome",
		state: "SC",
	},
	{
		city: "Bennettsville",
		state: "SC",
	},
	{
		city: "Centerville",
		state: "SC",
	},
	{
		city: "Forestbrook",
		state: "SC",
	},
	{
		city: "Hardeeville",
		state: "SC",
	},
	{
		city: "White Knoll",
		state: "SC",
	},
	{
		city: "Homeland Park",
		state: "SC",
	},
	{
		city: "Lake Murray of Richland",
		state: "SC",
	},
	{
		city: "Clover",
		state: "SC",
	},
	{
		city: "Marion",
		state: "SC",
	},
	{
		city: "Dillon",
		state: "SC",
	},
	{
		city: "Darlington",
		state: "SC",
	},
	{
		city: "Lake City",
		state: "SC",
	},
	{
		city: "Laurel Bay",
		state: "SC",
	},
	{
		city: "Lyman",
		state: "SC",
	},
	{
		city: "Piedmont",
		state: "SC",
	},
	{
		city: "Walterboro",
		state: "SC",
	},
	{
		city: "Clemson University",
		state: "SC",
	},
	{
		city: "Belvedere",
		state: "SC",
	},
	{
		city: "Batesburg-Leesville",
		state: "SC",
	},
	{
		city: "Hollywood",
		state: "SC",
	},
	{
		city: "Chester",
		state: "SC",
	},
	{
		city: "Central",
		state: "SC",
	},
	{
		city: "Cheraw",
		state: "SC",
	},
	{
		city: "Abbeville",
		state: "SC",
	},
	{
		city: "Blythewood",
		state: "SC",
	},
	{
		city: "Barnwell",
		state: "SC",
	},
	{
		city: "Baxter Village",
		state: "SC",
	},
	{
		city: "East Camden",
		state: "SC",
	},
	{
		city: "Newport",
		state: "SC",
	},
	{
		city: "Edgefield",
		state: "SC",
	},
	{
		city: "Belton",
		state: "SC",
	},
	{
		city: "Isle of Palms",
		state: "SC",
	},
	{
		city: "Southern Shops",
		state: "SC",
	},
	{
		city: "Brookdale",
		state: "SC",
	},
	{
		city: "Woodruff",
		state: "SC",
	},
	{
		city: "Surfside Beach",
		state: "SC",
	},
	{
		city: "India Hook",
		state: "SC",
	},
	{
		city: "Walhalla",
		state: "SC",
	},
	{
		city: "Mullins",
		state: "SC",
	},
	{
		city: "Williamston",
		state: "SC",
	},
	{
		city: "Saxon",
		state: "SC",
	},
	{
		city: "Clearwater",
		state: "SC",
	},
	{
		city: "Duncan",
		state: "SC",
	},
	{
		city: "Manning",
		state: "SC",
	},
	{
		city: "Wyboo",
		state: "SC",
	},
	{
		city: "Northlake",
		state: "SC",
	},
	{
		city: "Whitney",
		state: "SC",
	},
	{
		city: "Ridgeland",
		state: "SC",
	},
	{
		city: "Honea Path",
		state: "SC",
	},
	{
		city: "Lesslie",
		state: "SC",
	},
	{
		city: "Roebuck",
		state: "SC",
	},
	{
		city: "Pendleton",
		state: "SC",
	},
	{
		city: "Arcadia",
		state: "SC",
	},
	{
		city: "Liberty",
		state: "SC",
	},
	{
		city: "Pickens",
		state: "SC",
	},
	{
		city: "Dalzell",
		state: "SC",
	},
	{
		city: "Golden Grove",
		state: "SC",
	},
	{
		city: "Dunean",
		state: "SC",
	},
	{
		city: "Kingstree",
		state: "SC",
	},
	{
		city: "Winnsboro",
		state: "SC",
	},
	{
		city: "Wellford",
		state: "SC",
	},
	{
		city: "Denmark",
		state: "SC",
	},
	{
		city: "Saluda",
		state: "SC",
	},
	{
		city: "Hilltop",
		state: "SC",
	},
	{
		city: "Arthurtown",
		state: "SC",
	},
	{
		city: "Bamberg",
		state: "SC",
	},
	{
		city: "Gloverville",
		state: "SC",
	},
	{
		city: "Capitol View",
		state: "SC",
	},
	{
		city: "Burnettown",
		state: "SC",
	},
	{
		city: "Keowee Key",
		state: "SC",
	},
	{
		city: "Bishopville",
		state: "SC",
	},
	{
		city: "McCormick",
		state: "SC",
	},
	{
		city: "Cherryvale",
		state: "SC",
	},
	{
		city: "Williston",
		state: "SC",
	},
	{
		city: "Hampton",
		state: "SC",
	},
	{
		city: "Inman",
		state: "SC",
	},
	{
		city: "Elgin",
		state: "SC",
	},
	{
		city: "Lakewood",
		state: "SC",
	},
	{
		city: "Pageland",
		state: "SC",
	},
	{
		city: "Allendale",
		state: "SC",
	},
	{
		city: "East Gaffney",
		state: "SC",
	},
	{
		city: "Springdale",
		state: "SC",
	},
	{
		city: "Pine Ridge",
		state: "SC",
	},
	{
		city: "Andrews",
		state: "SC",
	},
	{
		city: "North Hartsville",
		state: "SC",
	},
	{
		city: "Graniteville",
		state: "SC",
	},
	{
		city: "South Congaree",
		state: "SC",
	},
	{
		city: "Ravenel",
		state: "SC",
	},
	{
		city: "Arkwright",
		state: "SC",
	},
	{
		city: "Shell Point",
		state: "SC",
	},
	{
		city: "Loris",
		state: "SC",
	},
	{
		city: "Landrum",
		state: "SC",
	},
	{
		city: "Estill",
		state: "SC",
	},
	{
		city: "New Ellenton",
		state: "SC",
	},
	{
		city: "Blacksburg",
		state: "SC",
	},
	{
		city: "Ninety Six",
		state: "SC",
	},
	{
		city: "Westminster",
		state: "SC",
	},
	{
		city: "Kershaw",
		state: "SC",
	},
	{
		city: "Johnston",
		state: "SC",
	},
	{
		city: "Sullivan's Island",
		state: "SC",
	},
	{
		city: "St. George",
		state: "SC",
	},
	{
		city: "Ben Avon",
		state: "SC",
	},
	{
		city: "Timmonsville",
		state: "SC",
	},
	{
		city: "Watts Mills",
		state: "SC",
	},
	{
		city: "Pacolet",
		state: "SC",
	},
	{
		city: "Fairfax",
		state: "SC",
	},
	{
		city: "Blackville",
		state: "SC",
	},
	{
		city: "Gaston",
		state: "SC",
	},
	{
		city: "Winnsboro Mills",
		state: "SC",
	},
	{
		city: "St. Matthews",
		state: "SC",
	},
	{
		city: "Eureka Mill",
		state: "SC",
	},
	{
		city: "Tigerville",
		state: "SC",
	},
	{
		city: "Jackson",
		state: "SC",
	},
	{
		city: "Ware Shoals",
		state: "SC",
	},
	{
		city: "Wilkinson Heights",
		state: "SC",
	},
	{
		city: "Wedgefield",
		state: "SC",
	},
	{
		city: "Edisto",
		state: "SC",
	},
	{
		city: "Cowpens",
		state: "SC",
	},
	{
		city: "Riverview",
		state: "SC",
	},
	{
		city: "Pimlico",
		state: "SC",
	},
	{
		city: "Langley",
		state: "SC",
	},
	{
		city: "Arial",
		state: "SC",
	},
	{
		city: "Kiawah Island",
		state: "SC",
	},
	{
		city: "Privateer",
		state: "SC",
	},
	{
		city: "Varnville",
		state: "SC",
	},
	{
		city: "McColl",
		state: "SC",
	},
	{
		city: "Seabrook Island",
		state: "SC",
	},
	{
		city: "Calhoun Falls",
		state: "SC",
	},
	{
		city: "Slater-Marietta",
		state: "SC",
	},
	{
		city: "Great Falls",
		state: "SC",
	},
	{
		city: "Camp Croft",
		state: "SC",
	},
	{
		city: "Hopkins",
		state: "SC",
	},
	{
		city: "South Sumter",
		state: "SC",
	},
	{
		city: "Stateburg",
		state: "SC",
	},
	{
		city: "Utica",
		state: "SC",
	},
	{
		city: "Mayo",
		state: "SC",
	},
	{
		city: "Latta",
		state: "SC",
	},
	{
		city: "Ridgeville",
		state: "SC",
	},
	{
		city: "Awendaw",
		state: "SC",
	},
	{
		city: "Reidville",
		state: "SC",
	},
	{
		city: "Fairforest",
		state: "SC",
	},
	{
		city: "Chapin",
		state: "SC",
	},
	{
		city: "Monarch Mill",
		state: "SC",
	},
	{
		city: "Judson",
		state: "SC",
	},
	{
		city: "Whitmire",
		state: "SC",
	},
	{
		city: "Catawba",
		state: "SC",
	},
	{
		city: "Conestee",
		state: "SC",
	},
	{
		city: "Joanna",
		state: "SC",
	},
	{
		city: "St. Stephen",
		state: "SC",
	},
	{
		city: "Homewood",
		state: "SC",
	},
	{
		city: "City View",
		state: "SC",
	},
	{
		city: "Chesterfield",
		state: "SC",
	},
	{
		city: "Seabrook",
		state: "SC",
	},
	{
		city: "Wagener",
		state: "SC",
	},
	{
		city: "Cane Savannah",
		state: "SC",
	},
	{
		city: "Holly Hill",
		state: "SC",
	},
	{
		city: "Pelzer",
		state: "SC",
	},
	{
		city: "Buffalo",
		state: "SC",
	},
	{
		city: "Murphys Estates",
		state: "SC",
	},
	{
		city: "Olympia",
		state: "SC",
	},
	{
		city: "Irwin",
		state: "SC",
	},
	{
		city: "Beech Island",
		state: "SC",
	},
	{
		city: "Iva",
		state: "SC",
	},
	{
		city: "Prosperity",
		state: "SC",
	},
	{
		city: "Six Mile",
		state: "SC",
	},
	{
		city: "Johnsonville",
		state: "SC",
	},
	{
		city: "Meggett",
		state: "SC",
	},
	{
		city: "Dovesville",
		state: "SC",
	},
	{
		city: "Branchville",
		state: "SC",
	},
	{
		city: "Cottageville",
		state: "SC",
	},
	{
		city: "Dunbar",
		state: "SC",
	},
	{
		city: "West Pelzer",
		state: "SC",
	},
	{
		city: "Lake Secession",
		state: "SC",
	},
	{
		city: "Pelion",
		state: "SC",
	},
	{
		city: "Due West",
		state: "SC",
	},
	{
		city: "Lamar",
		state: "SC",
	},
	{
		city: "Pamplico",
		state: "SC",
	},
	{
		city: "Bonneau Beach",
		state: "SC",
	},
	{
		city: "Oakland",
		state: "SC",
	},
	{
		city: "Sandy Springs",
		state: "SC",
	},
	{
		city: "Folly Beach",
		state: "SC",
	},
	{
		city: "Santee",
		state: "SC",
	},
	{
		city: "Jonesville",
		state: "SC",
	},
	{
		city: "Gayle Mill",
		state: "SC",
	},
	{
		city: "Elloree",
		state: "SC",
	},
	{
		city: "Quinby",
		state: "SC",
	},
	{
		city: "Pinopolis",
		state: "SC",
	},
	{
		city: "Drayton",
		state: "SC",
	},
	{
		city: "Aynor",
		state: "SC",
	},
	{
		city: "Fort Lawn",
		state: "SC",
	},
	{
		city: "East Sumter",
		state: "SC",
	},
	{
		city: "Gadsden",
		state: "SC",
	},
	{
		city: "Jefferson",
		state: "SC",
	},
	{
		city: "Bonneau",
		state: "SC",
	},
	{
		city: "Harleyville",
		state: "SC",
	},
	{
		city: "Newtown",
		state: "SC",
	},
	{
		city: "McBee",
		state: "SC",
	},
	{
		city: "Cateechee",
		state: "SC",
	},
	{
		city: "Lincolnville",
		state: "SC",
	},
	{
		city: "Hamer",
		state: "SC",
	},
	{
		city: "Edmund",
		state: "SC",
	},
	{
		city: "Scranton",
		state: "SC",
	},
	{
		city: "DeBordieu Colony",
		state: "SC",
	},
	{
		city: "Bowman",
		state: "SC",
	},
	{
		city: "Van Wyck",
		state: "SC",
	},
	{
		city: "North",
		state: "SC",
	},
	{
		city: "La France",
		state: "SC",
	},
	{
		city: "Gray Court",
		state: "SC",
	},
	{
		city: "Dale",
		state: "SC",
	},
	{
		city: "Inman Mills",
		state: "SC",
	},
	{
		city: "Swansea",
		state: "SC",
	},
	{
		city: "Clifton",
		state: "SC",
	},
	{
		city: "Pinewood",
		state: "SC",
	},
	{
		city: "Edisto Beach",
		state: "SC",
	},
	{
		city: "Fripp Island",
		state: "SC",
	},
	{
		city: "Heath Springs",
		state: "SC",
	},
	{
		city: "Summerton",
		state: "SC",
	},
	{
		city: "Pine Ridge",
		state: "SC",
	},
	{
		city: "Norris",
		state: "SC",
	},
	{
		city: "Arcadia Lakes",
		state: "SC",
	},
	{
		city: "Warrenville",
		state: "SC",
	},
	{
		city: "Turbeville",
		state: "SC",
	},
	{
		city: "Little Rock",
		state: "SC",
	},
	{
		city: "McClellanville",
		state: "SC",
	},
	{
		city: "Bucksport",
		state: "SC",
	},
	{
		city: "Campobello",
		state: "SC",
	},
	{
		city: "Chesnee",
		state: "SC",
	},
	{
		city: "Wallace",
		state: "SC",
	},
	{
		city: "Yemassee",
		state: "SC",
	},
	{
		city: "Lake View",
		state: "SC",
	},
	{
		city: "Elliott",
		state: "SC",
	},
	{
		city: "The Cliffs Valley",
		state: "SC",
	},
	{
		city: "Cash",
		state: "SC",
	},
	{
		city: "Buford",
		state: "SC",
	},
	{
		city: "Clio",
		state: "SC",
	},
	{
		city: "Coward",
		state: "SC",
	},
	{
		city: "Ridge Spring",
		state: "SC",
	},
	{
		city: "Unity",
		state: "SC",
	},
	{
		city: "Gilbert",
		state: "SC",
	},
	{
		city: "Helena",
		state: "SC",
	},
	{
		city: "Briarcliffe Acres",
		state: "SC",
	},
	{
		city: "Chickasaw Point",
		state: "SC",
	},
	{
		city: "Eastover",
		state: "SC",
	},
	{
		city: "Carlisle",
		state: "SC",
	},
	{
		city: "Mayesville",
		state: "SC",
	},
	{
		city: "Hemingway",
		state: "SC",
	},
	{
		city: "Cross Hill",
		state: "SC",
	},
	{
		city: "Centenary",
		state: "SC",
	},
	{
		city: "Springfield",
		state: "SC",
	},
	{
		city: "Furman",
		state: "SC",
	},
	{
		city: "Daufuskie Island",
		state: "SC",
	},
	{
		city: "Summit",
		state: "SC",
	},
	{
		city: "Finklea",
		state: "SC",
	},
	{
		city: "Lynchburg",
		state: "SC",
	},
	{
		city: "Brunson",
		state: "SC",
	},
	{
		city: "Olanta",
		state: "SC",
	},
	{
		city: "Fairview Crossroads",
		state: "SC",
	},
	{
		city: "North Santee",
		state: "SC",
	},
	{
		city: "Hickory Grove",
		state: "SC",
	},
	{
		city: "Lockhart",
		state: "SC",
	},
	{
		city: "Modoc",
		state: "SC",
	},
	{
		city: "Cameron",
		state: "SC",
	},
	{
		city: "Enoree",
		state: "SC",
	},
	{
		city: "Manville",
		state: "SC",
	},
	{
		city: "Lane",
		state: "SC",
	},
	{
		city: "Bethune",
		state: "SC",
	},
	{
		city: "Sharon",
		state: "SC",
	},
	{
		city: "Ehrhardt",
		state: "SC",
	},
	{
		city: "Cokesbury",
		state: "SC",
	},
	{
		city: "Rowesville",
		state: "SC",
	},
	{
		city: "Converse",
		state: "SC",
	},
	{
		city: "Society Hill",
		state: "SC",
	},
	{
		city: "Eutawville",
		state: "SC",
	},
	{
		city: "Paxville",
		state: "SC",
	},
	{
		city: "Danwood",
		state: "SC",
	},
	{
		city: "Sheldon",
		state: "SC",
	},
	{
		city: "Hilda",
		state: "SC",
	},
	{
		city: "Patrick",
		state: "SC",
	},
	{
		city: "Promised Land",
		state: "SC",
	},
	{
		city: "Greeleyville",
		state: "SC",
	},
	{
		city: "Alcolu",
		state: "SC",
	},
	{
		city: "West Union",
		state: "SC",
	},
	{
		city: "Grover",
		state: "SC",
	},
	{
		city: "Norway",
		state: "SC",
	},
	{
		city: "Little Mountain",
		state: "SC",
	},
	{
		city: "Russellville",
		state: "SC",
	},
	{
		city: "Stuckey",
		state: "SC",
	},
	{
		city: "Oswego",
		state: "SC",
	},
	{
		city: "Donalds",
		state: "SC",
	},
	{
		city: "Trenton",
		state: "SC",
	},
	{
		city: "Startex",
		state: "SC",
	},
	{
		city: "Glenn Springs",
		state: "SC",
	},
	{
		city: "Adams Run",
		state: "SC",
	},
	{
		city: "Lydia",
		state: "SC",
	},
	{
		city: "Fair Play",
		state: "SC",
	},
	{
		city: "Reevesville",
		state: "SC",
	},
	{
		city: "Ridgeway",
		state: "SC",
	},
	{
		city: "Neeses",
		state: "SC",
	},
	{
		city: "Snelling",
		state: "SC",
	},
	{
		city: "Harbor Island",
		state: "SC",
	},
	{
		city: "Atlantic Beach",
		state: "SC",
	},
	{
		city: "Richburg",
		state: "SC",
	},
	{
		city: "Lobeco",
		state: "SC",
	},
	{
		city: "McConnells",
		state: "SC",
	},
	{
		city: "Ruby",
		state: "SC",
	},
	{
		city: "Glendale",
		state: "SC",
	},
	{
		city: "Salley",
		state: "SC",
	},
	{
		city: "Pauline",
		state: "SC",
	},
	{
		city: "Monetta",
		state: "SC",
	},
	{
		city: "Mount Carmel",
		state: "SC",
	},
	{
		city: "Windsor",
		state: "SC",
	},
	{
		city: "Central Pacolet",
		state: "SC",
	},
	{
		city: "South Union",
		state: "SC",
	},
	{
		city: "Daviston",
		state: "SC",
	},
	{
		city: "Perry",
		state: "SC",
	},
	{
		city: "Elko",
		state: "SC",
	},
	{
		city: "Antreville",
		state: "SC",
	},
	{
		city: "Lowrys",
		state: "SC",
	},
	{
		city: "Dacusville",
		state: "SC",
	},
	{
		city: "Nichols",
		state: "SC",
	},
	{
		city: "Jacksonboro",
		state: "SC",
	},
	{
		city: "Ward",
		state: "SC",
	},
	{
		city: "Rembert",
		state: "SC",
	},
	{
		city: "Wisacky",
		state: "SC",
	},
	{
		city: "Mulberry",
		state: "SC",
	},
	{
		city: "Waterloo",
		state: "SC",
	},
	{
		city: "Olar",
		state: "SC",
	},
	{
		city: "Kline",
		state: "SC",
	},
	{
		city: "Floydale",
		state: "SC",
	},
	{
		city: "Shiloh",
		state: "SC",
	},
	{
		city: "Gifford",
		state: "SC",
	},
	{
		city: "Cordova",
		state: "SC",
	},
	{
		city: "Newry",
		state: "SC",
	},
	{
		city: "Williams",
		state: "SC",
	},
	{
		city: "Scotia",
		state: "SC",
	},
	{
		city: "Lodge",
		state: "SC",
	},
	{
		city: "Hodges",
		state: "SC",
	},
	{
		city: "Boykin",
		state: "SC",
	},
	{
		city: "Troy",
		state: "SC",
	},
	{
		city: "Parksville",
		state: "SC",
	},
	{
		city: "Salem",
		state: "SC",
	},
	{
		city: "Starr",
		state: "SC",
	},
	{
		city: "Sellers",
		state: "SC",
	},
	{
		city: "Livingston",
		state: "SC",
	},
	{
		city: "Silverstreet",
		state: "SC",
	},
	{
		city: "Mount Croghan",
		state: "SC",
	},
	{
		city: "Gillisonville",
		state: "SC",
	},
	{
		city: "Woodford",
		state: "SC",
	},
	{
		city: "Bradley",
		state: "SC",
	},
	{
		city: "Lowndesville",
		state: "SC",
	},
	{
		city: "Foreston",
		state: "SC",
	},
	{
		city: "Smoaks",
		state: "SC",
	},
	{
		city: "Rockville",
		state: "SC",
	},
	{
		city: "Ware Place",
		state: "SC",
	},
	{
		city: "Clarks Hill",
		state: "SC",
	},
	{
		city: "Sycamore",
		state: "SC",
	},
	{
		city: "Green Sea",
		state: "SC",
	},
	{
		city: "Tatum",
		state: "SC",
	},
	{
		city: "Pomaria",
		state: "SC",
	},
	{
		city: "Coronaca",
		state: "SC",
	},
	{
		city: "Blenheim",
		state: "SC",
	},
	{
		city: "Zion",
		state: "SC",
	},
	{
		city: "Jamestown",
		state: "SC",
	},
	{
		city: "Luray",
		state: "SC",
	},
	{
		city: "Peak",
		state: "SC",
	},
	{
		city: "Pawleys Island",
		state: "SC",
	},
	{
		city: "Ulmer",
		state: "SC",
	},
	{
		city: "Plum Branch",
		state: "SC",
	},
	{
		city: "Live Oak",
		state: "SC",
	},
	{
		city: "Vance",
		state: "SC",
	},
	{
		city: "Rains",
		state: "SC",
	},
	{
		city: "Tamassee",
		state: "SC",
	},
	{
		city: "Willington",
		state: "SC",
	},
	{
		city: "Mountville",
		state: "SC",
	},
	{
		city: "Princeton",
		state: "SC",
	},
	{
		city: "Saint Charles",
		state: "SC",
	},
	{
		city: "Gramling",
		state: "SC",
	},
	{
		city: "Ashwood",
		state: "SC",
	},
	{
		city: "Govan",
		state: "SC",
	},
	{
		city: "Tradesville",
		state: "SC",
	},
	{
		city: "Cross Anchor",
		state: "SC",
	},
	{
		city: "Fingerville",
		state: "SC",
	},
	{
		city: "Caesars Head",
		state: "SC",
	},
	{
		city: "Browntown",
		state: "SC",
	},
	{
		city: "Longcreek",
		state: "SC",
	},
	{
		city: "Smyrna",
		state: "SC",
	},
	{
		city: "Cope",
		state: "SC",
	},
	{
		city: "Jenkinsville",
		state: "SC",
	},
	{
		city: "Islandton",
		state: "SC",
	},
	{
		city: "Abney Crossroads",
		state: "SC",
	},
	{
		city: "Ketchuptown",
		state: "SC",
	},
	{
		city: "Coosawhatchie",
		state: "SC",
	},
	{
		city: "Cherokee Falls",
		state: "SC",
	},
]

const FloridaLocations = [
	{
		city: "Miami",
		state: "FL",
	},
	{
		city: "Tampa",
		state: "FL",
	},
	{
		city: "Orlando",
		state: "FL",
	},
	{
		city: "Jacksonville",
		state: "FL",
	},
	{
		city: "Cape Coral",
		state: "FL",
	},
	{
		city: "Palm Bay",
		state: "FL",
	},
	{
		city: "Port St. Lucie",
		state: "FL",
	},
	{
		city: "Bonita Springs",
		state: "FL",
	},
	{
		city: "Kissimmee",
		state: "FL",
	},
	{
		city: "Pensacola",
		state: "FL",
	},
	{
		city: "Winter Haven",
		state: "FL",
	},
	{
		city: "Lakeland",
		state: "FL",
	},
	{
		city: "Tallahassee",
		state: "FL",
	},
	{
		city: "St. Petersburg",
		state: "FL",
	},
	{
		city: "Hialeah",
		state: "FL",
	},
	{
		city: "Deltona",
		state: "FL",
	},
	{
		city: "Gainesville",
		state: "FL",
	},
	{
		city: "Ocala",
		state: "FL",
	},
	{
		city: "Fort Lauderdale",
		state: "FL",
	},
	{
		city: "Spring Hill",
		state: "FL",
	},
	{
		city: "Pembroke Pines",
		state: "FL",
	},
	{
		city: "Panama City",
		state: "FL",
	},
	{
		city: "Leesburg",
		state: "FL",
	},
	{
		city: "Hollywood",
		state: "FL",
	},
	{
		city: "Miramar",
		state: "FL",
	},
	{
		city: "Coral Springs",
		state: "FL",
	},
	{
		city: "Lehigh Acres",
		state: "FL",
	},
	{
		city: "Clearwater",
		state: "FL",
	},
	{
		city: "West Palm Beach",
		state: "FL",
	},
	{
		city: "Brandon",
		state: "FL",
	},
	{
		city: "Miami Gardens",
		state: "FL",
	},
	{
		city: "Pompano Beach",
		state: "FL",
	},
	{
		city: "Davie",
		state: "FL",
	},
	{
		city: "Riverview",
		state: "FL",
	},
	{
		city: "St. Augustine",
		state: "FL",
	},
	{
		city: "Sunrise",
		state: "FL",
	},
	{
		city: "Boca Raton",
		state: "FL",
	},
	{
		city: "Plantation",
		state: "FL",
	},
	{
		city: "Alafaya",
		state: "FL",
	},
	{
		city: "Town 'n' Country",
		state: "FL",
	},
	{
		city: "Palm Coast",
		state: "FL",
	},
	{
		city: "Deerfield Beach",
		state: "FL",
	},
	{
		city: "Fort Myers",
		state: "FL",
	},
	{
		city: "Pine Hills",
		state: "FL",
	},
	{
		city: "Melbourne",
		state: "FL",
	},
	{
		city: "Miami Beach",
		state: "FL",
	},
	{
		city: "Largo",
		state: "FL",
	},
	{
		city: "Boynton Beach",
		state: "FL",
	},
	{
		city: "Homestead",
		state: "FL",
	},
	{
		city: "Kendall",
		state: "FL",
	},
	{
		city: "The Villages",
		state: "FL",
	},
	{
		city: "North Port",
		state: "FL",
	},
	{
		city: "Lauderhill",
		state: "FL",
	},
	{
		city: "Doral",
		state: "FL",
	},
	{
		city: "Tamarac",
		state: "FL",
	},
	{
		city: "Daytona Beach",
		state: "FL",
	},
	{
		city: "Poinciana",
		state: "FL",
	},
	{
		city: "Weston",
		state: "FL",
	},
	{
		city: "Delray Beach",
		state: "FL",
	},
	{
		city: "Wesley Chapel",
		state: "FL",
	},
	{
		city: "Port Charlotte",
		state: "FL",
	},
	{
		city: "Port Orange",
		state: "FL",
	},
	{
		city: "The Hammocks",
		state: "FL",
	},
	{
		city: "Wellington",
		state: "FL",
	},
	{
		city: "Palm Harbor",
		state: "FL",
	},
	{
		city: "Jupiter",
		state: "FL",
	},
	{
		city: "North Miami",
		state: "FL",
	},
	{
		city: "Sanford",
		state: "FL",
	},
	{
		city: "Palm Beach Gardens",
		state: "FL",
	},
	{
		city: "Margate",
		state: "FL",
	},
	{
		city: "Fountainebleau",
		state: "FL",
	},
	{
		city: "St. Cloud",
		state: "FL",
	},
	{
		city: "Coconut Creek",
		state: "FL",
	},
	{
		city: "Bradenton",
		state: "FL",
	},
	{
		city: "Tamiami",
		state: "FL",
	},
	{
		city: "Westchester",
		state: "FL",
	},
	{
		city: "Apopka",
		state: "FL",
	},
	{
		city: "Horizon West",
		state: "FL",
	},
	{
		city: "Sarasota",
		state: "FL",
	},
	{
		city: "Kendale Lakes",
		state: "FL",
	},
	{
		city: "Pinellas Park",
		state: "FL",
	},
	{
		city: "Country Club",
		state: "FL",
	},
	{
		city: "Coral Gables",
		state: "FL",
	},
	{
		city: "University",
		state: "FL",
	},
	{
		city: "Titusville",
		state: "FL",
	},
	{
		city: "Ocoee",
		state: "FL",
	},
	{
		city: "Fort Pierce",
		state: "FL",
	},
	{
		city: "Winter Garden",
		state: "FL",
	},
	{
		city: "Altamonte Springs",
		state: "FL",
	},
	{
		city: "Cutler Bay",
		state: "FL",
	},
	{
		city: "North Lauderdale",
		state: "FL",
	},
	{
		city: "Oakland Park",
		state: "FL",
	},
	{
		city: "Greenacres",
		state: "FL",
	},
	{
		city: "North Miami Beach",
		state: "FL",
	},
	{
		city: "Ormond Beach",
		state: "FL",
	},
	{
		city: "Clermont",
		state: "FL",
	},
	{
		city: "North Fort Myers",
		state: "FL",
	},
	{
		city: "Hallandale Beach",
		state: "FL",
	},
	{
		city: "The Acreage",
		state: "FL",
	},
	{
		city: "Meadow Woods",
		state: "FL",
	},
	{
		city: "Aventura",
		state: "FL",
	},
	{
		city: "Valrico",
		state: "FL",
	},
	{
		city: "Oviedo",
		state: "FL",
	},
	{
		city: "Plant City",
		state: "FL",
	},
	{
		city: "Navarre",
		state: "FL",
	},
	{
		city: "Royal Palm Beach",
		state: "FL",
	},
	{
		city: "Land O' Lakes",
		state: "FL",
	},
	{
		city: "Winter Springs",
		state: "FL",
	},
	{
		city: "Richmond West",
		state: "FL",
	},
	{
		city: "Riviera Beach",
		state: "FL",
	},
	{
		city: "Kendall West",
		state: "FL",
	},
	{
		city: "DeLand",
		state: "FL",
	},
	{
		city: "Princeton",
		state: "FL",
	},
	{
		city: "South Miami Heights",
		state: "FL",
	},
	{
		city: "Estero",
		state: "FL",
	},
	{
		city: "Egypt Lake-Leto",
		state: "FL",
	},
	{
		city: "Dunedin",
		state: "FL",
	},
	{
		city: "Buenaventura Lakes",
		state: "FL",
	},
	{
		city: "Lauderdale Lakes",
		state: "FL",
	},
	{
		city: "Carrollwood",
		state: "FL",
	},
	{
		city: "Fruit Cove",
		state: "FL",
	},
	{
		city: "Merritt Island",
		state: "FL",
	},
	{
		city: "Golden Glades",
		state: "FL",
	},
	{
		city: "Cooper City",
		state: "FL",
	},
	{
		city: "Parkland",
		state: "FL",
	},
	{
		city: "West Little River",
		state: "FL",
	},
	{
		city: "East Lake",
		state: "FL",
	},
	{
		city: "Dania Beach",
		state: "FL",
	},
	{
		city: "Lake Magdalene",
		state: "FL",
	},
	{
		city: "Lakeside",
		state: "FL",
	},
	{
		city: "Miami Lakes",
		state: "FL",
	},
	{
		city: "Ferry Pass",
		state: "FL",
	},
	{
		city: "East Lake-Orient Park",
		state: "FL",
	},
	{
		city: "New Smyrna Beach",
		state: "FL",
	},
	{
		city: "Winter Park",
		state: "FL",
	},
	{
		city: "Vero Beach South",
		state: "FL",
	},
	{
		city: "Fleming Island",
		state: "FL",
	},
	{
		city: "Lakewood Ranch",
		state: "FL",
	},
	{
		city: "Golden Gate",
		state: "FL",
	},
	{
		city: "Oakleaf Plantation",
		state: "FL",
	},
	{
		city: "Casselberry",
		state: "FL",
	},
	{
		city: "Immokalee",
		state: "FL",
	},
	{
		city: "Rockledge",
		state: "FL",
	},
	{
		city: "Citrus Park",
		state: "FL",
	},
	{
		city: "Crestview",
		state: "FL",
	},
	{
		city: "Sun City Center",
		state: "FL",
	},
	{
		city: "Key West",
		state: "FL",
	},
	{
		city: "Leisure City",
		state: "FL",
	},
	{
		city: "Palm Springs",
		state: "FL",
	},
	{
		city: "Temple Terrace",
		state: "FL",
	},
	{
		city: "Ruskin",
		state: "FL",
	},
	{
		city: "Haines City",
		state: "FL",
	},
	{
		city: "Oak Ridge",
		state: "FL",
	},
	{
		city: "Coral Terrace",
		state: "FL",
	},
	{
		city: "West Melbourne",
		state: "FL",
	},
	{
		city: "Ives Estates",
		state: "FL",
	},
	{
		city: "Palm River-Clair Mel",
		state: "FL",
	},
	{
		city: "Palm City",
		state: "FL",
	},
	{
		city: "Keystone",
		state: "FL",
	},
	{
		city: "Silver Springs Shores",
		state: "FL",
	},
	{
		city: "Bayonet Point",
		state: "FL",
	},
	{
		city: "Tarpon Springs",
		state: "FL",
	},
	{
		city: "Bloomingdale",
		state: "FL",
	},
	{
		city: "South Bradenton",
		state: "FL",
	},
	{
		city: "Northdale",
		state: "FL",
	},
	{
		city: "Venice",
		state: "FL",
	},
	{
		city: "Sebastian",
		state: "FL",
	},
	{
		city: "Wright",
		state: "FL",
	},
	{
		city: "Apollo Beach",
		state: "FL",
	},
	{
		city: "Port St. John",
		state: "FL",
	},
	{
		city: "Fish Hawk",
		state: "FL",
	},
	{
		city: "Palmetto Bay",
		state: "FL",
	},
	{
		city: "Westchase",
		state: "FL",
	},
	{
		city: "Wekiwa Springs",
		state: "FL",
	},
	{
		city: "Lutz",
		state: "FL",
	},
	{
		city: "Pace",
		state: "FL",
	},
	{
		city: "Jacksonville Beach",
		state: "FL",
	},
	{
		city: "Jasmine Estates",
		state: "FL",
	},
	{
		city: "Edgewater",
		state: "FL",
	},
	{
		city: "Hialeah Gardens",
		state: "FL",
	},
	{
		city: "Bellview",
		state: "FL",
	},
	{
		city: "Eustis",
		state: "FL",
	},
	{
		city: "The Crossings",
		state: "FL",
	},
	{
		city: "Sunny Isles Beach",
		state: "FL",
	},
	{
		city: "Florida Ridge",
		state: "FL",
	},
	{
		city: "DeBary",
		state: "FL",
	},
	{
		city: "Ensley",
		state: "FL",
	},
	{
		city: "West Pensacola",
		state: "FL",
	},
	{
		city: "Brent",
		state: "FL",
	},
	{
		city: "Holiday",
		state: "FL",
	},
	{
		city: "Liberty Triangle",
		state: "FL",
	},
	{
		city: "Lealman",
		state: "FL",
	},
	{
		city: "Fort Walton Beach",
		state: "FL",
	},
	{
		city: "Marion Oaks",
		state: "FL",
	},
	{
		city: "Palm Valley",
		state: "FL",
	},
	{
		city: "World Golf Village",
		state: "FL",
	},
	{
		city: "Bayshore Gardens",
		state: "FL",
	},
	{
		city: "Englewood",
		state: "FL",
	},
	{
		city: "Midway",
		state: "FL",
	},
	{
		city: "Nocatee",
		state: "FL",
	},
	{
		city: "Hunters Creek",
		state: "FL",
	},
	{
		city: "Sweetwater",
		state: "FL",
	},
	{
		city: "Lynn Haven",
		state: "FL",
	},
	{
		city: "Punta Gorda",
		state: "FL",
	},
	{
		city: "Seminole",
		state: "FL",
	},
	{
		city: "Naples",
		state: "FL",
	},
	{
		city: "Maitland",
		state: "FL",
	},
	{
		city: "Cocoa",
		state: "FL",
	},
	{
		city: "Bartow",
		state: "FL",
	},
	{
		city: "Bradfordville",
		state: "FL",
	},
	{
		city: "Country Walk",
		state: "FL",
	},
	{
		city: "San Carlos Park",
		state: "FL",
	},
	{
		city: "Pinecrest",
		state: "FL",
	},
	{
		city: "Tavares",
		state: "FL",
	},
	{
		city: "Gibsonton",
		state: "FL",
	},
	{
		city: "Trinity",
		state: "FL",
	},
	{
		city: "Upper Grand Lagoon",
		state: "FL",
	},
	{
		city: "Groveland",
		state: "FL",
	},
	{
		city: "Panama City Beach",
		state: "FL",
	},
	{
		city: "Brownsville",
		state: "FL",
	},
	{
		city: "Lake Butler",
		state: "FL",
	},
	{
		city: "Stuart",
		state: "FL",
	},
	{
		city: "Glenvar Heights",
		state: "FL",
	},
	{
		city: "Pinewood",
		state: "FL",
	},
	{
		city: "Safety Harbor",
		state: "FL",
	},
	{
		city: "Myrtle Grove",
		state: "FL",
	},
	{
		city: "Belle Glade",
		state: "FL",
	},
	{
		city: "Zephyrhills",
		state: "FL",
	},
	{
		city: "Palmetto Estates",
		state: "FL",
	},
	{
		city: "Ojus",
		state: "FL",
	},
	{
		city: "Lake Mary",
		state: "FL",
	},
	{
		city: "South Venice",
		state: "FL",
	},
	{
		city: "New Port Richey",
		state: "FL",
	},
	{
		city: "Opa-locka",
		state: "FL",
	},
	{
		city: "Vero Beach",
		state: "FL",
	},
	{
		city: "Lake Wales",
		state: "FL",
	},
	{
		city: "Warrington",
		state: "FL",
	},
	{
		city: "Marco Island",
		state: "FL",
	},
	{
		city: "Mount Dora",
		state: "FL",
	},
	{
		city: "Auburndale",
		state: "FL",
	},
	{
		city: "Lady Lake",
		state: "FL",
	},
	{
		city: "Southchase",
		state: "FL",
	},
	{
		city: "Azalea Park",
		state: "FL",
	},
	{
		city: "Niceville",
		state: "FL",
	},
	{
		city: "Three Lakes",
		state: "FL",
	},
	{
		city: "Longwood",
		state: "FL",
	},
	{
		city: "West Park",
		state: "FL",
	},
	{
		city: "Oldsmar",
		state: "FL",
	},
	{
		city: "Wildwood",
		state: "FL",
	},
	{
		city: "Homosassa Springs",
		state: "FL",
	},
	{
		city: "Fruitville",
		state: "FL",
	},
	{
		city: "East Milton",
		state: "FL",
	},
	{
		city: "Key Biscayne",
		state: "FL",
	},
	{
		city: "Palmer Ranch",
		state: "FL",
	},
	{
		city: "Sunset",
		state: "FL",
	},
	{
		city: "Lockhart",
		state: "FL",
	},
	{
		city: "Gonzalez",
		state: "FL",
	},
	{
		city: "Viera West",
		state: "FL",
	},
	{
		city: "Bellair-Meadowbrook Terrace",
		state: "FL",
	},
	{
		city: "Thonotosassa",
		state: "FL",
	},
	{
		city: "Yulee",
		state: "FL",
	},
	{
		city: "Gladeview",
		state: "FL",
	},
	{
		city: "Forest City",
		state: "FL",
	},
	{
		city: "Hobe Sound",
		state: "FL",
	},
	{
		city: "Miami Springs",
		state: "FL",
	},
	{
		city: "West Lealman",
		state: "FL",
	},
	{
		city: "Villas",
		state: "FL",
	},
	{
		city: "Destin",
		state: "FL",
	},
	{
		city: "Minneola",
		state: "FL",
	},
	{
		city: "Olympia Heights",
		state: "FL",
	},
	{
		city: "Callaway",
		state: "FL",
	},
	{
		city: "Conway",
		state: "FL",
	},
	{
		city: "Lakewood Park",
		state: "FL",
	},
	{
		city: "Jupiter Farms",
		state: "FL",
	},
	{
		city: "Atlantic Beach",
		state: "FL",
	},
	{
		city: "Elfers",
		state: "FL",
	},
	{
		city: "Palmetto",
		state: "FL",
	},
	{
		city: "Iona",
		state: "FL",
	},
	{
		city: "Jensen Beach",
		state: "FL",
	},
	{
		city: "North Palm Beach",
		state: "FL",
	},
	{
		city: "South Daytona",
		state: "FL",
	},
	{
		city: "Naranja",
		state: "FL",
	},
	{
		city: "Florida City",
		state: "FL",
	},
	{
		city: "Cheval",
		state: "FL",
	},
	{
		city: "Holly Hill",
		state: "FL",
	},
	{
		city: "Orange City",
		state: "FL",
	},
	{
		city: "Fernandina Beach",
		state: "FL",
	},
	{
		city: "Goldenrod",
		state: "FL",
	},
	{
		city: "Doctor Phillips",
		state: "FL",
	},
	{
		city: "Sarasota Springs",
		state: "FL",
	},
	{
		city: "Shady Hills",
		state: "FL",
	},
	{
		city: "Cypress Lake",
		state: "FL",
	},
	{
		city: "Lake City",
		state: "FL",
	},
	{
		city: "Middleburg",
		state: "FL",
	},
	{
		city: "Viera East",
		state: "FL",
	},
	{
		city: "South Miami",
		state: "FL",
	},
	{
		city: "Gulfport",
		state: "FL",
	},
	{
		city: "On Top of the World Designated Place",
		state: "FL",
	},
	{
		city: "Pasadena Hills",
		state: "FL",
	},
	{
		city: "Fairview Shores",
		state: "FL",
	},
	{
		city: "Asbury Lake",
		state: "FL",
	},
	{
		city: "Mango",
		state: "FL",
	},
	{
		city: "Miami Shores",
		state: "FL",
	},
	{
		city: "Wilton Manors",
		state: "FL",
	},
	{
		city: "Lantana",
		state: "FL",
	},
	{
		city: "Medulla",
		state: "FL",
	},
	{
		city: "Port Salerno",
		state: "FL",
	},
	{
		city: "Lakeland Highlands",
		state: "FL",
	},
	{
		city: "Cocoa Beach",
		state: "FL",
	},
	{
		city: "Celebration",
		state: "FL",
	},
	{
		city: "Laurel",
		state: "FL",
	},
	{
		city: "Hudson",
		state: "FL",
	},
	{
		city: "Pine Castle",
		state: "FL",
	},
	{
		city: "Wimauma",
		state: "FL",
	},
	{
		city: "New Port Richey East",
		state: "FL",
	},
	{
		city: "Fuller Heights",
		state: "FL",
	},
	{
		city: "Satellite Beach",
		state: "FL",
	},
	{
		city: "Memphis",
		state: "FL",
	},
	{
		city: "Westview",
		state: "FL",
	},
	{
		city: "Highland City",
		state: "FL",
	},
	{
		city: "Goulds",
		state: "FL",
	},
	{
		city: "Key Largo",
		state: "FL",
	},
	{
		city: "Sebring",
		state: "FL",
	},
	{
		city: "Gateway",
		state: "FL",
	},
	{
		city: "Westwood Lakes",
		state: "FL",
	},
	{
		city: "Sugarmill Woods",
		state: "FL",
	},
	{
		city: "Pebble Creek",
		state: "FL",
	},
	{
		city: "Lighthouse Point",
		state: "FL",
	},
	{
		city: "Alachua",
		state: "FL",
	},
	{
		city: "Bithlo",
		state: "FL",
	},
	{
		city: "Palatka",
		state: "FL",
	},
	{
		city: "Union Park",
		state: "FL",
	},
	{
		city: "Richmond Heights",
		state: "FL",
	},
	{
		city: "Beverly Hills",
		state: "FL",
	},
	{
		city: "Cypress Gardens",
		state: "FL",
	},
	{
		city: "Citrus Springs",
		state: "FL",
	},
	{
		city: "West Vero Corridor",
		state: "FL",
	},
	{
		city: "Progress Village",
		state: "FL",
	},
	{
		city: "West Perrine",
		state: "FL",
	},
	{
		city: "Pine Ridge",
		state: "FL",
	},
	{
		city: "Milton",
		state: "FL",
	},
	{
		city: "Cape Canaveral",
		state: "FL",
	},
	{
		city: "Bee Ridge",
		state: "FL",
	},
	{
		city: "Rotonda",
		state: "FL",
	},
	{
		city: "St. Augustine Shores",
		state: "FL",
	},
	{
		city: "Avon Park",
		state: "FL",
	},
	{
		city: "Bardmoor",
		state: "FL",
	},
	{
		city: "Marathon",
		state: "FL",
	},
	{
		city: "Hernando",
		state: "FL",
	},
	{
		city: "Green Cove Springs",
		state: "FL",
	},
	{
		city: "Palm Beach",
		state: "FL",
	},
	{
		city: "Miramar Beach",
		state: "FL",
	},
	{
		city: "Davenport",
		state: "FL",
	},
	{
		city: "Westgate",
		state: "FL",
	},
	{
		city: "Lake Park",
		state: "FL",
	},
	{
		city: "St. Pete Beach",
		state: "FL",
	},
	{
		city: "Indian Harbour Beach",
		state: "FL",
	},
	{
		city: "Brooksville",
		state: "FL",
	},
	{
		city: "Orange Park",
		state: "FL",
	},
	{
		city: "Micco",
		state: "FL",
	},
	{
		city: "Broadview Park",
		state: "FL",
	},
	{
		city: "Seffner",
		state: "FL",
	},
	{
		city: "North Merritt Island",
		state: "FL",
	},
	{
		city: "Springfield",
		state: "FL",
	},
	{
		city: "Citrus Hills",
		state: "FL",
	},
	{
		city: "Fern Park",
		state: "FL",
	},
	{
		city: "North Bay Village",
		state: "FL",
	},
	{
		city: "Quincy",
		state: "FL",
	},
	{
		city: "McGregor",
		state: "FL",
	},
	{
		city: "Wedgefield",
		state: "FL",
	},
	{
		city: "Fruitland Park",
		state: "FL",
	},
	{
		city: "Mims",
		state: "FL",
	},
	{
		city: "South Apopka",
		state: "FL",
	},
	{
		city: "Odessa",
		state: "FL",
	},
	{
		city: "Southeast Arcadia",
		state: "FL",
	},
	{
		city: "Southwest Ranches",
		state: "FL",
	},
	{
		city: "Inverness Highlands South",
		state: "FL",
	},
	{
		city: "Arcadia",
		state: "FL",
	},
	{
		city: "Williamsburg",
		state: "FL",
	},
	{
		city: "Clewiston",
		state: "FL",
	},
	{
		city: "Longboat Key",
		state: "FL",
	},
	{
		city: "Sky Lake",
		state: "FL",
	},
	{
		city: "Inverness",
		state: "FL",
	},
	{
		city: "Lecanto",
		state: "FL",
	},
	{
		city: "Lake Lorraine",
		state: "FL",
	},
	{
		city: "Inwood",
		state: "FL",
	},
	{
		city: "Newberry",
		state: "FL",
	},
	{
		city: "North Weeki Wachee",
		state: "FL",
	},
	{
		city: "Macclenny",
		state: "FL",
	},
	{
		city: "Vero Lake Estates",
		state: "FL",
	},
	{
		city: "Orlovista",
		state: "FL",
	},
	{
		city: "Dade City",
		state: "FL",
	},
	{
		city: "Beacon Square",
		state: "FL",
	},
	{
		city: "West Miami",
		state: "FL",
	},
	{
		city: "Jan Phyl Village",
		state: "FL",
	},
	{
		city: "Lely Resort",
		state: "FL",
	},
	{
		city: "South Patrick Shores",
		state: "FL",
	},
	{
		city: "Islamorada, Village of Islands",
		state: "FL",
	},
	{
		city: "Fort Pierce North",
		state: "FL",
	},
	{
		city: "Heathrow",
		state: "FL",
	},
	{
		city: "Belle Isle",
		state: "FL",
	},
	{
		city: "Perry",
		state: "FL",
	},
	{
		city: "Neptune Beach",
		state: "FL",
	},
	{
		city: "West Samoset",
		state: "FL",
	},
	{
		city: "Live Oak",
		state: "FL",
	},
	{
		city: "St. Augustine Beach",
		state: "FL",
	},
	{
		city: "Loughman",
		state: "FL",
	},
	{
		city: "Indiantown",
		state: "FL",
	},
	{
		city: "Mascotte",
		state: "FL",
	},
	{
		city: "Treasure Island",
		state: "FL",
	},
	{
		city: "Indian River Estates",
		state: "FL",
	},
	{
		city: "Willow Oak",
		state: "FL",
	},
	{
		city: "Marianna",
		state: "FL",
	},
	{
		city: "Sanibel",
		state: "FL",
	},
	{
		city: "Ormond-by-the-Sea",
		state: "FL",
	},
	{
		city: "Osprey",
		state: "FL",
	},
	{
		city: "Naples Manor",
		state: "FL",
	},
	{
		city: "South Bay",
		state: "FL",
	},
	{
		city: "River Park",
		state: "FL",
	},
	{
		city: "Pembroke Park",
		state: "FL",
	},
	{
		city: "Gulf Breeze",
		state: "FL",
	},
	{
		city: "Pelican Bay",
		state: "FL",
	},
	{
		city: "Lauderdale-by-the-Sea",
		state: "FL",
	},
	{
		city: "Cocoa West",
		state: "FL",
	},
	{
		city: "High Springs",
		state: "FL",
	},
	{
		city: "Lake Alfred",
		state: "FL",
	},
	{
		city: "Kathleen",
		state: "FL",
	},
	{
		city: "South Gate Ridge",
		state: "FL",
	},
	{
		city: "Tequesta",
		state: "FL",
	},
	{
		city: "Southgate",
		state: "FL",
	},
	{
		city: "Connerton",
		state: "FL",
	},
	{
		city: "Bay Harbor Islands",
		state: "FL",
	},
	{
		city: "DeFuniak Springs",
		state: "FL",
	},
	{
		city: "Crystal Lake",
		state: "FL",
	},
	{
		city: "Ocean City",
		state: "FL",
	},
	{
		city: "South Highpoint",
		state: "FL",
	},
	{
		city: "Fort Myers Shores",
		state: "FL",
	},
	{
		city: "Fort Myers Beach",
		state: "FL",
	},
	{
		city: "Starke",
		state: "FL",
	},
	{
		city: "Surfside",
		state: "FL",
	},
	{
		city: "Naples Park",
		state: "FL",
	},
	{
		city: "Rio Pinar",
		state: "FL",
	},
	{
		city: "Pahokee",
		state: "FL",
	},
	{
		city: "Ellenton",
		state: "FL",
	},
	{
		city: "Palm Springs North",
		state: "FL",
	},
	{
		city: "Port LaBelle",
		state: "FL",
	},
	{
		city: "Freeport",
		state: "FL",
	},
	{
		city: "Zephyrhills West",
		state: "FL",
	},
	{
		city: "Combee Settlement",
		state: "FL",
	},
	{
		city: "Pensacola Station",
		state: "FL",
	},
	{
		city: "Siesta Key",
		state: "FL",
	},
	{
		city: "Cortez",
		state: "FL",
	},
	{
		city: "Mount Plymouth",
		state: "FL",
	},
	{
		city: "St. Augustine South",
		state: "FL",
	},
	{
		city: "River Ridge",
		state: "FL",
	},
	{
		city: "The Meadows",
		state: "FL",
	},
	{
		city: "South Pasadena",
		state: "FL",
	},
	{
		city: "Belleview",
		state: "FL",
	},
	{
		city: "Zephyrhills South",
		state: "FL",
	},
	{
		city: "Okeechobee",
		state: "FL",
	},
	{
		city: "Whiskey Creek",
		state: "FL",
	},
	{
		city: "Samsula-Spruce Creek",
		state: "FL",
	},
	{
		city: "Tice",
		state: "FL",
	},
	{
		city: "Crawfordville",
		state: "FL",
	},
	{
		city: "Three Oaks",
		state: "FL",
	},
	{
		city: "Fort Meade",
		state: "FL",
	},
	{
		city: "Dundee",
		state: "FL",
	},
	{
		city: "Lochmoor Waterway Estates",
		state: "FL",
	},
	{
		city: "Daytona Beach Shores",
		state: "FL",
	},
	{
		city: "Flagler Beach",
		state: "FL",
	},
	{
		city: "Warm Mineral Springs",
		state: "FL",
	},
	{
		city: "Kenneth City",
		state: "FL",
	},
	{
		city: "Balm",
		state: "FL",
	},
	{
		city: "Ave Maria",
		state: "FL",
	},
	{
		city: "Jasper",
		state: "FL",
	},
	{
		city: "Butler Beach",
		state: "FL",
	},
	{
		city: "Fussels Corner",
		state: "FL",
	},
	{
		city: "Wauchula",
		state: "FL",
	},
	{
		city: "Rainbow Springs",
		state: "FL",
	},
	{
		city: "Floral City",
		state: "FL",
	},
	{
		city: "Lower Grand Lagoon",
		state: "FL",
	},
	{
		city: "Timber Pines",
		state: "FL",
	},
	{
		city: "Stock Island",
		state: "FL",
	},
	{
		city: "Fellsmere",
		state: "FL",
	},
	{
		city: "Valparaiso",
		state: "FL",
	},
	{
		city: "LaBelle",
		state: "FL",
	},
	{
		city: "Bay Hill",
		state: "FL",
	},
	{
		city: "Ridge Wood Heights",
		state: "FL",
	},
	{
		city: "White City",
		state: "FL",
	},
	{
		city: "Nassau Village-Ratliff",
		state: "FL",
	},
	{
		city: "Clarcona",
		state: "FL",
	},
	{
		city: "Big Pine Key",
		state: "FL",
	},
	{
		city: "Brookridge",
		state: "FL",
	},
	{
		city: "South Sarasota",
		state: "FL",
	},
	{
		city: "Charlotte Harbor",
		state: "FL",
	},
	{
		city: "Sawgrass",
		state: "FL",
	},
	{
		city: "Fort Pierce South",
		state: "FL",
	},
	{
		city: "Ridge Manor",
		state: "FL",
	},
	{
		city: "Hutchinson Island South",
		state: "FL",
	},
	{
		city: "South Brooksville",
		state: "FL",
	},
	{
		city: "June Park",
		state: "FL",
	},
	{
		city: "Taylor Creek",
		state: "FL",
	},
	{
		city: "Gifford",
		state: "FL",
	},
	{
		city: "Moon Lake",
		state: "FL",
	},
	{
		city: "Grant-Valkaria",
		state: "FL",
	},
	{
		city: "Orangetree",
		state: "FL",
	},
	{
		city: "Samoset",
		state: "FL",
	},
	{
		city: "Whitfield",
		state: "FL",
	},
	{
		city: "Suncoast Estates",
		state: "FL",
	},
	{
		city: "Parker",
		state: "FL",
	},
	{
		city: "Belleair",
		state: "FL",
	},
	{
		city: "Highland Beach",
		state: "FL",
	},
	{
		city: "Indian River Shores",
		state: "FL",
	},
	{
		city: "Buckingham",
		state: "FL",
	},
	{
		city: "Pea Ridge",
		state: "FL",
	},
	{
		city: "Bagdad",
		state: "FL",
	},
	{
		city: "West Bradenton",
		state: "FL",
	},
	{
		city: "Pine Manor",
		state: "FL",
	},
	{
		city: "Laguna Beach",
		state: "FL",
	},
	{
		city: "Woodville",
		state: "FL",
	},
	{
		city: "Lake Sarasota",
		state: "FL",
	},
	{
		city: "Harbour Heights",
		state: "FL",
	},
	{
		city: "Mary Esther",
		state: "FL",
	},
	{
		city: "Madeira Beach",
		state: "FL",
	},
	{
		city: "Wahneta",
		state: "FL",
	},
	{
		city: "Mulberry",
		state: "FL",
	},
	{
		city: "Tierra Verde",
		state: "FL",
	},
	{
		city: "High Point",
		state: "FL",
	},
	{
		city: "Watergate",
		state: "FL",
	},
	{
		city: "Indian Rocks Beach",
		state: "FL",
	},
	{
		city: "North River Shores",
		state: "FL",
	},
	{
		city: "Juno Beach",
		state: "FL",
	},
	{
		city: "Geneva",
		state: "FL",
	},
	{
		city: "Kensington Park",
		state: "FL",
	},
	{
		city: "Port St. Joe",
		state: "FL",
	},
	{
		city: "Tangerine",
		state: "FL",
	},
	{
		city: "Alturas",
		state: "FL",
	},
	{
		city: "Umatilla",
		state: "FL",
	},
	{
		city: "North Brooksville",
		state: "FL",
	},
	{
		city: "Lake Panasoffkee",
		state: "FL",
	},
	{
		city: "Chipley",
		state: "FL",
	},
	{
		city: "Flagler Estates",
		state: "FL",
	},
	{
		city: "Lake Clarke Shores",
		state: "FL",
	},
	{
		city: "Zephyrhills North",
		state: "FL",
	},
	{
		city: "St. James City",
		state: "FL",
	},
	{
		city: "Winter Beach",
		state: "FL",
	},
	{
		city: "Lely",
		state: "FL",
	},
	{
		city: "Vineyards",
		state: "FL",
	},
	{
		city: "San Castle",
		state: "FL",
	},
	{
		city: "Holden Heights",
		state: "FL",
	},
	{
		city: "Nokomis",
		state: "FL",
	},
	{
		city: "Tiger Point",
		state: "FL",
	},
	{
		city: "Oakland",
		state: "FL",
	},
	{
		city: "Dover",
		state: "FL",
	},
	{
		city: "Cedar Grove",
		state: "FL",
	},
	{
		city: "Goulding",
		state: "FL",
	},
	{
		city: "Montura",
		state: "FL",
	},
	{
		city: "Venice Gardens",
		state: "FL",
	},
	{
		city: "Rainbow Lakes Estates",
		state: "FL",
	},
	{
		city: "West DeLand",
		state: "FL",
	},
	{
		city: "Midway",
		state: "FL",
	},
	{
		city: "Loxahatchee Groves",
		state: "FL",
	},
	{
		city: "Chattahoochee",
		state: "FL",
	},
	{
		city: "Woodlawn Beach",
		state: "FL",
	},
	{
		city: "Feather Sound",
		state: "FL",
	},
	{
		city: "Ponce Inlet",
		state: "FL",
	},
	{
		city: "Ridgecrest",
		state: "FL",
	},
	{
		city: "Crystal River",
		state: "FL",
	},
	{
		city: "Point Baker",
		state: "FL",
	},
	{
		city: "Ocala Estates",
		state: "FL",
	},
	{
		city: "Melbourne Beach",
		state: "FL",
	},
	{
		city: "South Beach",
		state: "FL",
	},
	{
		city: "Bunnell",
		state: "FL",
	},
	{
		city: "Tangelo Park",
		state: "FL",
	},
	{
		city: "Blountstown",
		state: "FL",
	},
	{
		city: "Big Coppitt Key",
		state: "FL",
	},
	{
		city: "Holmes Beach",
		state: "FL",
	},
	{
		city: "Biscayne Park",
		state: "FL",
	},
	{
		city: "Hill 'n Dale",
		state: "FL",
	},
	{
		city: "Williston Highlands",
		state: "FL",
	},
	{
		city: "Harbor Bluffs",
		state: "FL",
	},
	{
		city: "Windermere",
		state: "FL",
	},
	{
		city: "Bal Harbour",
		state: "FL",
	},
	{
		city: "Port Richey",
		state: "FL",
	},
	{
		city: "Bushnell",
		state: "FL",
	},
	{
		city: "Wallace",
		state: "FL",
	},
	{
		city: "Indialantic",
		state: "FL",
	},
	{
		city: "Eagle Lake",
		state: "FL",
	},
	{
		city: "Malabar",
		state: "FL",
	},
	{
		city: "Williston",
		state: "FL",
	},
	{
		city: "Grenelefe",
		state: "FL",
	},
	{
		city: "Frostproof",
		state: "FL",
	},
	{
		city: "Alva",
		state: "FL",
	},
	{
		city: "Hilliard",
		state: "FL",
	},
	{
		city: "Madison",
		state: "FL",
	},
	{
		city: "Silver Springs",
		state: "FL",
	},
	{
		city: "Lake Helen",
		state: "FL",
	},
	{
		city: "Carrabelle",
		state: "FL",
	},
	{
		city: "Meadow Oaks",
		state: "FL",
	},
	{
		city: "Chuluota",
		state: "FL",
	},
	{
		city: "Quail Ridge",
		state: "FL",
	},
	{
		city: "Eastpoint",
		state: "FL",
	},
	{
		city: "Dade City North",
		state: "FL",
	},
	{
		city: "Bonifay",
		state: "FL",
	},
	{
		city: "Haverhill",
		state: "FL",
	},
	{
		city: "Bay Pines",
		state: "FL",
	},
	{
		city: "Eglin AFB",
		state: "FL",
	},
	{
		city: "Hypoluxo",
		state: "FL",
	},
	{
		city: "Edgewood",
		state: "FL",
	},
	{
		city: "Verona Walk",
		state: "FL",
	},
	{
		city: "Cleveland",
		state: "FL",
	},
	{
		city: "Olga",
		state: "FL",
	},
	{
		city: "Polk City",
		state: "FL",
	},
	{
		city: "Seminole Manor",
		state: "FL",
	},
	{
		city: "Inverness Highlands North",
		state: "FL",
	},
	{
		city: "Zellwood",
		state: "FL",
	},
	{
		city: "Apalachicola",
		state: "FL",
	},
	{
		city: "Hurlburt Field",
		state: "FL",
	},
	{
		city: "Sharpes",
		state: "FL",
	},
	{
		city: "Silver Lake",
		state: "FL",
	},
	{
		city: "Island Walk",
		state: "FL",
	},
	{
		city: "Hernando Beach",
		state: "FL",
	},
	{
		city: "Monticello",
		state: "FL",
	},
	{
		city: "Florida Gulf Coast University",
		state: "FL",
	},
	{
		city: "Greenbriar",
		state: "FL",
	},
	{
		city: "Watertown",
		state: "FL",
	},
	{
		city: "Bowling Green",
		state: "FL",
	},
	{
		city: "Harlem",
		state: "FL",
	},
	{
		city: "Pretty Bayou",
		state: "FL",
	},
	{
		city: "Eatonville",
		state: "FL",
	},
	{
		city: "De Leon Springs",
		state: "FL",
	},
	{
		city: "Pine Air",
		state: "FL",
	},
	{
		city: "Cabana Colony",
		state: "FL",
	},
	{
		city: "Vamo",
		state: "FL",
	},
	{
		city: "Belleair Bluffs",
		state: "FL",
	},
	{
		city: "Lake Placid",
		state: "FL",
	},
	{
		city: "Astatula",
		state: "FL",
	},
	{
		city: "Berkshire Lakes",
		state: "FL",
	},
	{
		city: "North Sarasota",
		state: "FL",
	},
	{
		city: "Cross City",
		state: "FL",
	},
	{
		city: "Graceville",
		state: "FL",
	},
	{
		city: "Cudjoe Key",
		state: "FL",
	},
	{
		city: "Roosevelt Gardens",
		state: "FL",
	},
	{
		city: "Tavernier",
		state: "FL",
	},
	{
		city: "Dunnellon",
		state: "FL",
	},
	{
		city: "Lake Mack-Forest Hills",
		state: "FL",
	},
	{
		city: "Holley",
		state: "FL",
	},
	{
		city: "Virginia Gardens",
		state: "FL",
	},
	{
		city: "Pelican Marsh",
		state: "FL",
	},
	{
		city: "Bear Creek",
		state: "FL",
	},
	{
		city: "Malone",
		state: "FL",
	},
	{
		city: "Burnt Store Marina",
		state: "FL",
	},
	{
		city: "Chiefland",
		state: "FL",
	},
	{
		city: "Wabasso Beach",
		state: "FL",
	},
	{
		city: "Lake Mary Jane",
		state: "FL",
	},
	{
		city: "Campbell",
		state: "FL",
	},
	{
		city: "Moore Haven",
		state: "FL",
	},
	{
		city: "Montverde",
		state: "FL",
	},
	{
		city: "Redington Shores",
		state: "FL",
	},
	{
		city: "Mangonia Park",
		state: "FL",
	},
	{
		city: "Havana",
		state: "FL",
	},
	{
		city: "Hillsboro Beach",
		state: "FL",
	},
	{
		city: "Sewall's Point",
		state: "FL",
	},
	{
		city: "Century",
		state: "FL",
	},
	{
		city: "Pine Island Center",
		state: "FL",
	},
	{
		city: "Manatee Road",
		state: "FL",
	},
	{
		city: "Lake Belvedere Estates",
		state: "FL",
	},
	{
		city: "Sneads",
		state: "FL",
	},
	{
		city: "Tropical Park",
		state: "FL",
	},
	{
		city: "Lake Kerr",
		state: "FL",
	},
	{
		city: "Charlotte Park",
		state: "FL",
	},
	{
		city: "Crooked Lake Park",
		state: "FL",
	},
	{
		city: "Grove City",
		state: "FL",
	},
	{
		city: "Crescent City",
		state: "FL",
	},
	{
		city: "Harlem Heights",
		state: "FL",
	},
	{
		city: "Homosassa",
		state: "FL",
	},
	{
		city: "Howey-in-the-Hills",
		state: "FL",
	},
	{
		city: "South Palm Beach",
		state: "FL",
	},
	{
		city: "Ocklawaha",
		state: "FL",
	},
	{
		city: "Oak Hill",
		state: "FL",
	},
	{
		city: "Fort Denaud",
		state: "FL",
	},
	{
		city: "Heritage Pines",
		state: "FL",
	},
	{
		city: "Bokeelia",
		state: "FL",
	},
	{
		city: "Atlantis",
		state: "FL",
	},
	{
		city: "Trenton",
		state: "FL",
	},
	{
		city: "Zolfo Springs",
		state: "FL",
	},
	{
		city: "Wewahitchka",
		state: "FL",
	},
	{
		city: "Rainbow Park",
		state: "FL",
	},
	{
		city: "Desoto Lakes",
		state: "FL",
	},
	{
		city: "El Portal",
		state: "FL",
	},
	{
		city: "Boulevard Gardens",
		state: "FL",
	},
	{
		city: "Oriole Beach",
		state: "FL",
	},
	{
		city: "Mayo",
		state: "FL",
	},
	{
		city: "Baldwin",
		state: "FL",
	},
	{
		city: "Inglis",
		state: "FL",
	},
	{
		city: "Interlachen",
		state: "FL",
	},
	{
		city: "Callahan",
		state: "FL",
	},
	{
		city: "Palm Shores",
		state: "FL",
	},
	{
		city: "Manasota Key",
		state: "FL",
	},
	{
		city: "Patrick AFB",
		state: "FL",
	},
	{
		city: "Gotha",
		state: "FL",
	},
	{
		city: "Limestone Creek",
		state: "FL",
	},
	{
		city: "East Bronson",
		state: "FL",
	},
	{
		city: "Pierson",
		state: "FL",
	},
	{
		city: "Ocean Ridge",
		state: "FL",
	},
	{
		city: "Royal Palm Estates",
		state: "FL",
	},
	{
		city: "Lacoochee",
		state: "FL",
	},
	{
		city: "Punta Rassa",
		state: "FL",
	},
	{
		city: "Roseland",
		state: "FL",
	},
	{
		city: "Yalaha",
		state: "FL",
	},
	{
		city: "Taft",
		state: "FL",
	},
	{
		city: "Belleair Beach",
		state: "FL",
	},
	{
		city: "Navarre Beach",
		state: "FL",
	},
	{
		city: "Hawthorne",
		state: "FL",
	},
	{
		city: "Keystone Heights",
		state: "FL",
	},
	{
		city: "Washington Park",
		state: "FL",
	},
	{
		city: "Key Vista",
		state: "FL",
	},
	{
		city: "Franklin Park",
		state: "FL",
	},
	{
		city: "Verandah",
		state: "FL",
	},
	{
		city: "Cypress Quarters",
		state: "FL",
	},
	{
		city: "Astor",
		state: "FL",
	},
	{
		city: "Bronson",
		state: "FL",
	},
	{
		city: "Tyndall AFB",
		state: "FL",
	},
	{
		city: "East Palatka",
		state: "FL",
	},
	{
		city: "Lake Hamilton",
		state: "FL",
	},
	{
		city: "Pomona Park",
		state: "FL",
	},
	{
		city: "Babson Park",
		state: "FL",
	},
	{
		city: "Christmas",
		state: "FL",
	},
	{
		city: "Old Miakka",
		state: "FL",
	},
	{
		city: "Garden Grove",
		state: "FL",
	},
	{
		city: "St. Leo",
		state: "FL",
	},
	{
		city: "Mexico Beach",
		state: "FL",
	},
	{
		city: "North Key Largo",
		state: "FL",
	},
	{
		city: "Black Hammock",
		state: "FL",
	},
	{
		city: "Gretna",
		state: "FL",
	},
	{
		city: "Redington Beach",
		state: "FL",
	},
	{
		city: "San Antonio",
		state: "FL",
	},
	{
		city: "DeLand Southwest",
		state: "FL",
	},
	{
		city: "Midway",
		state: "FL",
	},
	{
		city: "Seville",
		state: "FL",
	},
	{
		city: "Pine Lakes",
		state: "FL",
	},
	{
		city: "Greenville",
		state: "FL",
	},
	{
		city: "Kenwood Estates",
		state: "FL",
	},
	{
		city: "Solana",
		state: "FL",
	},
	{
		city: "Bristol",
		state: "FL",
	},
	{
		city: "Juno Ridge",
		state: "FL",
	},
	{
		city: "North Redington Beach",
		state: "FL",
	},
	{
		city: "Indian Shores",
		state: "FL",
	},
	{
		city: "Paisley",
		state: "FL",
	},
	{
		city: "Five Points",
		state: "FL",
	},
	{
		city: "Lawtey",
		state: "FL",
	},
	{
		city: "Center Hill",
		state: "FL",
	},
	{
		city: "Glencoe",
		state: "FL",
	},
	{
		city: "Archer",
		state: "FL",
	},
	{
		city: "Panacea",
		state: "FL",
	},
	{
		city: "Palm Beach Shores",
		state: "FL",
	},
	{
		city: "Fanning Springs",
		state: "FL",
	},
	{
		city: "Waldo",
		state: "FL",
	},
	{
		city: "Buckhead Ridge",
		state: "FL",
	},
	{
		city: "Webster",
		state: "FL",
	},
	{
		city: "Black Diamond",
		state: "FL",
	},
	{
		city: "Wabasso",
		state: "FL",
	},
	{
		city: "Desoto Acres",
		state: "FL",
	},
	{
		city: "St. George Island",
		state: "FL",
	},
	{
		city: "Scottsmoor",
		state: "FL",
	},
	{
		city: "Heritage Bay",
		state: "FL",
	},
	{
		city: "Chokoloskee",
		state: "FL",
	},
	{
		city: "Medley",
		state: "FL",
	},
	{
		city: "Homestead Base",
		state: "FL",
	},
	{
		city: "Welaka",
		state: "FL",
	},
	{
		city: "Grand Ridge",
		state: "FL",
	},
	{
		city: "Anna Maria",
		state: "FL",
	},
	{
		city: "Molino",
		state: "FL",
	},
	{
		city: "Stacey Street",
		state: "FL",
	},
	{
		city: "Allentown",
		state: "FL",
	},
	{
		city: "Branford",
		state: "FL",
	},
	{
		city: "Crescent Beach",
		state: "FL",
	},
	{
		city: "Rio",
		state: "FL",
	},
	{
		city: "Cedar Key",
		state: "FL",
	},
	{
		city: "Gulf Stream",
		state: "FL",
	},
	{
		city: "Harold",
		state: "FL",
	},
	{
		city: "Jupiter Island",
		state: "FL",
	},
	{
		city: "Weeki Wachee Gardens",
		state: "FL",
	},
	{
		city: "Cottondale",
		state: "FL",
	},
	{
		city: "Fort Braden",
		state: "FL",
	},
	{
		city: "Bradenton Beach",
		state: "FL",
	},
	{
		city: "Raleigh",
		state: "FL",
	},
	{
		city: "Lake Kathryn",
		state: "FL",
	},
	{
		city: "Page Park",
		state: "FL",
	},
	{
		city: "Crystal Springs",
		state: "FL",
	},
	{
		city: "North DeLand",
		state: "FL",
	},
	{
		city: "St. Lucie Village",
		state: "FL",
	},
	{
		city: "Matlacha",
		state: "FL",
	},
	{
		city: "Hastings",
		state: "FL",
	},
	{
		city: "Shalimar",
		state: "FL",
	},
	{
		city: "White Springs",
		state: "FL",
	},
	{
		city: "Wiscon",
		state: "FL",
	},
	{
		city: "Bradley Junction",
		state: "FL",
	},
	{
		city: "Duck Key",
		state: "FL",
	},
	{
		city: "Chumuckla",
		state: "FL",
	},
	{
		city: "Greensboro",
		state: "FL",
	},
	{
		city: "Masaryktown",
		state: "FL",
	},
	{
		city: "Ponce de Leon",
		state: "FL",
	},
	{
		city: "Palmona Park",
		state: "FL",
	},
	{
		city: "Schall Circle",
		state: "FL",
	},
	{
		city: "Key Colony Beach",
		state: "FL",
	},
	{
		city: "Melbourne Village",
		state: "FL",
	},
	{
		city: "Marco Shores-Hammock Bay",
		state: "FL",
	},
	{
		city: "Gun Club Estates",
		state: "FL",
	},
	{
		city: "Hosford",
		state: "FL",
	},
	{
		city: "Coleman",
		state: "FL",
	},
	{
		city: "Silver Springs Shores East",
		state: "FL",
	},
	{
		city: "Jennings",
		state: "FL",
	},
	{
		city: "Lee",
		state: "FL",
	},
	{
		city: "Vernon",
		state: "FL",
	},
	{
		city: "Andrews",
		state: "FL",
	},
	{
		city: "Penney Farms",
		state: "FL",
	},
	{
		city: "Paxton",
		state: "FL",
	},
	{
		city: "Cinco Bayou",
		state: "FL",
	},
	{
		city: "Fort White",
		state: "FL",
	},
	{
		city: "Jay",
		state: "FL",
	},
	{
		city: "East Williston",
		state: "FL",
	},
	{
		city: "Indian Lake Estates",
		state: "FL",
	},
	{
		city: "Briny Breezes",
		state: "FL",
	},
	{
		city: "Greenwood",
		state: "FL",
	},
	{
		city: "Yankeetown",
		state: "FL",
	},
	{
		city: "Paradise Heights",
		state: "FL",
	},
	{
		city: "Glen St. Mary",
		state: "FL",
	},
	{
		city: "Acacia Villas",
		state: "FL",
	},
	{
		city: "Steinhatchee",
		state: "FL",
	},
	{
		city: "Lake Mystic",
		state: "FL",
	},
	{
		city: "Chaires",
		state: "FL",
	},
	{
		city: "Avalon",
		state: "FL",
	},
	{
		city: "Roeville",
		state: "FL",
	},
	{
		city: "Altha",
		state: "FL",
	},
	{
		city: "Westlake",
		state: "FL",
	},
	{
		city: "Laurel Hill",
		state: "FL",
	},
	{
		city: "Orchid",
		state: "FL",
	},
	{
		city: "Golden Beach",
		state: "FL",
	},
	{
		city: "Reddick",
		state: "FL",
	},
	{
		city: "Beverly Beach",
		state: "FL",
	},
	{
		city: "Sea Ranch Lakes",
		state: "FL",
	},
	{
		city: "Winding Cypress",
		state: "FL",
	},
	{
		city: "Lemon Grove",
		state: "FL",
	},
	{
		city: "Micanopy",
		state: "FL",
	},
	{
		city: "Fort Green Springs",
		state: "FL",
	},
	{
		city: "Pinecraft",
		state: "FL",
	},
	{
		city: "Hampton",
		state: "FL",
	},
	{
		city: "Fisher Island",
		state: "FL",
	},
	{
		city: "Ferndale",
		state: "FL",
	},
	{
		city: "Hillsboro Pines",
		state: "FL",
	},
	{
		city: "Spring Lake",
		state: "FL",
	},
	{
		city: "Wacissa",
		state: "FL",
	},
	{
		city: "Bell",
		state: "FL",
	},
	{
		city: "Jupiter Inlet Colony",
		state: "FL",
	},
	{
		city: "Pineland",
		state: "FL",
	},
	{
		city: "Sopchoppy",
		state: "FL",
	},
	{
		city: "Alford",
		state: "FL",
	},
	{
		city: "Ona",
		state: "FL",
	},
	{
		city: "Westville",
		state: "FL",
	},
	{
		city: "Lake Hart",
		state: "FL",
	},
	{
		city: "Pioneer",
		state: "FL",
	},
	{
		city: "Limestone",
		state: "FL",
	},
	{
		city: "Esto",
		state: "FL",
	},
	{
		city: "Matlacha Isles-Matlacha Shores",
		state: "FL",
	},
	{
		city: "Cloud Lake",
		state: "FL",
	},
	{
		city: "Hillcrest Heights",
		state: "FL",
	},
	{
		city: "Trilby",
		state: "FL",
	},
	{
		city: "Noma",
		state: "FL",
	},
	{
		city: "Gardner",
		state: "FL",
	},
	{
		city: "Wausau",
		state: "FL",
	},
	{
		city: "Brownsdale",
		state: "FL",
	},
	{
		city: "La Crosse",
		state: "FL",
	},
	{
		city: "Canal Point",
		state: "FL",
	},
	{
		city: "Capitola",
		state: "FL",
	},
	{
		city: "Manalapan",
		state: "FL",
	},
	{
		city: "Miccosukee",
		state: "FL",
	},
	{
		city: "St. Marks",
		state: "FL",
	},
	{
		city: "Ocean Breeze",
		state: "FL",
	},
	{
		city: "McIntosh",
		state: "FL",
	},
	{
		city: "Nobleton",
		state: "FL",
	},
	{
		city: "Floridatown",
		state: "FL",
	},
	{
		city: "Caryville",
		state: "FL",
	},
	{
		city: "Windsor",
		state: "FL",
	},
	{
		city: "Raiford",
		state: "FL",
	},
	{
		city: "Garcon Point",
		state: "FL",
	},
	{
		city: "Munson",
		state: "FL",
	},
	{
		city: "Brooker",
		state: "FL",
	},
	{
		city: "Golf",
		state: "FL",
	},
	{
		city: "Jacob City",
		state: "FL",
	},
	{
		city: "Worthington Springs",
		state: "FL",
	},
	{
		city: "Pittman",
		state: "FL",
	},
	{
		city: "Ebro",
		state: "FL",
	},
	{
		city: "Mount Carmel",
		state: "FL",
	},
	{
		city: "Spring Ridge",
		state: "FL",
	},
	{
		city: "Mulat",
		state: "FL",
	},
	{
		city: "Homeland",
		state: "FL",
	},
	{
		city: "Berrydale",
		state: "FL",
	},
	{
		city: "Glen Ridge",
		state: "FL",
	},
	{
		city: "Yeehaw Junction",
		state: "FL",
	},
	{
		city: "Aucilla",
		state: "FL",
	},
	{
		city: "Fort Green",
		state: "FL",
	},
	{
		city: "Highland Park",
		state: "FL",
	},
	{
		city: "Campbellton",
		state: "FL",
	},
	{
		city: "Goodland",
		state: "FL",
	},
	{
		city: "Captiva",
		state: "FL",
	},
	{
		city: "Waverly",
		state: "FL",
	},
	{
		city: "Lloyd",
		state: "FL",
	},
	{
		city: "Okahumpka",
		state: "FL",
	},
	{
		city: "Sorrento",
		state: "FL",
	},
	{
		city: "Lisbon",
		state: "FL",
	},
	{
		city: "Pine Island",
		state: "FL",
	},
	{
		city: "Layton",
		state: "FL",
	},
	{
		city: "Aripeka",
		state: "FL",
	},
	{
		city: "Morriston",
		state: "FL",
	},
	{
		city: "West Canaveral Groves",
		state: "FL",
	},
	{
		city: "Dickerson City",
		state: "FL",
	},
	{
		city: "Day",
		state: "FL",
	},
	{
		city: "Bascom",
		state: "FL",
	},
	{
		city: "Otter Creek",
		state: "FL",
	},
	{
		city: "Waukeenah",
		state: "FL",
	},
	{
		city: "Everglades",
		state: "FL",
	},
	{
		city: "Pine Level",
		state: "FL",
	},
	{
		city: "Altoona",
		state: "FL",
	},
	{
		city: "Lake Lindsey",
		state: "FL",
	},
	{
		city: "Fidelis",
		state: "FL",
	},
	{
		city: "Istachatta",
		state: "FL",
	},
	{
		city: "Belleair Shore",
		state: "FL",
	},
	{
		city: "Sumatra",
		state: "FL",
	},
	{
		city: "Charleston Park",
		state: "FL",
	},
	{
		city: "Cobbtown",
		state: "FL",
	},
	{
		city: "Horseshoe Beach",
		state: "FL",
	},
	{
		city: "Tildenville",
		state: "FL",
	},
	{
		city: "Indian Creek",
		state: "FL",
	},
	{
		city: "Bay Lake",
		state: "FL",
	},
	{
		city: "Dixonville",
		state: "FL",
	},
	{
		city: "Marineland",
		state: "FL",
	},
	{
		city: "Lazy Lake",
		state: "FL",
	},
	{
		city: "Lake Buena Vista",
		state: "FL",
	},
	{
		city: "Lake Harbor",
		state: "FL",
	},
	{
		city: "Lamont",
		state: "FL",
	},
	{
		city: "Plantation Island",
		state: "FL",
	},
	{
		city: "Bayport",
		state: "FL",
	},
]

export const GeorgiaLocations = [
	{ city: "Atlanta", state: "GA" },
	{ city: "Augusta", state: "GA" },
	{ city: "Savannah", state: "GA" },
	{ city: "Columbus", state: "GA" },
	{ city: "Gainesville", state: "GA" },
	{ city: "Macon", state: "GA" },
	{ city: "Warner Robins", state: "GA" },
	{ city: "Athens", state: "GA" },
	{ city: "South Fulton", state: "GA" },
	{ city: "Sandy Springs", state: "GA" },
	{ city: "Roswell", state: "GA" },
	{ city: "Albany", state: "GA" },
	{ city: "Johns Creek", state: "GA" },
	{ city: "Valdosta", state: "GA" },
	{ city: "Brunswick", state: "GA" },
	{ city: "Dalton", state: "GA" },
	{ city: "Alpharetta", state: "GA" },
	{ city: "Marietta", state: "GA" },
	{ city: "Stonecrest", state: "GA" },
	{ city: "Brookhaven", state: "GA" },
	{ city: "Smyrna", state: "GA" },
	{ city: "Dunwoody", state: "GA" },
	{ city: "Newnan", state: "GA" },
	{ city: "Peachtree Corners", state: "GA" },
	{ city: "Mableton", state: "GA" },
	{ city: "Milton", state: "GA" },
	{ city: "Peachtree City", state: "GA" },
	{ city: "East Point", state: "GA" },
	{ city: "Rome", state: "GA" },
	{ city: "Evans", state: "GA" },
	{ city: "Tucker", state: "GA" },
	{ city: "Douglasville", state: "GA" },
	{ city: "Woodstock", state: "GA" },
	{ city: "Hinesville", state: "GA" },
	{ city: "Martinez", state: "GA" },
	{ city: "Canton", state: "GA" },
	{ city: "Kennesaw", state: "GA" },
	{ city: "Statesboro", state: "GA" },
	{ city: "Duluth", state: "GA" },
	{ city: "Redan", state: "GA" },
	{ city: "LaGrange", state: "GA" },
	{ city: "Lawrenceville", state: "GA" },
	{ city: "Chamblee", state: "GA" },
	{ city: "McDonough", state: "GA" },
	{ city: "Stockbridge", state: "GA" },
	{ city: "Union City", state: "GA" },
	{ city: "Carrollton", state: "GA" },
	{ city: "Pooler", state: "GA" },
	{ city: "Sugar Hill", state: "GA" },
	{ city: "Decatur", state: "GA" },
	{ city: "Griffin", state: "GA" },
	{ city: "Cartersville", state: "GA" },
	{ city: "Candler-McAfee", state: "GA" },
	{ city: "Acworth", state: "GA" },
	{ city: "Perry", state: "GA" },
	{ city: "Suwanee", state: "GA" },
	{ city: "Snellville", state: "GA" },
	{ city: "Forest Park", state: "GA" },
	{ city: "Fayetteville", state: "GA" },
	{ city: "Thomasville", state: "GA" },
	{ city: "Kingsland", state: "GA" },
	{ city: "St. Marys", state: "GA" },
	{ city: "Winder", state: "GA" },
	{ city: "Norcross", state: "GA" },
	{ city: "Conyers", state: "GA" },
	{ city: "Villa Rica", state: "GA" },
	{ city: "North Druid Hills", state: "GA" },
	{ city: "Buford", state: "GA" },
	{ city: "Calhoun", state: "GA" },
	{ city: "North Decatur", state: "GA" },
	{ city: "Tifton", state: "GA" },
	{ city: "Milledgeville", state: "GA" },
	{ city: "Powder Springs", state: "GA" },
	{ city: "Richmond Hill", state: "GA" },
	{ city: "Holly Springs", state: "GA" },
	{ city: "Fairburn", state: "GA" },
	{ city: "Grovetown", state: "GA" },
	{ city: "Americus", state: "GA" },
	{ city: "Lithia Springs", state: "GA" },
	{ city: "Dublin", state: "GA" },
	{ city: "St. Simons", state: "GA" },
	{ city: "Wilmington Island", state: "GA" },
	{ city: "Monroe", state: "GA" },
	{ city: "Riverdale", state: "GA" },
	{ city: "Lilburn", state: "GA" },
	{ city: "Belvedere Park", state: "GA" },
	{ city: "Clarkston", state: "GA" },
	{ city: "Moultrie", state: "GA" },
	{ city: "Loganville", state: "GA" },
	{ city: "Bainbridge", state: "GA" },
	{ city: "Covington", state: "GA" },
	{ city: "Dallas", state: "GA" },
	{ city: "College Park", state: "GA" },
	{ city: "Waycross", state: "GA" },
	{ city: "Braselton", state: "GA" },
	{ city: "Jefferson", state: "GA" },
	{ city: "Mountain Park", state: "GA" },
	{ city: "Vinings", state: "GA" },
	{ city: "Georgetown", state: "GA" },
	{ city: "Douglas", state: "GA" },
	{ city: "Port Wentworth", state: "GA" },
	{ city: "Rincon", state: "GA" },
	{ city: "Vidalia", state: "GA" },
	{ city: "Doraville", state: "GA" },
	{ city: "Scottdale", state: "GA" },
	{ city: "Lovejoy", state: "GA" },
	{ city: "Garden City", state: "GA" },
	{ city: "Cordele", state: "GA" },
	{ city: "Fort Oglethorpe", state: "GA" },
	{ city: "Cedartown", state: "GA" },
	{ city: "Cairo", state: "GA" },
	{ city: "Jesup", state: "GA" },
	{ city: "Thomaston", state: "GA" },
	{ city: "Flowery Branch", state: "GA" },
	{ city: "Fair Oaks", state: "GA" },
	{ city: "Cusseta", state: "GA" },
	{ city: "Locust Grove", state: "GA" },
	{ city: "Fort Stewart", state: "GA" },
	{ city: "Skidaway Island", state: "GA" },
	{ city: "Panthersville", state: "GA" },
	{ city: "Country Club Estates", state: "GA" },
	{ city: "Toccoa", state: "GA" },
	{ city: "Bemiss", state: "GA" },
	{ city: "Fitzgerald", state: "GA" },
	{ city: "Fort Valley", state: "GA" },
	{ city: "Druid Hills", state: "GA" },
	{ city: "Irondale", state: "GA" },
	{ city: "Hampton", state: "GA" },
	{ city: "Centerville", state: "GA" },
	{ city: "Dock Junction", state: "GA" },
	{ city: "Austell", state: "GA" },
	{ city: "Gresham Park", state: "GA" },
	{ city: "Auburn", state: "GA" },
	{ city: "Tyrone", state: "GA" },
	{ city: "Swainsboro", state: "GA" },
	{ city: "Commerce", state: "GA" },
	{ city: "Cumming", state: "GA" },
	{ city: "Bremen", state: "GA" },
	{ city: "Dahlonega", state: "GA" },
	{ city: "LaFayette", state: "GA" },
	{ city: "Dacula", state: "GA" },
	{ city: "Thomson", state: "GA" },
	{ city: "Whitemarsh Island", state: "GA" },
	{ city: "Stone Mountain", state: "GA" },
	{ city: "Hapeville", state: "GA" },
	{ city: "Morrow", state: "GA" },
	{ city: "Fairfield Plantation", state: "GA" },
	{ city: "Eatonton", state: "GA" },
	{ city: "McRae-Helena", state: "GA" },
	{ city: "Barnesville", state: "GA" },
	{ city: "Conley", state: "GA" },
	{ city: "Sandersville", state: "GA" },
	{ city: "Waynesboro", state: "GA" },
	{ city: "Fairview", state: "GA" },
	{ city: "Byron", state: "GA" },
	{ city: "Eastman", state: "GA" },
	{ city: "Sylvester", state: "GA" },
	{ city: "Adel", state: "GA" },
	{ city: "Jackson", state: "GA" },
	{ city: "Temple", state: "GA" },
	{ city: "Blakely", state: "GA" },
	{ city: "Glennville", state: "GA" },
	{ city: "Camilla", state: "GA" },
	{ city: "Oakwood", state: "GA" },
	{ city: "Senoia", state: "GA" },
	{ city: "Rockmart", state: "GA" },
	{ city: "Social Circle", state: "GA" },
	{ city: "Baxley", state: "GA" },
	{ city: "Hiram", state: "GA" },
	{ city: "Adairsville", state: "GA" },
	{ city: "Nashville", state: "GA" },
	{ city: "Cochran", state: "GA" },
	{ city: "Chatsworth", state: "GA" },
	{ city: "Buckhead", state: "GA" },
	{ city: "Lakeview", state: "GA" },
	{ city: "Palmetto", state: "GA" },
	{ city: "Cornelia", state: "GA" },
	{ city: "Elberton", state: "GA" },
	{ city: "Montgomery", state: "GA" },
	{ city: "Madison", state: "GA" },
	{ city: "Grayson", state: "GA" },
	{ city: "Lindale", state: "GA" },
	{ city: "Forsyth", state: "GA" },
	{ city: "Folkston", state: "GA" },
	{ city: "Hartwell", state: "GA" },
	{ city: "Jonesboro", state: "GA" },
	{ city: "Summerville", state: "GA" },
	{ city: "Dawson", state: "GA" },
	{ city: "Euharlee", state: "GA" },
	{ city: "Ashburn", state: "GA" },
	{ city: "Lyons", state: "GA" },
	{ city: "Jasper", state: "GA" },
	{ city: "Chattanooga Valley", state: "GA" },
	{ city: "Hazlehurst", state: "GA" },
	{ city: "Quitman", state: "GA" },
	{ city: "Hawkinsville", state: "GA" },
	{ city: "Rossville", state: "GA" },
	{ city: "Metter", state: "GA" },
	{ city: "Heron Bay", state: "GA" },
	{ city: "Dawsonville", state: "GA" },
	{ city: "Hephzibah", state: "GA" },
	{ city: "Baldwin", state: "GA" },
	{ city: "Washington", state: "GA" },
	{ city: "Walthourville", state: "GA" },
	{ city: "Harlem", state: "GA" },
	{ city: "Bonanza", state: "GA" },
	{ city: "Hannahs Mill", state: "GA" },
	{ city: "Manchester", state: "GA" },
	{ city: "Greensboro", state: "GA" },
	{ city: "Blackshear", state: "GA" },
	{ city: "Cleveland", state: "GA" },
	{ city: "West Point", state: "GA" },
	{ city: "Avondale Estates", state: "GA" },
	{ city: "Pelham", state: "GA" },
	{ city: "Wrightsville", state: "GA" },
	{ city: "Leesburg", state: "GA" },
	{ city: "Alma", state: "GA" },
	{ city: "Ringgold", state: "GA" },
	{ city: "Gray", state: "GA" },
	{ city: "Hahira", state: "GA" },
	{ city: "Ocilla", state: "GA" },
	{ city: "Putney", state: "GA" },
	{ city: "Chattahoochee Hills", state: "GA" },
	{ city: "Hogansville", state: "GA" },
	{ city: "Tallapoosa", state: "GA" },
	{ city: "Hardwick", state: "GA" },
	{ city: "Alamo", state: "GA" },
	{ city: "Grantville", state: "GA" },
	{ city: "Tybee Island", state: "GA" },
	{ city: "Cuthbert", state: "GA" },
	{ city: "Hoschton", state: "GA" },
	{ city: "Unadilla", state: "GA" },
	{ city: "Nicholls", state: "GA" },
	{ city: "Montezuma", state: "GA" },
	{ city: "Henderson", state: "GA" },
	{ city: "Watkinsville", state: "GA" },
	{ city: "Chickamauga", state: "GA" },
	{ city: "Millen", state: "GA" },
	{ city: "Vienna", state: "GA" },
	{ city: "Bloomingdale", state: "GA" },
	{ city: "Lakeland", state: "GA" },
	{ city: "Lake City", state: "GA" },
	{ city: "Soperton", state: "GA" },
	{ city: "East Dublin", state: "GA" },
	{ city: "Lula", state: "GA" },
	{ city: "Statham", state: "GA" },
	{ city: "Donalsonville", state: "GA" },
	{ city: "Reed Creek", state: "GA" },
	{ city: "Springfield", state: "GA" },
	{ city: "Abbeville", state: "GA" },
	{ city: "Lakeview Estates", state: "GA" },
	{ city: "Louisville", state: "GA" },
	{ city: "Kennesaw State University", state: "GA" },
	{ city: "Sylvania", state: "GA" },
	{ city: "Lavonia", state: "GA" },
	{ city: "Lithonia", state: "GA" },
	{ city: "Monticello", state: "GA" },
	{ city: "Bowdon", state: "GA" },
	{ city: "Experiment", state: "GA" },
	{ city: "Claxton", state: "GA" },
	{ city: "Thunderbolt", state: "GA" },
	{ city: "Pembroke", state: "GA" },
	{ city: "Reidsville", state: "GA" },
	{ city: "Raoul", state: "GA" },
	{ city: "Ball Ground", state: "GA" },
	{ city: "Berkeley Lake", state: "GA" },
	{ city: "Sparks", state: "GA" },
	{ city: "Midway", state: "GA" },
	{ city: "Kings Bay Base", state: "GA" },
	{ city: "Varnell", state: "GA" },
	{ city: "Guyton", state: "GA" },
	{ city: "Trion", state: "GA" },
	{ city: "Mount Zion", state: "GA" },
	{ city: "Royston", state: "GA" },
	{ city: "Demorest", state: "GA" },
	{ city: "Clayton", state: "GA" },
	{ city: "Mount Vernon", state: "GA" },
	{ city: "Unionville", state: "GA" },
	{ city: "Gumlog", state: "GA" },
	{ city: "Davisboro", state: "GA" },
	{ city: "Trenton", state: "GA" },
	{ city: "Winterville", state: "GA" },
	{ city: "Deenwood", state: "GA" },
	{ city: "Ludowici", state: "GA" },
	{ city: "Isle of Hope", state: "GA" },
	{ city: "Colquitt", state: "GA" },
	{ city: "Gordon", state: "GA" },
	{ city: "Nicholson", state: "GA" },
	{ city: "Wrens", state: "GA" },
	{ city: "Butler", state: "GA" },
	{ city: "Wadley", state: "GA" },
	{ city: "Homerville", state: "GA" },
	{ city: "Oxford", state: "GA" },
	{ city: "Indian Springs", state: "GA" },
	{ city: "Buena Vista", state: "GA" },
	{ city: "Hamilton", state: "GA" },
	{ city: "Sparta", state: "GA" },
	{ city: "Sterling", state: "GA" },
	{ city: "Clarkesville", state: "GA" },
	{ city: "Lincolnton", state: "GA" },
	{ city: "Twin City", state: "GA" },
	{ city: "Warrenton", state: "GA" },
	{ city: "Morgan", state: "GA" },
	{ city: "Cataula", state: "GA" },
	{ city: "Arcade", state: "GA" },
	{ city: "Pearson", state: "GA" },
	{ city: "Comer", state: "GA" },
	{ city: "Lookout Mountain", state: "GA" },
	{ city: "Aragon", state: "GA" },
	{ city: "Ellijay", state: "GA" },
	{ city: "Berry College", state: "GA" },
	{ city: "Pendergrass", state: "GA" },
	{ city: "Chester", state: "GA" },
	{ city: "Shannon", state: "GA" },
	{ city: "Maysville", state: "GA" },
	{ city: "Union Point", state: "GA" },
	{ city: "Homer", state: "GA" },
	{ city: "McCaysville", state: "GA" },
	{ city: "Zebulon", state: "GA" },
	{ city: "Walnut Grove", state: "GA" },
	{ city: "Remerton", state: "GA" },
	{ city: "Porterdale", state: "GA" },
	{ city: "Emerson", state: "GA" },
	{ city: "Eton", state: "GA" },
	{ city: "Brooklet", state: "GA" },
	{ city: "Tennille", state: "GA" },
	{ city: "Moody AFB", state: "GA" },
	{ city: "Ellaville", state: "GA" },
	{ city: "Pine Mountain", state: "GA" },
	{ city: "Roberta", state: "GA" },
	{ city: "Willacoochee", state: "GA" },
	{ city: "Hagan", state: "GA" },
	{ city: "Rock Spring", state: "GA" },
	{ city: "Russell", state: "GA" },
	{ city: "Nahunta", state: "GA" },
	{ city: "Mount Airy", state: "GA" },
	{ city: "Blue Ridge", state: "GA" },
	{ city: "Resaca", state: "GA" },
	{ city: "Alto", state: "GA" },
	{ city: "Robins AFB", state: "GA" },
	{ city: "Omega", state: "GA" },
	{ city: "Richland", state: "GA" },
	{ city: "Bogart", state: "GA" },
	{ city: "Dutch Island", state: "GA" },
	{ city: "Darien", state: "GA" },
	{ city: "Enigma", state: "GA" },
	{ city: "Buchanan", state: "GA" },
	{ city: "Fairplay", state: "GA" },
	{ city: "Edison", state: "GA" },
	{ city: "Sycamore", state: "GA" },
	{ city: "Fairmount", state: "GA" },
	{ city: "East Griffin", state: "GA" },
	{ city: "Flemington", state: "GA" },
	{ city: "Talahi Island", state: "GA" },
	{ city: "Tunnel Hill", state: "GA" },
	{ city: "West Brow", state: "GA" },
	{ city: "Flovilla", state: "GA" },
	{ city: "Tignall", state: "GA" },
	{ city: "Homeland", state: "GA" },
	{ city: "Arlington", state: "GA" },
	{ city: "Hiawassee", state: "GA" },
	{ city: "Lumber City", state: "GA" },
	{ city: "Norman Park", state: "GA" },
	{ city: "Boston", state: "GA" },
	{ city: "Sardis", state: "GA" },
	{ city: "Clermont", state: "GA" },
	{ city: "Greenville", state: "GA" },
	{ city: "Oglethorpe", state: "GA" },
	{ city: "Broxton", state: "GA" },
	{ city: "Rutledge", state: "GA" },
	{ city: "Young Harris", state: "GA" },
	{ city: "Dasher", state: "GA" },
	{ city: "Ivey", state: "GA" },
	{ city: "Meigs", state: "GA" },
	{ city: "Shellman", state: "GA" },
	{ city: "East Newnan", state: "GA" },
	{ city: "Canon", state: "GA" },
	{ city: "Gibson", state: "GA" },
	{ city: "Doerun", state: "GA" },
	{ city: "Bowman", state: "GA" },
	{ city: "Pine Lake", state: "GA" },
	{ city: "Lenox", state: "GA" },
	{ city: "Woodbury", state: "GA" },
	{ city: "Hoboken", state: "GA" },
	{ city: "Funston", state: "GA" },
	{ city: "White", state: "GA" },
	{ city: "Milstead", state: "GA" },
	{ city: "Reynolds", state: "GA" },
	{ city: "Luthersville", state: "GA" },
	{ city: "Plains", state: "GA" },
	{ city: "Waco", state: "GA" },
	{ city: "Lumpkin", state: "GA" },
	{ city: "Coolidge", state: "GA" },
	{ city: "Kingston", state: "GA" },
	{ city: "Williamson", state: "GA" },
	{ city: "Berlin", state: "GA" },
	{ city: "Poulan", state: "GA" },
	{ city: "Waleska", state: "GA" },
	{ city: "Lincoln Park", state: "GA" },
	{ city: "Glenwood", state: "GA" },
	{ city: "Blairsville", state: "GA" },
	{ city: "Cohutta", state: "GA" },
	{ city: "Patterson", state: "GA" },
	{ city: "Talbotton", state: "GA" },
	{ city: "North High Shoals", state: "GA" },
	{ city: "Brooks", state: "GA" },
	{ city: "McIntyre", state: "GA" },
	{ city: "Attapulgus", state: "GA" },
	{ city: "Pavo", state: "GA" },
	{ city: "Stillmore", state: "GA" },
	{ city: "Sunset Village", state: "GA" },
	{ city: "Appling", state: "GA" },
	{ city: "Waverly Hall", state: "GA" },
	{ city: "Screven", state: "GA" },
	{ city: "Crooked Creek", state: "GA" },
	{ city: "Blythe", state: "GA" },
	{ city: "Ochlocknee", state: "GA" },
	{ city: "Graham", state: "GA" },
	{ city: "Molena", state: "GA" },
	{ city: "Dudley", state: "GA" },
	{ city: "Arabi", state: "GA" },
	{ city: "Dearing", state: "GA" },
	{ city: "Whitesburg", state: "GA" },
	{ city: "Toomsboro", state: "GA" },
	{ city: "Crescent", state: "GA" },
	{ city: "Milan", state: "GA" },
	{ city: "Gumbranch", state: "GA" },
	{ city: "Newborn", state: "GA" },
	{ city: "Cadwell", state: "GA" },
	{ city: "Ideal", state: "GA" },
	{ city: "Portal", state: "GA" },
	{ city: "Whigham", state: "GA" },
	{ city: "Milner", state: "GA" },
	{ city: "Adrian", state: "GA" },
	{ city: "Ty Ty", state: "GA" },
	{ city: "Pineview", state: "GA" },
	{ city: "Moreland", state: "GA" },
	{ city: "Antioch", state: "GA" },
	{ city: "Colbert", state: "GA" },
	{ city: "Sky Valley", state: "GA" },
	{ city: "Collins", state: "GA" },
	{ city: "Bethlehem", state: "GA" },
	{ city: "Alapaha", state: "GA" },
	{ city: "Crawfordville", state: "GA" },
	{ city: "Morven", state: "GA" },
	{ city: "Waynesville", state: "GA" },
	{ city: "Byromville", state: "GA" },
	{ city: "Warwick", state: "GA" },
	{ city: "Ailey", state: "GA" },
	{ city: "Epworth", state: "GA" },
	{ city: "Rhine", state: "GA" },
	{ city: "Irwinton", state: "GA" },
	{ city: "Yonah", state: "GA" },
	{ city: "Eulonia", state: "GA" },
	{ city: "Shiloh", state: "GA" },
	{ city: "Cobbtown", state: "GA" },
	{ city: "Wildwood", state: "GA" },
	{ city: "Ephesus", state: "GA" },
	{ city: "Tiger", state: "GA" },
	{ city: "Lyerly", state: "GA" },
	{ city: "Warm Springs", state: "GA" },
	{ city: "East Ellijay", state: "GA" },
	{ city: "Oak Park", state: "GA" },
	{ city: "Leslie", state: "GA" },
	{ city: "Allenhurst", state: "GA" },
	{ city: "Centralhatchee", state: "GA" },
	{ city: "Pitts", state: "GA" },
	{ city: "Helen", state: "GA" },
	{ city: "Argyle", state: "GA" },
	{ city: "Newton", state: "GA" },
	{ city: "Between", state: "GA" },
	{ city: "Empire", state: "GA" },
	{ city: "Arnoldsville", state: "GA" },
	{ city: "Sautee-Nacoochee", state: "GA" },
	{ city: "Maxeys", state: "GA" },
	{ city: "New England", state: "GA" },
	{ city: "Menlo", state: "GA" },
	{ city: "Bowersville", state: "GA" },
	{ city: "Phillipsburg", state: "GA" },
	{ city: "Waresboro", state: "GA" },
	{ city: "Fargo", state: "GA" },
	{ city: "Bishop", state: "GA" },
	{ city: "Hiltonia", state: "GA" },
	{ city: "Talmo", state: "GA" },
	{ city: "Concord", state: "GA" },
	{ city: "Pinehurst", state: "GA" },
	{ city: "Camak", state: "GA" },
	{ city: "Roopville", state: "GA" },
	{ city: "Midville", state: "GA" },
	{ city: "Sale City", state: "GA" },
	{ city: "Lexington", state: "GA" },
	{ city: "Junction City", state: "GA" },
	{ city: "Newington", state: "GA" },
	{ city: "Ila", state: "GA" },
	{ city: "Sumner", state: "GA" },
	{ city: "Carlton", state: "GA" },
	{ city: "Keysville", state: "GA" },
	{ city: "Montrose", state: "GA" },
	{ city: "Siloam", state: "GA" },
	{ city: "Uvalda", state: "GA" },
	{ city: "Hickox", state: "GA" },
	{ city: "Piney Grove", state: "GA" },
	{ city: "Axson", state: "GA" },
	{ city: "Sharpsburg", state: "GA" },
	{ city: "Turin", state: "GA" },
	{ city: "Stapleton", state: "GA" },
	{ city: "Salem", state: "GA" },
	{ city: "Leary", state: "GA" },
	{ city: "Bostwick", state: "GA" },
	{ city: "Daisy", state: "GA" },
	{ city: "Mineral Bluff", state: "GA" },
	{ city: "Chauncey", state: "GA" },
	{ city: "Braswell", state: "GA" },
	{ city: "Good Hope", state: "GA" },
	{ city: "Martin", state: "GA" },
	{ city: "Yatesville", state: "GA" },
	{ city: "Taylorsville", state: "GA" },
	{ city: "Odum", state: "GA" },
	{ city: "Higgston", state: "GA" },
	{ city: "Hortense", state: "GA" },
	{ city: "Cecil", state: "GA" },
	{ city: "Harrison", state: "GA" },
	{ city: "Sasser", state: "GA" },
	{ city: "Mansfield", state: "GA" },
	{ city: "Bolingbroke", state: "GA" },
	{ city: "Damascus", state: "GA" },
	{ city: "Bronwood", state: "GA" },
	{ city: "Iron City", state: "GA" },
	{ city: "Smithville", state: "GA" },
	{ city: "Barwick", state: "GA" },
	{ city: "Rebecca", state: "GA" },
	{ city: "Scotland", state: "GA" },
	{ city: "Plainville", state: "GA" },
	{ city: "Jenkinsburg", state: "GA" },
	{ city: "Oconee", state: "GA" },
	{ city: "Carl", state: "GA" },
	{ city: "Woodland", state: "GA" },
	{ city: "Ellenton", state: "GA" },
	{ city: "Andersonville", state: "GA" },
	{ city: "Bluffton", state: "GA" },
	{ city: "Shady Dale", state: "GA" },
	{ city: "Morganton", state: "GA" },
	{ city: "Oliver", state: "GA" },
	{ city: "Gillsville", state: "GA" },
	{ city: "Avalon", state: "GA" },
	{ city: "Dillard", state: "GA" },
	{ city: "Clyattville", state: "GA" },
	{ city: "Smarr", state: "GA" },
	{ city: "Surrency", state: "GA" },
	{ city: "Norwood", state: "GA" },
	{ city: "Dewy Rose", state: "GA" },
	{ city: "Satilla", state: "GA" },
	{ city: "Woodville", state: "GA" },
	{ city: "Summertown", state: "GA" },
	{ city: "Waverly", state: "GA" },
	{ city: "Denton", state: "GA" },
	{ city: "Culloden", state: "GA" },
	{ city: "Avera", state: "GA" },
	{ city: "Rocky Ford", state: "GA" },
	{ city: "Juliette", state: "GA" },
	{ city: "Stockton", state: "GA" },
	{ city: "Rentz", state: "GA" },
	{ city: "Meansville", state: "GA" },
	{ city: "Box Springs", state: "GA" },
	{ city: "Tallulah Falls", state: "GA" },
	{ city: "Haralson", state: "GA" },
	{ city: "Jakin", state: "GA" },
	{ city: "Woolsey", state: "GA" },
	{ city: "Pulaski", state: "GA" },
	{ city: "Mendes", state: "GA" },
	{ city: "Alston", state: "GA" },
	{ city: "Ambrose", state: "GA" },
	{ city: "Wenona", state: "GA" },
	{ city: "Bartow", state: "GA" },
	{ city: "Hilltop", state: "GA" },
	{ city: "Register", state: "GA" },
	{ city: "Hull", state: "GA" },
	{ city: "Garfield", state: "GA" },
	{ city: "Allentown", state: "GA" },
	{ city: "Girard", state: "GA" },
	{ city: "Kite", state: "GA" },
	{ city: "Bellville", state: "GA" },
	{ city: "Brinson", state: "GA" },
	{ city: "Orchard Hill", state: "GA" },
	{ city: "Vidette", state: "GA" },
	{ city: "Climax", state: "GA" },
	{ city: "Danville", state: "GA" },
	{ city: "Matthews", state: "GA" },
	{ city: "Buckhead", state: "GA" },
	{ city: "Lone Oak", state: "GA" },
	{ city: "White Plains", state: "GA" },
	{ city: "Dixie", state: "GA" },
	{ city: "Nunez", state: "GA" },
	{ city: "Rayle", state: "GA" },
	{ city: "Jersey", state: "GA" },
	{ city: "Warthen", state: "GA" },
	{ city: "Faceville", state: "GA" },
	{ city: "Rockingham", state: "GA" },
	{ city: "Santa Claus", state: "GA" },
	{ city: "Lilly", state: "GA" },
	{ city: "Du Pont", state: "GA" },
	{ city: "Geneva", state: "GA" },
	{ city: "Deepstep", state: "GA" },
	{ city: "Godfrey", state: "GA" },
	{ city: "Mitchell", state: "GA" },
	{ city: "Musella", state: "GA" },
	{ city: "Gay", state: "GA" },
	{ city: "Bristol", state: "GA" },
	{ city: "Tarrytown", state: "GA" },
	{ city: "Manor", state: "GA" },
	{ city: "Vernonburg", state: "GA" },
	{ city: "Gough", state: "GA" },
	{ city: "Riddleville", state: "GA" },
	{ city: "Knoxville", state: "GA" },
	{ city: "Talking Rock", state: "GA" },
	{ city: "Canoochee", state: "GA" },
	{ city: "Jacksonville", state: "GA" },
	{ city: "Parrott", state: "GA" },
	{ city: "Perkins", state: "GA" },
	{ city: "Calvary", state: "GA" },
	{ city: "Manassas", state: "GA" },
	{ city: "De Soto", state: "GA" },
	{ city: "Millwood", state: "GA" },
	{ city: "Ranger", state: "GA" },
	{ city: "Everett", state: "GA" },
	{ city: "Dixie Union", state: "GA" },
	{ city: "Sharon", state: "GA" },
	{ city: "Ohoopee", state: "GA" },
	{ city: "Dooling", state: "GA" },
	{ city: "Rest Haven", state: "GA" },
	{ city: "Naylor", state: "GA" },
	{ city: "Gardi", state: "GA" },
	{ city: "Seville", state: "GA" },
	{ city: "Boykin", state: "GA" },
	{ city: "The Rock", state: "GA" },
	{ city: "Norristown", state: "GA" },
	{ city: "Eagle Grove", state: "GA" },
	{ city: "Cedar Springs", state: "GA" },
	{ city: "Cogdell", state: "GA" },
	{ city: "Edge Hill", state: "GA" },
	{ city: "Mershon", state: "GA" },
	{ city: "Glenn", state: "GA" },
	{ city: "Tazewell", state: "GA" },
	{ city: "Coleman", state: "GA" },
	{ city: "Cherry Log", state: "GA" },
	{ city: "Aldora", state: "GA" },
	{ city: "Tate City", state: "GA" },
	{ city: "Howard", state: "GA" },
]

export const NorthCarolinaLocationOptions = NorthCarolinaLocations.map((l) => {
	return { label: `${l.city}, ${l.state}`, value: `${l.city}, ${l.state}` }
})

export const SouthCarolinaLocationOptions = SouthCarolinaLocations.map((l) => {
	return { label: `${l.city}, ${l.state}`, value: `${l.city}, ${l.state}` }
})

export const FloridaLocationOptions = FloridaLocations.map((l) => {
	return { label: `${l.city}, ${l.state}`, value: `${l.city}, ${l.state}` }
})

export const GeorgiaLocationOptions = GeorgiaLocations.map((l) => {
	return { label: `${l.city}, ${l.state}`, value: `${l.city}, ${l.state}` }
})
