import React, { useEffect, useState, useContext } from "react"
import {
	Box,
	Button,
	Center,
	Heading,
	HStack,
	IconButton,
	Spinner,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react"
import { DeleteIcon } from "@chakra-ui/icons"
import { db } from "../../firebase"
import { useNavigate } from "react-router-dom"
import {
	collection,
	increment,
	getDocs,
	deleteDoc,
	query,
	updateDoc,
	where,
} from "firebase/firestore"
import { ServicesMobileCard } from "../../Components/ServicesMobileCard"
import { EmptyState } from "../../Components/EmptyState"
import { UserContext } from "../../Contexts/UserContext"
import { VendorPayWallModal } from "../../Components/VendorPayWall"
import {
	checkVendorSubscriptionServiceLimit,
	formatPhoneNumber,
} from "../../Utilities.js"
import { PaywallBanner } from "../../Components/PaywallBanner"

export const ServicesList = () => {
	const { userPlus } = useContext(UserContext)
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()
	useEffect(() => {
		if (userPlus === "pending") {
			setIsLoading(true)
			return
		} else {
			setIsLoading(false)
		}
	}, [userPlus])

	const [services, setServices] = useState([])
	const [paywallTriggered, setPaywallTriggered] = useState(false)

	const fetchServices = async () => {
		setIsLoading(true)
		try {
			const q = query(
				collection(db, "services"),
				where("userId", "==", userPlus.id)
			)
			const doc = await getDocs(q)

			const servicesData = doc.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}))

			setServices(servicesData)
		} catch (error) {
			console.error("Error fetching services:", error)
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}
		fetchServices()
	}, [userPlus])

	const editService = (service) => () => {
		navigate(`/services/new`, { state: { service } })
	}

	const removeService = async (service) => {
		setIsLoading(true)
		try {
			await updateDoc(userPlus.userDocRef, {
				numberOfServices: increment(-1),
			})

			const q1 = query(
				collection(db, "vendorServiceMap"),
				where("serviceId", "==", service.id)
			)
			const doc1 = await getDocs(q1)

			const docRef1 = doc1.docs[0].ref
			await deleteDoc(docRef1)
			const q2 = query(
				collection(db, "services"),
				where("id", "==", service.id)
			)
			const doc2 = await getDocs(q2)
			const docRef2 = doc2.docs[0].ref

			await deleteDoc(docRef2)
			fetchServices()
		} catch (error) {
			console.error("Error updating document: ", error)
		}
		setIsLoading(false)
	}
	const addNewService = () => {
		checkVendorSubscriptionServiceLimit(userPlus, 1)
			? navigate("/services/new")
			: setPaywallTriggered(true)
	}

	if (isLoading) {
		return (
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					My Services
				</Heading>
				<Text display={["flex", "flex", "none", "none"]}>
					Add services and invite agents and teams to begin getting job leads.{" "}
				</Text>
				<Center>
					<Spinner m="auto" mt="40px" />
				</Center>
			</Box>
		)
	}

	return (
		<>
			{userPlus.planName === null && (
				<PaywallBanner location="vendorServiceList" />
			)}
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					My Services
				</Heading>
				<Text display={["flex", "flex", "none", "none"]}>
					Add services and invite agents and teams to begin getting job leads.{" "}
				</Text>
				<Button
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					w="100%"
					bgColor="red.500"
					color="white"
					onClick={() => addNewService()}
				>
					New Service
				</Button>
				<HStack
					display={["none", "none", "flex", "flex"]}
					justifyContent="space-between"
					mt="10px"
				>
					<Text>
						Add services and invite agents and teams to begin getting job leads.{" "}
					</Text>
					<Button onClick={() => addNewService()}>New Service</Button>
				</HStack>
				<Box
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					flexDirection="column"
				>
					{services.map((service, index) => {
						return (
							<ServicesMobileCard
								service={service}
								index={index}
								removeService={removeService}
								editService={editService}
								key={index}
							/>
						)
					})}
				</Box>
				<Box
					display={["none", "none", "flex", "flex"]}
					flexDirection="column"
					mt="20px"
				>
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Service Type</Th>
								<Th>Contact</Th>
								<Th>Job Responses</Th>
								<Th />
							</Tr>
						</Thead>
						<Tbody>
							{services.map((service, index) => {
								return (
									<Tr key={service.id}>
										<Td>{service.serviceType}</Td>
										<Td>
											<Box>
												{" "}
												<Text>
													{service.contactEmail && service.contactEmail}
												</Text>
												<Text>
													{service.phoneNumber &&
														formatPhoneNumber(service.phoneNumber)}
												</Text>
											</Box>
										</Td>
										<Td>
											{service.jobResponseCount ? service.jobResponseCount : 0}
										</Td>
										<Td w="250px">
											<Button onClick={editService(service)}>Edit</Button>
											<IconButton
												icon={<DeleteIcon color="red.500" />}
												bgColor="background"
												ml="15px"
												onClick={() => removeService(service)}
											/>
										</Td>
									</Tr>
								)
							})}
						</Tbody>
					</Table>
					{services.length === 0 && (
						<EmptyState helperText="You have not added any services yet!" />
					)}
				</Box>
			</Box>
			<VendorPayWallModal
				isOpen={paywallTriggered}
				setPaywallTriggered={setPaywallTriggered}
			/>
		</>
	)
}
