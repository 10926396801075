import React, { useEffect, useCallback, useState, useContext } from "react"
import DatePicker from "react-datepicker"
import axios from "axios"
import { useParams, NavLink, useNavigate, useLocation } from "react-router-dom"
import {
	Box,
	Heading,
	HStack,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Tag,
	TagLabel,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react"
import { UpDownIcon, ArrowBackIcon } from "@chakra-ui/icons"
import {
	collection,
	query,
	where,
	getDocs,
	updateDoc,
} from "firebase/firestore"
import { db, logUserEvent } from "../../firebase"
import { JobRequestResponseMobileCard } from "../../Components/JobRequestResponseMobileCard"
import { JobRequestResponseAwaitingResponseMobileCard } from "../../Components/JobRequestResponseAwaitingResponseMobileCard"
import { JobDetailsInformationBox } from "../../Components/JobDetailsInformationBox"
import { dateToFormat, formatPhoneNumber } from "../../Utilities"
import { UserContext } from "../../Contexts/UserContext"
import { analytic_events } from "../../analytics"
import config from "../../config.js"
import "../../date-picker.css"

export const ClientAgentJobRequestResponsesListView = () => {
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const [linkText, setLinkText] = useState("")
	const [isOpen, setIsOpen] = useState(false)
	const [jobRequest, setJobRequest] = useState(null)
	const [awaitingVendors, setAwaitingVendors] = useState([])
	const [isScheduling, setIsScheduling] = useState(false)
	const [jobRequestResponses, setJobRequestResponses] = useState([])
	const [showDatePicker, setShowDatePicker] = useState(false)

	const handleOpen = () => {
		setIsOpen(!isOpen)
	}

	const fetchData = useCallback(async () => {
		if (userPlus === "pending" || isScheduling || userPlus === null) return

		try {
			const jobRequestQ = query(
				collection(db, "jobRequests"),
				where("id", "==", id)
			)
			const jobRequestSnap = await getDocs(jobRequestQ)

			if (jobRequestSnap.empty) {
				console.error("Job request not found")
				return
			}

			const jobRequestData = jobRequestSnap.docs[0].data()
			const vendorIds = jobRequestData.vendorIds || []

			const vendorDataSnap = await getDocs(
				query(collection(db, "users"), where("id", "in", vendorIds))
			)
			const vendorData = vendorDataSnap.docs.map((doc) => doc.data())

			setJobRequest(jobRequestData)

			const jobRequestResponsesQ = query(
				collection(db, "jobRequestResponses"),
				where("requestId", "==", id)
			)
			const jobRequestResponsesSnap = await getDocs(jobRequestResponsesQ)
			const jobRequestResponsesData = jobRequestResponsesSnap.docs.map(
				(doc) => ({
					id: doc.id,
					...doc.data(),
				})
			)

			setJobRequestResponses(jobRequestResponsesData)
			if (jobRequestResponsesData.length > 0) {
				const awaitingVendors = vendorData.filter(
					(vendor) =>
						!jobRequestResponsesData.some(
							(jobRequest) => jobRequest.userId === vendor.id
						)
				)

				setAwaitingVendors(awaitingVendors)
			} else {
				setAwaitingVendors(vendorData)
			}
		} catch (error) {
			console.error("Error fetching data:", error)
		}
	}, [id, isScheduling, userPlus])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	useEffect(() => {
		const previousUrl = location.state?.from || ""
		console.log("previousUrl", previousUrl, location)
		if (previousUrl.includes("/insights")) {
			setLinkText("Return to Client Insights")
		} else {
			setLinkText("All Job Requests")
		}
	}, [location])

	const handleScheduling = async (e, jobRequestResponse) => {
		if (isScheduling) return

		setIsScheduling(true)
		const q = query(collection(db, "jobRequests"), where("id", "==", id))
		const docSnap = await getDocs(q)
		if (docSnap.docs[0].data().selectedResponseId) {
			await updateDoc(docSnap.docs[0].ref, {
				selectedResponseId: null,
				scheduleDate: null,
			})

			await logUserEvent(
				analytic_events.AGENT_CLIENT_UNSCHEDULED,
				userPlus.id,
				{
					unscheduledResponsesId: docSnap.docs[0].data().selectedResponseId,
					userId: userPlus.id,
				}
			)
		} else {
			await updateDoc(docSnap.docs[0].ref, {
				selectedResponseId: jobRequestResponse.id,
				scheduleDate: e,
			})
			await logUserEvent(analytic_events.AGENT_CLIENT_SCHEDULED, userPlus.id, {
				scheduledResponsesId: jobRequestResponse.id,
				userId: userPlus.id,
			})
			sendSchedulingEmail(jobRequestResponse, e)
		}
		setShowDatePicker(false)
		setIsOpen(false)
		setIsScheduling(false)
	}

	const sendSchedulingEmail = async (jobRequestResponse, e) => {
		const userEmail = userPlus.email
		const userName = userPlus.name
		const scheduleDate = e
		try {
			await axios.post(`${config.baseURL}/api/job-request-scheduling`, {
				jobRequestResponse,
				jobRequest,
				scheduleDate,
				userEmail,
				userName,
			})
		} catch (error) {
			console.error("Error sending email:", error)
		}
	}
	return (
		<>
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					Job Request Responses
				</Heading>
				<HStack my="15px" gap={0}>
					<IconButton
						aria-label="Return"
						size="md"
						bgColor="background"
						icon={<ArrowBackIcon />}
						onClick={() => navigate(-1)}
						_hover={{ color: "red.500" }}
					/>
					<Link as={NavLink} variant="secondary" onClick={() => navigate(-1)}>
						{linkText}
					</Link>
				</HStack>
				<JobDetailsInformationBox jobRequest={jobRequest} />
				<Box
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					flexDirection="column"
				>
					{jobRequestResponses.length > 0 &&
						jobRequestResponses.map((jobRequestResponse, index) => {
							return (
								<JobRequestResponseMobileCard
									jobRequestResponse={jobRequestResponse}
									jobRequest={jobRequest}
									handleScheduling={handleScheduling}
									selectedResponseId={jobRequest?.selectedResponseId}
									showDatePicker={showDatePicker}
									setShowDatePicker={setShowDatePicker}
								/>
							)
						})}
					{awaitingVendors.length > 0 &&
						awaitingVendors.map((vendor, index) => {
							return (
								<JobRequestResponseAwaitingResponseMobileCard
									vendor={vendor}
									key={index}
								/>
							)
						})}
				</Box>
				<Box display={["none", "none", "flex", "flex"]} mt="30px">
					<Table variant="simple" mt="40px">
						<Thead>
							<Tr>
								<Th>Vendor</Th>
								<Th>Job Estimate</Th>
								<Th>Alternative Dates & Times</Th>
								<Th>Vendor Status</Th>
							</Tr>
						</Thead>
						<Tbody>
							{jobRequestResponses.length > 0 &&
								jobRequestResponses.map((jobRequestResponse) => {
									return (
										<Tr key={jobRequestResponse.id}>
											<Td>{jobRequestResponse.vendorName}</Td>
											<Td>
												{jobRequestResponse.minQuote &&
													jobRequestResponse.maxQuote && (
														<Text>
															${jobRequestResponse.minQuote}-$
															{jobRequestResponse.maxQuote}
														</Text>
													)}
												{jobRequestResponse.serviceCharge && (
													<Box display="flex">
														<Text>${jobRequestResponse.serviceChargeCost}</Text>
														<Tag
															ml="10px"
															width="125px"
															borderRadius="full"
															variant="solid"
															bgColor="blue.300"
															color="blue.800"
														>
															<TagLabel>Service Charge</TagLabel>
														</Tag>
													</Box>
												)}
											</Td>
											<Td>
												{jobRequestResponse.servicePreferredDates.map(
													(entry, index) => (
														<HStack gap={5} mb="5px">
															<Text key={index}>
																{dateToFormat(entry.date)}
															</Text>
														</HStack>
													)
												)}
											</Td>
											<Td>
												{jobRequestResponse.available &&
													!jobRequest?.selectedResponseId && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="green"
															mr="5px"
															mb="5px"
														>
															<TagLabel>Available</TagLabel>
														</Tag>
													)}
												{!jobRequestResponse.available && (
													<Tag
														borderRadius="full"
														variant="solid"
														bgColor="red.500"
														mr="5px"
														mb="5px"
													>
														<TagLabel>Not Available</TagLabel>
													</Tag>
												)}{" "}
												{jobRequest?.selectedResponseId ===
													jobRequestResponse.id && (
													<Tag
														borderRadius="full"
														w="260px"
														variant="solid"
														bgColor="green"
														mr="5px"
														mb="5px"
													>
														<TagLabel>
															Request to schedule -{" "}
															{dateToFormat(jobRequest.scheduleDate)}
														</TagLabel>
													</Tag>
												)}
											</Td>
											<Td>
												{" "}
												<Menu closeOnSelect={false} isOpen={isOpen}>
													<MenuButton
														as={IconButton}
														icon={<UpDownIcon />}
														onClick={handleOpen}
													/>
													<MenuList>
														{jobRequest.selectedResponseId && (
															<MenuItem
																onClick={() =>
																	handleScheduling(jobRequestResponse)
																}
															>
																Undo scheudle
															</MenuItem>
														)}
														{!jobRequest.selectedResponseId && (
															<MenuItem onClick={() => setShowDatePicker(true)}>
																Schedule
															</MenuItem>
														)}
														{showDatePicker &&
															!jobRequest.selectedResponseId && (
																<DatePicker
																	placeholderText="Select date"
																	onChange={(e) => {
																		handleScheduling(e, jobRequestResponse)
																	}}
																	dateFormat="MM/dd/yyyy"
																/>
															)}
													</MenuList>
												</Menu>
											</Td>
										</Tr>
									)
								})}
							{awaitingVendors.length > 0 &&
								awaitingVendors.map((vendor) => {
									return (
										<Tr key={vendor.id}>
											<Td>
												{vendor.name
													? vendor.name
													: vendor.email
													? vendor.email
													: formatPhoneNumber(vendor.phoneNumber)}
											</Td>
											<Td />
											<Td />
											<Td>
												{" "}
												<Tag
													borderRadius="full"
													variant="solid"
													bgColor="
													#gray.500"
													mr="5px"
													mb="5px"
												>
													<TagLabel>Awaiting Repsonse</TagLabel>
												</Tag>
											</Td>
										</Tr>
									)
								})}
						</Tbody>
					</Table>
				</Box>
			</Box>
		</>
	)
}
