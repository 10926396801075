import { Box, Image, Text } from "@chakra-ui/react"

export const EmptyState = ({ helperText }) => {
	return (
		<Box w="100%" m="auto" pb="40px">
			<Image src="/assets/house_landscape.png" alt="empty state" m="auto" />
			<Text textAlign="center">{helperText}</Text>
		</Box>
	)
}
