import { useState } from "react"
import { NavLink } from "react-router-dom"
import { signInWithGoogle } from "../firebase"
import {
	AbsoluteCenter,
	Box,
	Button,
	Divider,
	FormControl,
	Input,
	Image,
	Link,
	Text,
	VStack,
} from "@chakra-ui/react"
import PhoneInput from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"

export const VendorRegistrationSecondStep = ({
	formData,
	setFormData,
	register,
	handleChange,
	id,
}) => {
	const [formError, setFormError] = useState(false)

	const verifySignUpInformation = () => {
		const fields = ["email", "name", "phoneNumber"]
		const isAnyFieldEmpty = fields.some(
			(field) =>
				formData[field] === "" ||
				formData[field] === null ||
				formData[field] === undefined ||
				(Array.isArray(formData[field]) && formData[field].length === 0)
		)
		const hasError =
			isAnyFieldEmpty ||
			(!!formData.phoneNumber && !isValidPhoneNumber(formData.phoneNumber))
		if (hasError) {
			alert("Please complete all fields before proceeding.")
		}
		setFormError(hasError)
		if (hasError) return false
		return true
	}

	const onSubmit = (e, type) => {
		e.preventDefault()
		if (verifySignUpInformation() === false) return
		if (type === "google") {
			signInWithGoogle(formData, true, id)
		} else {
			register(formData)
		}
	}

	return (
		<FormControl>
			<VStack gap={2} alignItems="left">
				<Input
					isInvalid={formError && !formData.name}
					type="text"
					bg="white"
					value={formData.name}
					onChange={(e) => handleChange("name", e.target.value)}
					placeholder="Company Name"
				/>
				<Input
					isInvalid={formError && !formData.email}
					type="text"
					bg="white"
					value={formData.email}
					onChange={(e) => handleChange("email", e.target.value.toLowerCase())}
					placeholder="Company email where job requests are sent"
				/>

				<PhoneInput
					country="US"
					style={{
						width: "100%",
						height: "40px",
						padding: "0.5rem 0.75rem",
						border:
							formError &&
							(formData.phoneNumber === null ||
								formData.phoneNumber === undefined ||
								(formData.phoneNumber &&
									!isValidPhoneNumber(formData.phoneNumber)))
								? "1px solid #F05252"
								: "1px solid #CBD5E0",
						borderRadius: "0.375rem",
						outlineColor:
							formError &&
							(formData.phoneNumber === null ||
								formData.phoneNumber === undefined ||
								(formData.phoneNumber &&
									!isValidPhoneNumber(formData.phoneNumber)))
								? "#F05252"
								: "#CBD5E0",
					}}
					placeholder="Enter company phone number"
					value={formData.phoneNumber}
					onChange={(e) => {
						setFormData((prevFormData) => ({
							...prevFormData,
							phoneNumber: e,
						}))
					}}
					onFocus={(e) => {
						e.target.style.borderColor = "#4299e1"
						e.target.style.boxShadow = "0 0 0 3px rgba(66, 153, 225, 0.1)"
						e.target.style.outlineColor = "#4299e1"
					}}
					onBlur={(e) => {
						e.target.style.borderColor =
							formError &&
							(formData.phoneNumber === null ||
								formData.phoneNumber === undefined ||
								(formData.phoneNumber &&
									!isValidPhoneNumber(formData.phoneNumber)))
								? "#F05252"
								: "#CBD5E0"
						e.target.style.boxShadow =
							formError &&
							(formData.phoneNumber === null ||
								formData.phoneNumber === undefined ||
								(formData.phoneNumber &&
									!isValidPhoneNumber(formData.phoneNumber)))
								? "0 0 0 1px #F05252"
								: "none"
					}}
				/>
				<Input
					type="password"
					bg="white"
					value={formData.password}
					onChange={(e) => handleChange("password", e.target.value)}
					placeholder="Password"
				/>
				<Link as={NavLink} to="/reset" fontSize="15px">
					Forgot Password?
				</Link>
				<Text fontSize="15px">
					Already have an account?{" "}
					<Link as={NavLink} to="/login">
						Login
					</Link>
				</Text>
				<Button
					w="100%"
					bgColor="#3690D8"
					color="white"
					onClick={(e) => onSubmit(e, "register")}
				>
					Create account
				</Button>
				<Text fontSize="15px">
					By creating an account you agree to the{" "}
					<Link as={NavLink} to="/login">
						Terms of Service{" "}
					</Link>{" "}
					and{" "}
					<Link as={NavLink} to="/login">
						Privacy Policy.{" "}
					</Link>
				</Text>
				<Box position="relative" padding={{ base: "10px", md: "25px" }}>
					<Divider />
					<AbsoluteCenter bgColor="#fafafa" px="4">
						Or
					</AbsoluteCenter>
				</Box>
				<Button
					border="black solid"
					bgColor="white"
					w="100%"
					alignContent="left"
					onClick={(e) => onSubmit(e, "google")}
				>
					<Image
						src="/assets/google_logo.png"
						alt="google"
						width="20px"
						height="20px"
						mr="10px"
					/>
					Continue with Google
				</Button>
			</VStack>
		</FormControl>
	)
}
