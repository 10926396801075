import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md"

export const StarRating = ({ rating, reviewCount }) => {
	const stars = []
	const totalStars = 5

	// Calculate the number of filled stars
	const filledStars = Math.floor(rating)

	// Calculate the number of half stars
	const hasHalfStar = rating % 1 !== 0

	// Add filled stars
	for (let i = 0; i < filledStars; i++) {
		stars.push(<Icon key={i} as={MdStar} color="yellow.500" />)
	}

	// Add half star if present
	if (hasHalfStar) {
		stars.push(<Icon key="half" as={MdStarHalf} color="yellow.500" />)
	}

	// Add empty stars
	const remainingStars = totalStars - stars.length
	for (let i = 0; i < remainingStars; i++) {
		stars.push(<Icon key={`empty-${i}`} as={MdStarBorder} color="gray.400" />)
	}

	return (
		<Flex alignItems="center">
			<Text mr={2} fontSize="sm" color="gray.600">
				{rating}
			</Text>
			{stars.map((star, index) => (
				<Box key={index} mr={1} display="flex" alignItems="center">
					{star}
				</Box>
			))}
			<Text ml={2} fontSize="sm" color="gray.600">
				({reviewCount})
			</Text>
		</Flex>
	)
}
