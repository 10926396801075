const config = {
	development: {
		baseURL: "http://localhost:3001",
	},
	production: {
		baseURL: "https://relay-57861.web.app",
	},
}

module.exports = config[process.env.NODE_ENV || "development"]
