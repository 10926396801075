import {
	Box,
	Button,
	Card,
	CardHeader,
	CardBody,
	Stack,
	StackDivider,
	Text,
	Tag,
	TagLabel,
} from "@chakra-ui/react"
import DatePicker from "react-datepicker"
import { dateToFormat } from "../Utilities"
import "../date-picker.css"

export const JobRequestResponseMobileCard = ({
	jobRequestResponse,
	jobRequest,
	handleScheduling,
	selectedResponseId,
	showDatePicker,
	setShowDatePicker,
}) => {
	return (
		<Card w="100%" mb="20px" key={jobRequestResponse.id}>
			<CardHeader fontWeight="semibold">
				{jobRequestResponse.vendorName}
			</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Text style={{ wordWrap: "break-word" }}>
						{jobRequestResponse.vendorContactEmail}
					</Text>

					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Job Estimate:</Text>
						{jobRequestResponse.minQuote && jobRequestResponse.maxQuote && (
							<Text>
								${jobRequestResponse.minQuote}-$
								{jobRequestResponse.maxQuote}
							</Text>
						)}
						{jobRequestResponse.serviceCharge && (
							<Box display="flex">
								<Text>${jobRequestResponse.serviceChargeCost}</Text>
								<Tag
									ml="10px"
									width="125px"
									borderRadius="full"
									variant="solid"
									bgColor="blue.300"
									color="blue.800"
								>
									<TagLabel>Service Charge</TagLabel>
								</Tag>
							</Box>
						)}
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<Text fontWeight="semibold">Alternative Dates:</Text>
						{jobRequestResponse.servicePreferredDates.map((entry, index) => (
							<Box key={index}>
								<Text align="right">{dateToFormat(entry.date)}</Text>
							</Box>
						))}
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Vendor Status:</Text>
						{selectedResponseId === jobRequestResponse.id && (
							<Text>
								Request to schedule - {dateToFormat(jobRequest.scheduleDate)}
							</Text>
						)}
						{selectedResponseId !== jobRequestResponse.id &&
							jobRequestResponse.availabile && <Text>Not Available</Text>}
						{selectedResponseId !== jobRequestResponse.id &&
							!jobRequestResponse.availabile && <Text> Available</Text>}
					</Box>
				</Stack>
				{jobRequest.selectedResponseId && (
					<Button
						w="100%"
						mt="10px"
						bgColor="red.500"
						color="white"
						onClick={() => handleScheduling(jobRequestResponse)}
					>
						Undo scheudle
					</Button>
				)}
				{!jobRequest.selectedResponseId && (
					<Button
						w="100%"
						mt="10px"
						bgColor="red.500"
						color="white"
						onClick={() => setShowDatePicker(true)}
					>
						Schedule
					</Button>
				)}
				{showDatePicker && !jobRequest.selectedResponseId && (
					<DatePicker
						mt="10px"
						placeholderText="Select date"
						onChange={(e) => {
							handleScheduling(e, jobRequestResponse)
						}}
						dateFormat="MM/dd/yyyy"
					/>
				)}
			</CardBody>
		</Card>
	)
}
