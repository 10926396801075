import {
	Box,
	Input,
	Tag,
	TagLabel,
	TagCloseButton,
	Wrap,
} from "@chakra-ui/react"
import { useState, useEffect } from "react"

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const isValidEmail = (input) => EMAIL_REGEXP.test(input)

const isValidZip = (zip) => /^\d{5}$/.test(zip)

/**
 * Represents an input added to the list. Highlighted with a close button for removal.
 */
export const Chip = ({ input, onCloseClick, handleFormChange }) => (
	<Tag key={input} borderRadius="full" variant="solid" colorScheme="red">
		<TagLabel>{input}</TagLabel>
		<TagCloseButton
			onClick={() => {
				onCloseClick(input, handleFormChange)
			}}
		/>
	</Tag>
)

/**
 * A horizontal stack of chips. Like a Pringles can on its side.
 */
export const ChipList = ({
	inputs = [],
	onCloseClick,
	formData,
	handleFormChange,
	isZip,
}) => (
	<Wrap spacing={1} mb={3}>
		{formData &&
			inputs.map((input) => (
				<Chip
					input={input}
					isZip={isZip}
					key={input}
					onCloseClick={onCloseClick}
					handleFormChange={handleFormChange}
				/>
			))}
	</Wrap>
)

/**
 * Form field wrapper.
 */
export const ChipInput = ({ ...rest }) => (
	<Box>
		<Input type={rest.isZip ? "number" : "email"} {...rest} />
	</Box>
)

export const MultiDisplayInput = ({
	formData,
	isZip,
	handleFormChange,
	placeholder,
}) => {
	useEffect(() => {
		if (formData && formData.length === 0 && inputs.length > 0) {
			setInputs([])
		}
	}, [formData])
	const [inputValue, setInputValue] = useState("")
	const [inputs, setInputs] = useState(formData)
	// Checks whether we've added this input already.
	const inputChipExists = (input) => inputs.includes(input)

	// Add an input to the list, if it's valid and isn't already there.
	const addInputs = (inputsToAdd, isZip) => {
		let validatedInputs = []
		if (isZip === true) {
			validatedInputs = inputsToAdd
				.map((e) => e.trim())
				.filter((input) => isValidZip(input) && !inputChipExists(input))
		} else {
			validatedInputs = inputsToAdd
				.map((e) => e.trim())
				.filter((input) => isValidEmail(input) && !inputChipExists(input))
		}
		const newInputs = [...inputs, ...validatedInputs]

		setInputs(newInputs)
		handleFormChange(newInputs)
		setInputValue("")
	}

	// Remove an input from the list.
	const removeInput = (input, handleFormChange) => {
		const index = inputs.findIndex((e) => e === input)
		if (index !== -1) {
			const newInputs = [...inputs]
			newInputs.splice(index, 1)
			setInputs(newInputs)
			handleFormChange(newInputs)
			handleChange(newInputs)
		}
	}

	// Save input field contents in state when changed.
	const handleChange = (e) => {
		setInputValue(e.target && e.target.value ? e.target.value : "")
	}

	// Validate and add the input if we press tab, enter or comma.
	const handleKeyDown = (e, isZip) => {
		if (["Enter", "Tab", ","].includes(e.key)) {
			e.preventDefault()

			addInputs([inputValue], isZip)
		}
	}

	// Split and add inputs when pasting.
	const handlePaste = (e) => {
		e.preventDefault()

		const pastedData = e.clipboardData.getData("text")
		const pastedInputs = pastedData.split(",")
		addInputs(pastedInputs)
	}

	const handleCloseClick = (input, handleFormChange) => {
		removeInput(input, handleFormChange)
	}

	return (
		<>
			{formData && formData.length > 0 && (
				<ChipList
					inputs={inputs}
					formData={formData}
					onCloseClick={handleCloseClick}
					handleFormChange={handleFormChange}
				/>
			)}
			<ChipInput
				bgColor="white"
				placeholder={placeholder}
				onPaste={handlePaste}
				onKeyDown={(e) => handleKeyDown(e, isZip)}
				onChange={handleChange}
				value={inputValue}
			/>
		</>
	)
}
