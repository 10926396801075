import { useState, useContext } from "react"
import { Box, Button, Flex, IconButton, Image, Text } from "@chakra-ui/react"

import "./select.css"
import { useNavigate, NavLink, useMatch } from "react-router-dom"
import { logout } from "./firebase"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import { teamIdDefaultIds } from "./Options/DefaultTeamIds.js"

export const ClientLoggedInNav = ({ userPlus }) => {
	const navigate = useNavigate()

	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

	const team =
		(userPlus?.teamId &&
			teamIdDefaultIds.find((team) => team.teamId === userPlus.teamId)) ||
		null

	const logOutFromApp = async () => {
		try {
			await logout()
		} catch (error) {
			console.error("Failed to log out:", error)
		}
		navigate("/login")
	}

	return (
		<>
			<Box
				bgColor="white"
				borderBottom={"1px solid #cccccc"}
				height="70px"
				display={["none", "none", "none", "flex"]}
			>
				<Flex height="100%" width="100%">
					{userPlus?.userType === "client" || userPlus?.userType === "TC" ? (
						<Image
							padding="18px	30px 10px 30px"
							src={
								team
									? team.logo_path
									: process.env.PUBLIC_URL + "/assets/Logo.svg"
							}
						/>
					) : (
						<Image
							src="/assets/Logo.svg"
							alt="logo"
							padding="18px	30px 10px 30px"
							bgColor="white"
						/>
					)}
					<Flex
						width="100%"
						height="100%"
						alignItems={"center"}
						background="white"
						gap={{ base: 3, lg: 10 }}
						lineHeight="70px"
					>
						<Text
							style={{
								borderBottom: useMatch("/vendors")
									? "2px solid	#F05252"
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/vendors">
								Vendors
							</NavLink>
						</Text>
						<Text
							style={{
								borderBottom: useMatch("/job-requests")
									? "2px solid	#F05252"
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/job-requests">
								Job Requests
							</NavLink>
						</Text>
						<Text>
							<a
								href="https://quiploteams.com/how-it-works"
								target="_blank"
								rel="noopener noreferrer"
							>
								How it works
							</a>
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="flex-end"
						mr="30px"
						bgColor="white"
						width="1000px"
						gap={5}
					>
						<Button variant="smallCta" onClick={logOutFromApp}>
							Log Out
						</Button>
					</Flex>
				</Flex>
			</Box>
			<Box
				bgColor="white"
				display="flex"
				width="100%"
				justifyContent="space-between"
			>
				{/* Mobile */}

				<Image
					padding="18px"
					display={["flex", "flex", "flex", "none"]}
					src={
						team ? team.logo_path : process.env.PUBLIC_URL + "/assets/Logo.svg"
					}
				/>

				<IconButton
					aria-label="Open Menu"
					size="xxl"
					bgColor="white"
					marginRight="18px"
					icon={<HamburgerIcon w={7} h={7} />}
					onClick={() => setMobileMenuOpen(true)}
					display={["flex", "flex", "flex", "none"]}
				/>

				<Flex
					w="100vw"
					display={isMobileMenuOpen ? "flex" : "none"}
					bgColor="gray.50"
					zIndex={20}
					h="100vh"
					pos="fixed"
					top="0"
					left="0"
					overflowY="auto"
					flexDir="column"
				>
					<Flex justify="flex-end">
						<IconButton
							mt={2}
							mr={2}
							aria-label="Open Menu"
							size="lg"
							bgColor="background"
							icon={<CloseIcon />}
							onClick={() => setMobileMenuOpen(false)}
						/>
					</Flex>
					<Flex flexDir="column" align="center">
						<NavLink to="/vendors" onClick={() => setMobileMenuOpen(false)}>
							<Button variant="ghost" aria-label="Vendors" my={5} w="100%">
								Vendors
							</Button>
						</NavLink>
						<NavLink
							to="/job-requests"
							onClick={() => setMobileMenuOpen(false)}
						>
							<Button variant="ghost" aria-label="Job Requests" my={5} w="100%">
								Job Requests
							</Button>
						</NavLink>
						<Button
							as="a"
							href="https://quiploteams.com/how-it-works"
							onClick={() => setMobileMenuOpen(false)}
							target="_blank"
							rel="noopener noreferrer"
							variant="ghost"
							aria-label="How it works"
							my={5}
							w="100%"
						>
							How it works
						</Button>

						<NavLink onClick={logOutFromApp}>
							<Button variant="ghost" aria-label="Log Out" my={5} w="100%">
								Log Out
							</Button>
						</NavLink>
					</Flex>
				</Flex>
			</Box>
		</>
	)
}
