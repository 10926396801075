import { Box, Text, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom" // Use your router's Link component here

export const NotFound = () => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			h="100vh"
			flexDirection="column"
		>
			<Text fontSize="6xl" fontWeight="bold" mb={4}>
				404
			</Text>
			<Text fontSize="xl" fontWeight="bold" mb={8}>
				Page Not Found
			</Text>
			<Button as={Link} to="/" colorScheme="teal" size="lg">
				Go Home
			</Button>
		</Box>
	)
}
