import { Box, HStack, Image, Text } from "@chakra-ui/react"
import { dateToFormat } from "../Utilities"

export const ClientInsightInformationBox = ({ client }) => {
	return (
		<Box
			bgColor="white"
			boxShadow="md"
			borderRadius="md"
			display="flex"
			position="relative"
		>
			<Image
				src="/assets/house_banner_icon.svg"
				position={{ md: "absolute" }}
				display={{ base: "none", lg: "flex" }}
			/>
			<Image
				src="/assets/house_banner_icon2.svg"
				position="absolute"
				top="0"
				right="0"
				display={{ base: "flex", lg: "none" }}
			/>
			<Box
				display={{ lg: "flex" }}
				width={{ base: "100%", lg: "50%" }}
				justifyContent="space-between"
				p="20px"
				ml={{ lg: "125px" }}
				mt={{ base: "25px", lg: "0" }}
			>
				<Box ml={{ lg: "10px" }}>
					<HStack alignItems={{ base: "center", lg: "start" }}>
						<Image src="/assets/user_group.svg" />
						<Text fontWeight="semibold" fontSize="lg">
							Client Info
						</Text>
					</HStack>
					<Box mt={{ base: "5px", lg: "15px" }}>
						<Text>{client?.name ? client.name : client.email}</Text>
						{client?.name && <Text>{client.email}</Text>}
					</Box>
				</Box>
				<Box ml={{ lg: "10px" }} mt={{ base: "10px", lg: "0px" }}>
					<Text fontWeight="semibold" fontSize="lg">
						Account Created
					</Text>
					<Box mt={{ base: "5px", lg: "15px" }}>
						{dateToFormat(client._createdAt)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
