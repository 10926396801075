import { useContext, useState, useEffect, useCallback } from "react"
import {
	Box,
	Button,
	Center,
	Image,
	Heading,
	HStack,
	Link,
	Stack,
	Table,
	Tag,
	TagLabel,
	TagLeftIcon,
	Thead,
	Tbody,
	Text,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react"
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons"
import { NavLink } from "react-router-dom"
import { db } from "../../firebase"
import { collection, getDocs, query, where } from "firebase/firestore"
import { differenceInDays } from "date-fns"

import { SkeletonTable } from "../../Components/SkeletonTable"
import { InsightListMobileCard } from "../../Components/InsightListMobileCard"
import { UserContext } from "../../Contexts/UserContext.js"

import { formatPhoneNumber, dateToFormat } from "../../Utilities"
import "../../select.css"

export const InsightsList = () => {
	const { userPlus } = useContext(UserContext)
	const [hasMore, setHasMore] = useState(true)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [direction, setDirection] = useState("next")

	const [clientList, setClientList] = useState([])
	const [clientChunkArray, setClientChunkArray] = useState([])
	const [pageLoading, setPageLoading] = useState(true)

	const getClientsByTeamId = useCallback(async (teamId) => {
		const teamQuery = query(
			collection(db, "agentClient"),
			where("teamId", "==", teamId)
		)
		return await getDocs(teamQuery)
	}, [])

	const getClientsByAgentId = useCallback(async (agentId) => {
		const agentQuery = query(
			collection(db, "agentClient"),
			where("agentId", "==", agentId)
		)
		return await getDocs(agentQuery)
	}, [])

	useEffect(() => {
		if (userPlus === "pending" || userPlus === null || !userPlus?.userType)
			return

		const fetchData = async () => {
			try {
				setPageLoading(true)
				let clients = []

				if (userPlus.isAdmin) {
					clients = await getClientsByTeamId(userPlus.teamId)
				} else if (userPlus.userType === "agent" && !userPlus.isAdmin) {
					clients = await getClientsByAgentId(userPlus.id)
				}

				const clientUidList = Array.isArray(clients)
					? [
							...new Set(
								clients.flatMap((clientSnapshot) =>
									clientSnapshot.docs.map((doc) => doc.data().clientId)
								)
							),
					  ]
					: clients.docs.map((doc) => doc.data().clientId)

				setClientList(clientUidList)

				if (clientUidList.length > 0) {
					const clientChunkIds = chunkArray(clientUidList, 10)
					setClientChunkArray(clientChunkIds)
					await fetchClientByClientUidList(clientChunkIds)
				} else {
					setClientChunkArray([])
					setClientList([])
					setHasMore(false)
				}
			} catch (error) {
				console.error("Error fetching data:", error)
			} finally {
				setPageLoading(false)
			}
		}
		fetchData()
	}, [userPlus])

	function chunkArray(array, size) {
		const result = []
		for (let i = 0; i < array.length; i += size) {
			result.push(array.slice(i, i + size))
		}
		return result
	}

	const fetchClientByClientUidList = async (clientChunkIds) => {
		const clients = []
		for (const chunkId of clientChunkIds[currentIndex]) {
			const clientQuery = query(
				collection(db, "users"),
				where("id", "==", chunkId)
			)
			const doc = await getDocs(clientQuery)

			clients.push(
				...doc.docs.map((doc) => ({
					...doc.data(),
				}))
			)
		}

		if (direction === "next") {
			if (currentIndex + 1 < clientChunkIds.length) {
				setHasMore(true)
			} else {
				setHasMore(false)
			}
		} else {
			if (currentIndex - 1 < clientChunkIds.length) {
				setHasMore(true)
			} else {
				setHasMore(false)
			}
		}
		setClientList(clients)
	}

	useEffect(() => {
		const fetchClientByClientUidList = async (clientChunkIds) => {
			const clients = []
			for (const chunkId of clientChunkIds[currentIndex]) {
				const servicesQuery = query(
					collection(db, "users"),
					where("id", "==", chunkId)
				)
				const doc = await getDocs(servicesQuery)

				clients.push(
					...doc.docs.map((doc) => ({
						...doc.data(),
					}))
				)
			}

			if (direction === "next") {
				if (currentIndex + 1 < clientChunkIds.length) {
					setHasMore(true)
				} else {
					setHasMore(false)
				}
			} else {
				if (currentIndex - 1 < clientChunkIds.length) {
					setHasMore(true)
				} else {
					setHasMore(false)
				}
			}
			setClientList(clients)
		}

		if (clientChunkArray.length > 0) {
			fetchClientByClientUidList(clientChunkArray)
		}
	}, [currentIndex, direction, clientChunkArray])

	const goToNext = () => {
		setCurrentIndex(currentIndex + 1)
		setDirection("next")
	}

	const goToPrevious = () => {
		setCurrentIndex(currentIndex - 1)
		setDirection("previous")
	}

	const calculateLastLogin = (client, index) => {
		if (client._lastLogin) {
			const numberOfDays = differenceInDays(
				new Date(),
				client._lastLogin.toDate()
			)
			if (numberOfDays === 0) {
				return (
					<Tag
						key={index}
						borderRadius="full"
						variant="solid"
						bgColor="green"
						mr="5px"
						mt="5px"
						mb="5px"
					>
						<TagLeftIcon as={ArrowUpIcon} />

						<TagLabel>Today</TagLabel>
					</Tag>
				)
			}
			if (numberOfDays === 1) {
				return (
					<Tag
						key={index}
						borderRadius="full"
						variant="solid"
						bgColor="green"
						mr="5px"
						mt="5px"
						mb="5px"
					>
						<TagLeftIcon as={ArrowUpIcon} />

						<TagLabel>1 day ago</TagLabel>
					</Tag>
				)
			} else {
				return (
					<Tag
						key={index}
						borderRadius="full"
						variant="solid"
						bgColor={
							numberOfDays < 30
								? "green"
								: numberOfDays < 90
								? "orange"
								: "red.500"
						}
						mr="5px"
						mt="5px"
						mb="5px"
					>
						{numberOfDays < 30 && <TagLeftIcon as={ArrowUpIcon} />}
						{numberOfDays > 90 && <TagLeftIcon as={ArrowDownIcon} />}
						<TagLabel>{numberOfDays} days ago</TagLabel>
					</Tag>
				)
			}
		} else {
			return
		}
	}

	return (
		<>
			<Box
				display={["flex", "flex", "flex", "none"]}
				flexDirection="column"
				m="30px 15px"
			>
				<Heading size="lg" fontWeight="bold" mb="5px">
					Insights
				</Heading>
				{pageLoading && <SkeletonTable variant="insightList" />}
				{clientList.length > 0 && (
					<Box mt="20px" flexDirection="column">
						{clientList.map((client, index) => {
							return (
								<InsightListMobileCard
									client={client}
									clientTag={calculateLastLogin(client, index)}
									index={index}
									key={index}
								/>
							)
						})}
					</Box>
				)}
				{clientList.length > 0 && (
					<Box display="flex" justifyContent="flex-end" gap={3} my="15px">
						<Button
							onClick={() => goToPrevious()}
							isDisabled={currentIndex === 0}
						>
							Previous
						</Button>
						<Button onClick={() => goToNext()} isDisabled={!hasMore}>
							Next
						</Button>
					</Box>
				)}
				{clientList.length === 0 && !pageLoading && (
					<Center m="auto">
						<Stack spacing={3} alignItems="center">
							<Image
								src="../../assets/client_insights_empty_state.svg"
								h="200px"
								w="200px"
							/>
							<Heading fontWeight="semibold" size="lg">
								No clients on Quiplo yet!
							</Heading>
							<Text align="center">
								<Link as={NavLink} to="/invite">
									Invite your clients
								</Link>{" "}
								via email to track invites and sign ups.
							</Text>
						</Stack>
					</Center>
				)}
			</Box>
			<Box
				display={["none", "none", "none", "flex"]}
				mt="30px"
				flexDirection="column"
				ml={{ lg: "30px", xl: "93px" }}
				mr={{ lg: "30px", xl: "93px" }}
			>
				<HStack justifyContent="space-between">
					<Heading size="lg" fontWeight="bold">
						Insights
					</Heading>
				</HStack>
				{pageLoading && <SkeletonTable variant="insightList" />}
				{clientList.length > 0 && !pageLoading && (
					<Table variant="simple" mt={4}>
						<Thead>
							<Tr>
								<Th>Last Login</Th>
								<Th>Clients</Th>
								<Th>Invite Sent</Th>
								<Th>Job Requests</Th>
								<Th>Client Activity</Th>
							</Tr>
						</Thead>
						<Tbody>
							{clientList.map((client, index) => (
								<Tr key={client.id}>
									<Td>{calculateLastLogin(client)}</Td>
									<Td>
										<Text fontWeight="semibold">{client.name}</Text>
										<Text>
											{client.phoneNumber
												? formatPhoneNumber(client.phoneNumber)
												: client.email}
										</Text>
									</Td>
									<Td>{dateToFormat(client._createdAt)}</Td>
									<Td>{client.jobRequestsMade ? client.jobRequestsMade : 0}</Td>
									<Td>
										{client.jobRequestsMade > 0 && (
											<NavLink
												to={`/insights/${client.id}`}
												state={{ client }}
												style={{
													textDecoration: "underline",
													color: "#1A73E8",
												}}
											>
												View
											</NavLink>
										)}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				)}
				{clientList.length === 0 && !pageLoading && (
					<Center m="auto" mb="40px">
						<Stack spacing={3} alignItems="center">
							<Image
								src="../../assets/client_insights_empty_state.svg"
								h="200px"
								w="200px"
							/>
							<Heading fontWeight="semibold" size="lg">
								No clients on Quiplo yet!
							</Heading>
							<Text align="center">
								<Link as={NavLink} to="/invite">
									Invite your clients
								</Link>{" "}
								via email to track invites and sign ups.
							</Text>
						</Stack>
					</Center>
				)}
				{clientList.length > 0 && (
					<Box display="flex" justifyContent="flex-end" gap={3} my="15px">
						<Button
							onClick={() => goToPrevious()}
							isDisabled={currentIndex === 0}
						>
							Previous
						</Button>
						<Button onClick={() => goToNext()} isDisabled={!hasMore}>
							Next
						</Button>
					</Box>
				)}
			</Box>
			{/* <Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "full", lg: "2xl" }}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Filter Vendors</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack gap={2}>
							<Box>
								<Select
									placeholder="City"
									value={locationOptions.filter((option) =>
										locationFilter.includes(option.value)
									)}
									isMulti
									options={locationOptions}
									onChange={(options) => {
										setLocationFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Zip Codes"
									value={zipCodeFilterList.filter((option) =>
										zipcodeFilter.includes(option.value)
									)}
									isMulti
									options={zipCodeFilterList}
									onChange={(options) => {
										setZipcodeFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Company Name"
									isMulti
									value={
										nameValueFilter.length > 0
											? companyFilterList.find((option) =>
													nameValueFilter.includes(option.value)
											  )
											: null
									}
									options={companyFilterList}
									onChange={(options) => {
										handleCompanyFilterChange(options)
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Preferred Vendors"
									value={preferredVendorOptions.filter(
										(option) => option.value === preferredVendorFilter
									)}
									options={preferredVendorOptions}
									onChange={(e) => {
										setPreferredVendorFilter(e.value)
									}}
								/>
							</Box>
						</Stack>
					</ModalBody>
					<ModalFooter margin="auto" w="100%">
						<Stack
							direction={{ base: "column", md: "row" }}
							w={{ base: "100%", md: null }}
							justifyContent="center"
						>
							<Button
								bgColor="red.500"
								color="white"
								onClick={getFilteredList}
								w={{ base: "100%", md: "125px" }}
							>
								Apply Filters
							</Button>
							<Button
								onClick={() =>
									setClearFilters((prevClearFilters) => !prevClearFilters)
								}
								mt={{ base: "15px", md: "0px" }}
								w={{ base: "100%", md: "125px" }}
							>
								Clear Filters
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal> */}
		</>
	)
}
