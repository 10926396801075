import { Box, Flex } from "@chakra-ui/react"
import { NavWrapper } from "../NavWrapper.js"
import { Footer } from "../Footer.js"

export const Wrapper = ({ component, children }) => {
	return (
		<Flex direction="column" minHeight="100vh">
			<Box flex="1">
				<NavWrapper />
				{component && component()}
				{children}
			</Box>
			<Footer />
		</Flex>
	)
}
