import { useContext, useState } from "react"
import { Box, Button, Image, Text } from "@chakra-ui/react"
import { logUserEvent } from "../firebase"
import { UserContext } from "../Contexts/UserContext"
import { analytic_events } from "../analytics"
import { AgentPayWallModal } from "./AgentPayWall"
import { VendorPayWallModal } from "./VendorPayWall"

export const PaywallBanner = ({ location }) => {
	const { userPlus } = useContext(UserContext)
	const [modal, setModal] = useState(false)

	const handleClick = async () => {
		await logUserEvent(analytic_events.OPEN_PAYWALL, userPlus.id, {
			userType: userPlus.userType,
			location: location,
		})
		setModal(true)
	}
	return (
		<>
			<Box
				w="100%"
				bgColor="blue.200"
				display={{ md: "flex" }}
				justifyContent="space-between"
				p="20px"
			>
				<Box>
					<Box display="flex" alignItems="center">
						<Image
							src="/assets/verified_badge.svg"
							color="blue.700"
							my="auto"
							mr="10px"
						/>
						<Text color="blue.700" fontWeight="semibold" fontSize="xl">
							Upgrade your Quiplo account
						</Text>
					</Box>
					<Text>
						{userPlus.userType === "vendor"
							? "Unlock the ability to respond to job requests and have your business marked as verified so agents and homeowners know you are responsive."
							: "Unlock premium features to support and grow your business"}
					</Text>
				</Box>
				<Button
					bgColor="blue.700"
					color="white"
					borderRadius="full"
					w={{ base: "100%", md: "auto" }}
					mt={{ base: "10px", md: "0px" }}
					size="lg"
					onClick={handleClick}
				>
					Upgrade
				</Button>
			</Box>
			{userPlus.userType === "agent" && modal && (
				<AgentPayWallModal
					isOpen={modal}
					setPaywallTriggered={setModal}
					location={location}
				/>
			)}
			{userPlus.userType === "vendor" && modal && (
				<VendorPayWallModal
					isOpen={modal}
					setPaywallTriggered={setModal}
					location={location}
				/>
			)}
		</>
	)
}
