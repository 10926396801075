import { useContext, useState, useEffect, useCallback } from "react"
import {
	Box,
	Button,
	Center,
	Image,
	IconButton,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalHeader,
	ModalOverlay,
	ModalFooter,
	ModalContent,
	ModalCloseButton,
	Stack,
	Table,
	Tag,
	TagLabel,
	Thead,
	Tbody,
	Text,
	Tr,
	Th,
	Td,
	useDisclosure,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import { useNavigate } from "react-router-dom"
import { db, logUserEvent } from "../../firebase"
import {
	addDoc,
	collection,
	deleteDoc,
	getDocs,
	query,
	where,
	orderBy,
} from "firebase/firestore"
import { serviceTypesOptions } from "../../Options/ServiceTypes"
import {
	NorthCarolinaLocationOptions,
	SouthCarolinaLocationOptions,
	GeorgiaLocationOptions,
	FloridaLocationOptions,
} from "../../Options/Locations"
import { StarIcon, UpDownIcon } from "@chakra-ui/icons"
import { VendorListMobileCard } from "../../Components/VendorListMobileCard"
import { AgentPayWallModal } from "../../Components/AgentPayWall"
import { PaywallBanner } from "../../Components/PaywallBanner"
import { SkeletonTable } from "../../Components/SkeletonTable"
import { StarRating } from "../../Components/StarRating"
import { VerifiedBadge } from "../../Components/VerifiedBadge"
import { UserContext } from "../../Contexts/UserContext.js"

import { JOB_REQUEST_LIMIT } from "../../enums"
import { analytic_events } from "../../analytics"
import { formatPhoneNumber } from "../../Utilities"
import "../../select.css"

export const VendorList = () => {
	const { userPlus } = useContext(UserContext)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()
	const [serviceChunkArray, setServiceChunkArray] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [filtered, setFiltered] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [direction, setDirection] = useState("next")
	const [pageLoading, setPageLoading] = useState(true)
	const [clearFilters, setClearFilters] = useState(true)

	const [filteredServices, setFilteredServices] = useState([])
	const [serviceTypeFilter, setServiceTypeFilter] = useState([])
	const [locationFilter, setLocationFilter] = useState([])
	const [zipcodeFilter, setZipcodeFilter] = useState([])
	const [nameValueFilter, setnameValueFilter] = useState([])
	const [nameLabelFilter, setnameLabelFilter] = useState([])
	const [companyFilterList, setCompanyFilterList] = useState([])
	const [zipCodeFilterList, setZipCodeFilterList] = useState([])

	const [preferredVendorFilter, setPreferredVendorFilter] = useState("")

	const [userPreferredServices, setUserPreferredServices] = useState([])
	const [teamPreferredServices, setTeamPreferredServices] = useState([])
	const [vendorList, setVendorList] = useState([])
	const [agentName, setAgentName] = useState("")
	const [paywallTriggered, setPaywallTriggered] = useState(false)
	const [hasOpened, setHasOpened] = useState(false)

	const locationOptions =
		userPlus?.userType === "TC"
			? [
					...NorthCarolinaLocationOptions,
					...SouthCarolinaLocationOptions,
					...FloridaLocationOptions,
					...GeorgiaLocationOptions,
			  ]
			: userPlus?.teamId === "SCOTTM"
			? GeorgiaLocationOptions
			: [...NorthCarolinaLocationOptions, ...SouthCarolinaLocationOptions]

	const setUserPreferred = async (serviceId, vendorId) => {
		if (userPreferredServices.find((s) => s === serviceId)) {
			const q = query(
				collection(db, "userPreferredServices"),
				where("userId", "==", userPlus.id),
				where("serviceId", "==", serviceId)
			)

			const doc = await getDocs(q)
			const docRef = doc.docs[0].ref

			await deleteDoc(docRef)
			setUserPreferredServices(
				userPreferredServices.filter((s) => s !== serviceId)
			)
		} else {
			await addDoc(collection(db, "userPreferredServices"), {
				id: crypto.randomUUID(),
				userId: userPlus.id,
				serviceId,
				vendorId,
			})
			setUserPreferredServices([...userPreferredServices, serviceId])
		}
	}

	const setTeamPreferred = async (serviceId, vendorId) => {
		if (teamPreferredServices.find((s) => s === serviceId)) {
			const q = query(
				collection(db, "teamPreferredServices"),
				where("teamId", "==", userPlus.teamId),
				where("serviceId", "==", serviceId)
			)

			const doc = await getDocs(q)
			const docRef = doc.docs[0].ref

			await deleteDoc(docRef)
			setTeamPreferredServices(
				teamPreferredServices.filter((s) => s !== serviceId)
			)
		} else {
			await addDoc(collection(db, "teamPreferredServices"), {
				id: crypto.randomUUID(),
				teamId: userPlus.teamId,
				serviceId,
				vendorId,
			})
			setTeamPreferredServices([...teamPreferredServices, serviceId])
		}
	}

	const handleCompanyFilterChange = (options) => {
		setnameValueFilter(options.map((option) => option.value))
		setnameLabelFilter(options.map((option) => option.label))
	}

	const getVendorsByTeamId = useCallback(async (teamId) => {
		const teamQuery = query(
			collection(db, "agentVendor"),
			where("teamId", "==", teamId)
		)
		return await getDocs(teamQuery)
	}, [])

	const getVendorsByAgentId = useCallback(async (agentId) => {
		const agentQuery = query(
			collection(db, "agentVendor"),
			where("agentId", "==", agentId)
		)
		return await getDocs(agentQuery)
	}, [])

	const getClientVendorList = useCallback(
		async (agentId) => {
			const agentNameQuery = query(
				collection(db, "users"),
				where("id", "==", agentId)
			)
			const querySnapshot = await getDocs(agentNameQuery)
			const agentName = querySnapshot.docs[0].data().name
			setAgentName(agentName)

			const agentQuery = query(
				collection(db, "userPreferredServices"),
				where("userId", "==", agentId)
			)

			let teamQuery

			if (userPlus && userPlus.teamId) {
				teamQuery = query(
					collection(db, "teamPreferredServices"),
					where("teamId", "==", userPlus.teamId)
				)
			}

			const queries = [getDocs(agentQuery)]
			if (teamQuery) {
				queries.push(getDocs(teamQuery))
			}

			return await Promise.all(queries)
		},
		[userPlus?.teamId]
	)

	const fetchVendorServicePairs = useCallback(async (vendorUidList) => {
		const serviceIds = []
		const vendorUidChunks = chunkArray(vendorUidList, 30)

		for (const vendorUidChunk of vendorUidChunks) {
			const serviceMapQuery = query(
				collection(db, "vendorServiceMap"),
				where("vendorId", "in", vendorUidChunk),
				orderBy("_createdAt")
			)
			const querySnapshot = await getDocs(serviceMapQuery)
			serviceIds.push(...querySnapshot.docs.map((doc) => doc.data().serviceId))
		}
		const serviceIdChunks = chunkArray(serviceIds, 10)
		setServiceChunkArray(serviceIdChunks)
		return serviceIdChunks
	}, [])

	useEffect(() => {
		if (userPlus === "pending" || userPlus === null || !userPlus?.userType)
			return

		const fetchData = async () => {
			try {
				setPageLoading(true)
				let vendors = []
				let combinedVendors = []

				if (userPlus.isAdmin) {
					vendors = await getVendorsByTeamId(userPlus.teamId)
				} else if (userPlus.userType === "agent" && !userPlus.teamId) {
					vendors = await getVendorsByAgentId(userPlus.id)
				} else if (
					userPlus.userType === "agent" &&
					userPlus.teamId &&
					!userPlus.isAdmin
				) {
					const [teamVendors, agentVendors] = await Promise.all([
						getVendorsByTeamId(userPlus.teamId),
						getVendorsByAgentId(userPlus.id),
					])
					combinedVendors = [teamVendors, agentVendors]
				} else if (userPlus.userType === "client") {
					const agentId = userPlus.invitedBy
					const clientQuery = await getClientVendorList(agentId)
					if (clientQuery.length > 1) {
						combinedVendors = clientQuery
					} else {
						vendors = clientQuery
					}
				}

				let vendorUidList = []
				if (combinedVendors.length > 0) {
					vendorUidList = [
						...new Set(
							combinedVendors.flatMap((vendorList) =>
								vendorList.docs.map((doc) => doc.data().vendorId)
							)
						),
					]
				} else {
					vendorUidList = Array.isArray(vendors)
						? [
								...new Set(
									vendors.flatMap((vendorSnapshot) =>
										vendorSnapshot.docs.map((doc) => doc.data().vendorId)
									)
								),
						  ]
						: vendors.docs.map((doc) => doc.data().vendorId)
				}

				setVendorList(vendorUidList)

				if (vendorUidList.length > 0) {
					const serviceChunkIds = await fetchVendorServicePairs(vendorUidList)
					await fetchServicesByVendorUidList(serviceChunkIds)
				} else {
					setServiceChunkArray([])
					setFilteredServices([])
					setHasMore(false)
				}
			} catch (error) {
				console.error("Error fetching data:", error)
			} finally {
				setPageLoading(false)
			}
		}

		const handleClearFilters = () => {
			if (clearFilters) {
				setFiltered(false)
				setCurrentIndex(0)
				setDirection("next")
				resetFilters()
			}
		}
		fetchData()

		return () => {
			handleClearFilters()
		}
	}, [userPlus, clearFilters])

	useEffect(() => {
		const getServiceFilter = async () => {
			try {
				const services = []
				for (const chunkId of serviceChunkArray) {
					const servicesQuery = query(
						collection(db, "services"),
						where("id", "in", chunkId),
						where("serviceType", "in", serviceTypeFilter)
					)
					const doc = await getDocs(servicesQuery)
					services.push(
						...doc.docs.map((doc) => ({
							...doc.data(),
						}))
					)
				}

				setFilteredServices(services)
				setFiltered(true)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}
		if (serviceTypeFilter.length > 0) {
			getServiceFilter()
		} else {
			setClearFilters((prevClearFilters) => !prevClearFilters)
		}
	}, [serviceTypeFilter])

	const resetFilters = () => {
		setPreferredVendorFilter("")
		setServiceTypeFilter([])
		setLocationFilter([])
		setZipcodeFilter([])
		setnameValueFilter([])
		setnameLabelFilter([])
	}

	const getVendorOptions = (services) => {
		return (
			services &&
			services
				.map((service) => ({
					label: service.name,
					value: service.userId,
				}))
				.filter(
					(option, index, self) =>
						index === self.findIndex((t) => t.value === option.value)
				)
		)
	}

	const getZipCodeOptions = (services) => {
		return (
			services &&
			services
				.filter((service) => service.zipCodesServiced)
				.map((service) =>
					service.zipCodesServiced.map((zip) => ({
						label: zip,
						value: zip,
					}))
				)
				.flat()
				.filter(
					(option, index, self) =>
						index ===
						self.findIndex(
							(t) => t.label === option.label && t.value === option.value
						)
				)
		)
	}

	useEffect(() => {
		const getPreferredServices = async () => {
			let userResults = []
			let teamResults = []
			try {
				for (const serviceChunk of serviceChunkArray) {
					if (userPlus?.teamId) {
						const q = query(
							collection(db, "teamPreferredServices"),
							where("teamId", "==", userPlus.teamId),
							where("serviceId", "in", serviceChunk)
						)
						const docs = await getDocs(q)
						teamResults.push(...docs.docs.map((doc) => doc.data().serviceId))
					}

					if (userPlus.userType === "agent") {
						const q1 = query(
							collection(db, "userPreferredServices"),
							where("userId", "==", userPlus.id),
							where("serviceId", "in", serviceChunk)
						)
						const docs1 = await getDocs(q1)
						userResults.push(...docs1.docs.map((doc) => doc.data().serviceId))
					} else {
						const q1 = query(
							collection(db, "userPreferredServices"),
							where("userId", "==", userPlus.invitedBy),
							where("serviceId", "in", serviceChunk)
						)
						const docs1 = await getDocs(q1)
						userResults.push(...docs1.docs.map((doc) => doc.data().serviceId))
					}
				}
				setTeamPreferredServices(teamResults)
				setUserPreferredServices(userResults)
			} catch (error) {
				console.error("Error fetching preferred services: ", error)
			}
		}
		getPreferredServices()
	}, [serviceChunkArray])

	const getFilters = async () => {
		let zipCodesServiced = []
		let companyOptions = []
		for (const serviceChunk of serviceChunkArray) {
			const servicesQuery = query(
				collection(db, "services"),
				where("id", "in", serviceChunk)
			)

			const querySnapshot = await getDocs(servicesQuery)
			const data = querySnapshot.docs.map((doc) => doc.data())
			if (data.length > 0) {
				const vendorOptions = getVendorOptions(data)
				const zipCodeOptions = getZipCodeOptions(data)
				companyOptions.push(...vendorOptions)
				zipCodesServiced.push(...zipCodeOptions)
			}
		}

		setZipCodeFilterList([...new Set(zipCodesServiced)])
		setCompanyFilterList(
			companyOptions.filter(
				(option, index, self) =>
					index === self.findIndex((t) => t.value === option.value)
			)
		)
	}

	function chunkArray(array, size) {
		const result = []
		for (let i = 0; i < array.length; i += size) {
			result.push(array.slice(i, i + size))
		}
		return result
	}

	const getFilteredList = async () => {
		try {
			// Create a base query with the common condition for isActive
			let baseQuery = query(collection(db, "services"))

			// Get all documents that match the base query
			const querySnapshot = await getDocs(baseQuery)
			let results = querySnapshot.docs.map((doc) => ({
				...doc.data(),
			}))

			// Apply filters
			if (vendorList.length > 0) {
				results = results.filter((service) =>
					vendorList.includes(service.userId)
				)
			}

			if (serviceTypeFilter.length > 0) {
				results = results.filter((service) =>
					serviceTypeFilter.includes(service.serviceType)
				)
			}

			if (nameValueFilter.length > 0 && nameLabelFilter.length > 0) {
				results = results.filter(
					(service) =>
						nameValueFilter.includes(service.userId) &&
						nameLabelFilter.includes(service.name)
				)
			}

			if (locationFilter.length > 0) {
				results = results.filter(
					(service) =>
						service.selectLocations &&
						service.selectLocations.some((location) =>
							locationFilter.includes(location)
						)
				)
			}

			if (zipcodeFilter.length > 0) {
				results = results.filter(
					(service) =>
						service.zipCodesServiced &&
						service.zipCodesServiced.some((zipcode) =>
							zipcodeFilter.includes(zipcode)
						)
				)
			}

			// Filter based on preferred vendor filter
			if (preferredVendorFilter !== "") {
				const filterFunction =
					preferredVendorFilter === "team"
						? (service) => teamPreferredServices.includes(service.id)
						: preferredVendorFilter === "user"
						? (service) => userPreferredServices.includes(service.id)
						: (service) =>
								userPreferredServices.includes(service.id) ||
								teamPreferredServices.includes(service.id)

				results = results.filter(filterFunction)
			}

			// Create a Map to ensure uniqueness
			const uniqueServicesMap = new Map()
			results.forEach((service) => {
				uniqueServicesMap.set(service.id, service)
			})

			const filteredServices = Array.from(uniqueServicesMap.values())

			setFilteredServices(filteredServices)
			setFiltered(true)
			onClose()
		} catch (error) {
			console.error("Error filtering services:", error)
		}
	}

	const fetchServicesByVendorUidList = async (serviceChunkIds) => {
		const services = []
		for (const chunkId of serviceChunkIds[currentIndex]) {
			const servicesQuery = query(
				collection(db, "services"),
				where("id", "==", chunkId)
			)
			const doc = await getDocs(servicesQuery)

			services.push(
				...doc.docs.map((doc) => ({
					...doc.data(),
				}))
			)
		}

		if (direction === "next") {
			if (currentIndex + 1 < serviceChunkIds.length) {
				setHasMore(true)
			} else {
				setHasMore(false)
			}
		} else {
			if (currentIndex - 1 < serviceChunkIds.length) {
				setHasMore(true)
			} else {
				setHasMore(false)
			}
		}
		setFilteredServices(services)
	}

	useEffect(() => {
		const fetchServicesByVendorUidList = async (serviceChunkIds) => {
			const services = []
			for (const chunkId of serviceChunkIds[currentIndex]) {
				const servicesQuery = query(
					collection(db, "services"),
					where("id", "==", chunkId)
				)
				const doc = await getDocs(servicesQuery)

				services.push(
					...doc.docs.map((doc) => ({
						...doc.data(),
					}))
				)
			}

			if (direction === "next") {
				if (currentIndex + 1 < serviceChunkIds.length) {
					setHasMore(true)
				} else {
					setHasMore(false)
				}
			} else {
				if (currentIndex - 1 < serviceChunkIds.length) {
					setHasMore(true)
				} else {
					setHasMore(false)
				}
			}
			setFilteredServices(services)
		}

		if (serviceChunkArray.length > 0) {
			fetchServicesByVendorUidList(serviceChunkArray)
		}
	}, [currentIndex, direction, serviceChunkArray])

	const goToNext = () => {
		setCurrentIndex(currentIndex + 1)
		setDirection("next")
	}

	const goToPrevious = () => {
		setCurrentIndex(currentIndex - 1)
		setDirection("previous")
	}

	const preferredVendorOptions = [
		...(userPlus?.teamId
			? [
					{ label: "Show All Preferred Vendors", value: "both" },
					{ label: "Show Team Preferred Vendors", value: "team" },
			  ]
			: []),
		{
			label:
				userPlus?.userType === "client"
					? "Show Agent's Preferred Vendors"
					: "Show My Preferred Vendors",
			value: "user",
		},
	]

	const handleOpen = async () => {
		if (!hasOpened) {
			await getFilters()
			setHasOpened(true)
		}
		onOpen()
	}

	const handleServiceFilterChange = async (options) => {
		await logUserEvent(
			analytic_events.USER_SERVICE_FILTER_SEARCH_VENDOR_PAGE,
			userPlus.id,
			{
				searchFilter: options.map((option) => option.label).join(", "),
				agentId: userPlus.invitedBy,
				userType: userPlus.userType,
			}
		)
		setServiceTypeFilter(options ? options.map((option) => option.value) : [])
	}

	const startNewJobRequest = () => {
		if (
			userPlus?.userType === "agent" &&
			!userPlus?.activePlan &&
			userPlus?.jobRequestsMade >= JOB_REQUEST_LIMIT
		) {
			setPaywallTriggered(true)
			return
		} else {
			navigate("/job-requests/new")
		}
	}

	return (
		<>
			{userPlus?.planName === null && userPlus?.userType === "agent" && (
				<PaywallBanner location="agentVendorList" />
			)}
			<Box
				display={["flex", "flex", "flex", "none"]}
				flexDirection="column"
				m="30px 15px"
			>
				<Heading size="lg" fontWeight="bold" mb="5px">
					{!pageLoading &&
						userPlus?.userType === "client" &&
						"Protect your home with our preferred vendors!"}
					{!pageLoading && userPlus.userType === "agent" && "Skilled Vendors"}
				</Heading>
				{!pageLoading && userPlus?.userType === "client" && (
					<Text>
						You have access to {agentName} skilled vendors for all your home
						needs. Submit job requests to get estimates and schedule.{" "}
					</Text>
				)}
				{!pageLoading && userPlus?.userType !== "client" && (
					<Text>
						Organize your skilled vendor list and submit job requests.
					</Text>
				)}
				<HStack mt="10px" justifyContent="space-between">
					<Select
						placeholder="What do you need help with?"
						value={serviceTypesOptions.filter((option) =>
							serviceTypeFilter.includes(option.value)
						)}
						isMulti
						isTruncated
						options={serviceTypesOptions}
						onChange={(options) => handleServiceFilterChange(options)}
						w="100%"
					/>
					<IconButton
						ml="5px"
						aria-label="Open filters"
						icon={<Image src="/assets/filter.svg" boxSize="24px" />}
						onClick={handleOpen}
					/>
				</HStack>
				<Button
					mt="20px"
					w="100%"
					bgColor="red.500"
					color="white"
					onClick={() => startNewJobRequest()}
				>
					New Job Request
				</Button>
				{/* <Box display="flex" justifyContent="flex-end">
					<Button
						onClick={handleOpen}
						rightIcon={<UpDownIcon />}
						bgColor="background"
						mt="15px"
						mr="10px"
					>
						Filter
					</Button>
				</Box> */}
				{pageLoading && <SkeletonTable variant="vendorList" />}
				{filteredServices.length > 0 && (
					<Box mt="20px" flexDirection="column">
						{filteredServices.map((service, index) => {
							return (
								<VendorListMobileCard
									service={service}
									index={index}
									key={index}
									teamPreferredServices={teamPreferredServices}
									userPreferredServices={userPreferredServices}
									setTeamPreferred={setTeamPreferred}
									setUserPreferred={setUserPreferred}
									requestJobFromVendor={startNewJobRequest}
								/>
							)
						})}
					</Box>
				)}
				{filteredServices.length > 0 && (
					<Box display="flex" justifyContent="flex-end" gap={3} my="15px">
						<Button
							onClick={() => goToPrevious()}
							isDisabled={currentIndex === 0 || filtered}
						>
							Previous
						</Button>
						<Button
							onClick={() => goToNext()}
							isDisabled={!hasMore || filtered}
						>
							Next
						</Button>
					</Box>
				)}
				{filteredServices.length === 0 && !pageLoading && (
					<Center m="auto">
						<Stack spacing={3} alignItems="center">
							<Image src="../../assets/search_empty.png" h="200px" w="200px" />
							<Heading fontWeight="semibold" size="lg">
								No results found
							</Heading>
							<Text align="center">
								There were not any results found matching your search or you are
								not connected with skilled vendors. Invite your vendors to join!
							</Text>
						</Stack>
					</Center>
				)}
			</Box>
			<Box
				display={["none", "none", "none", "flex"]}
				mt="30px"
				flexDirection="column"
				ml={{ lg: "30px", xl: "93px" }}
				mr={{ lg: "30px", xl: "93px" }}
			>
				<HStack justifyContent="space-between">
					<Stack>
						<Heading size="lg" fontWeight="bold">
							{!pageLoading &&
								userPlus.userType === "client" &&
								"Protect your home with our preferred vendors!"}
							{!pageLoading &&
								userPlus.userType === "agent" &&
								"Skilled Vendors"}
						</Heading>
						{!pageLoading && userPlus.userType === "client" && (
							<Text display="flex">
								{"You have access to"}
								&nbsp;
								<Text fontWeight="bold" color="red.500">
									{agentName + "'s"}
								</Text>
								&nbsp;
								{
									"skilled vendors for all your home needs. Submit job requests to get estimates and schedule."
								}
							</Text>
						)}
						{!pageLoading && userPlus.userType !== "client" && (
							<Text>
								Organize your skilled vendor list and submit job requests.{" "}
							</Text>
						)}
					</Stack>
					<Stack>
						<Button onClick={() => startNewJobRequest()}>
							New Job Request
						</Button>
					</Stack>
				</HStack>
				<Box display="flex" justifyContent="space-between" gap={4}>
					<Box mt="15px" w="600px">
						<Select
							placeholder="What do you need help with?"
							value={serviceTypesOptions.filter((option) =>
								serviceTypeFilter.includes(option.value)
							)}
							isMulti
							options={serviceTypesOptions}
							onChange={(options) => {
								setServiceTypeFilter(options.map((option) => option.value))
							}}
						/>
					</Box>
					<Box>
						<Button
							onClick={() =>
								setClearFilters((prevClearFilters) => !prevClearFilters)
							}
							mt="15px"
							mr="10px"
						>
							Clear Filters
						</Button>
						<Button
							onClick={handleOpen}
							rightIcon={<UpDownIcon />}
							bgColor="blue.900"
							color="white"
							mt="15px"
						>
							Filter
						</Button>
					</Box>
				</Box>
				{pageLoading && <SkeletonTable variant="vendorList" />}
				{filteredServices.length > 0 && !pageLoading && (
					<Table variant="simple" mt={4}>
						<Thead>
							<Tr>
								{userPlus?.userType !== "client" && (
									<Th>
										{userPlus?.isAdmin
											? "Team Preferred Vendor"
											: "My Preferred Vendor"}
									</Th>
								)}
								{userPlus?.teamId && !userPlus?.isAdmin && <Th></Th>}
								<Th>Company Name</Th>
								<Th>Service Type</Th>
								<Th>Number of Job Responses</Th>
								<Th>Google Review</Th>
							</Tr>
						</Thead>
						<Tbody>
							{filteredServices.map((service, index) => (
								<Tr key={service.id}>
									{userPlus?.isAdmin && (
										<Td m="auto">
											<StarIcon
												onClick={() =>
													setTeamPreferred(service.id, service.userId)
												}
												color={
													teamPreferredServices.includes(service.id)
														? "gold"
														: "grey"
												}
											/>
										</Td>
									)}
									{userPlus?.userType !== "client" && !userPlus?.isAdmin && (
										<Td m="auto">
											<StarIcon
												onClick={() =>
													setUserPreferred(service.id, service.userId)
												}
												color={
													userPreferredServices.includes(service.id)
														? "gold"
														: "grey"
												}
											/>
										</Td>
									)}
									{userPlus?.teamId && !userPlus.isAdmin && (
										<Td m="auto" width="320px">
											{!userPlus?.isAdmin &&
												teamPreferredServices.includes(service.id) && (
													<Tag
														width="125px"
														borderRadius="full"
														variant="solid"
														bgColor="darkBlue"
													>
														<TagLabel>Team Preferred</TagLabel>
													</Tag>
												)}
											{!userPlus?.isAdmin &&
												userPlus?.userType === "agent" &&
												userPreferredServices.includes(service.id) && (
													<Tag
														width="125px"
														borderRadius="full"
														variant="solid"
														bgColor="green"
														ml={
															!userPlus?.isAdmin &&
															teamPreferredServices.includes(service.id)
																? "5px"
																: "0"
														}
													>
														<TagLabel>Recommended</TagLabel>
													</Tag>
												)}

											{userPlus?.userType === "client" &&
												userPreferredServices.includes(service.id) && (
													<Tag
														width="125px"
														borderRadius="full"
														variant="solid"
														bgColor="green"
														ml={
															!userPlus?.isAdmin &&
															teamPreferredServices.includes(service.id)
																? "5px"
																: "0"
														}
													>
														<TagLabel>Agent Preferred</TagLabel>
													</Tag>
												)}
										</Td>
									)}
									<Td>
										<Box display="flex">
											<Text fontWeight="semibold">{service.name}</Text>
											{service.subscriptionStatusActive && (
												<VerifiedBadge id={index} />
											)}
										</Box>
										<Text>
											{service.contactEmail
												? service.contactEmail
												: formatPhoneNumber(service.phoneNumber)}
										</Text>
									</Td>
									<Td>{service.serviceType}</Td>
									<Td>
										{service.jobResponseCount ? service.jobResponseCount : 0}
									</Td>
									<Td>
										{service.googleRating && service.googleReviewCount && (
											<Box display="flex">
												<Image
													src="/assets/google_logo.png"
													alt="google logo"
													w="20px"
													mr="10px"
												/>
												<StarRating
													rating={service.googleRating}
													reviewCount={service.googleReviewCount}
												/>
											</Box>
										)}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				)}
				{filteredServices.length === 0 && !pageLoading && (
					<Center m="auto" mb="40px">
						<Stack spacing={3} alignItems="center">
							<Image src="../../assets/search_empty.png" h="200px" w="200px" />
							<Heading fontWeight="semibold" size="lg">
								No results found
							</Heading>
							<Text align="center">
								There were not any results found matching your search or you are
								not connected with skilled vendors. Invite your vendors to join!
							</Text>
						</Stack>
					</Center>
				)}
				{filteredServices.length > 0 && (
					<Box display="flex" justifyContent="flex-end" gap={3} my="15px">
						<Button
							onClick={() => goToPrevious()}
							isDisabled={currentIndex === 0 || filtered}
						>
							Previous
						</Button>
						<Button
							onClick={() => goToNext()}
							isDisabled={!hasMore || filtered}
						>
							Next
						</Button>
					</Box>
				)}
			</Box>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "full", lg: "2xl" }}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Filter Vendors</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack gap={2}>
							<Box>
								<Select
									placeholder="City"
									value={locationOptions.filter((option) =>
										locationFilter.includes(option.value)
									)}
									isMulti
									options={locationOptions}
									onChange={(options) => {
										setLocationFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Zip Codes"
									value={zipCodeFilterList.filter((option) =>
										zipcodeFilter.includes(option.value)
									)}
									isMulti
									options={zipCodeFilterList}
									onChange={(options) => {
										setZipcodeFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Company Name"
									isMulti
									value={
										nameValueFilter.length > 0
											? companyFilterList.find((option) =>
													nameValueFilter.includes(option.value)
											  )
											: null
									}
									options={companyFilterList}
									onChange={(options) => {
										handleCompanyFilterChange(options)
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Preferred Vendors"
									value={preferredVendorOptions.filter(
										(option) => option.value === preferredVendorFilter
									)}
									options={preferredVendorOptions}
									onChange={(e) => {
										setPreferredVendorFilter(e.value)
									}}
								/>
							</Box>
						</Stack>
					</ModalBody>
					<ModalFooter margin="auto" w="100%">
						<Stack
							direction={{ base: "column", md: "row" }}
							w={{ base: "100%", md: null }}
							justifyContent="center"
						>
							<Button
								bgColor="red.500"
								color="white"
								onClick={getFilteredList}
								w={{ base: "100%", md: "125px" }}
							>
								Apply Filters
							</Button>
							<Button
								onClick={() =>
									setClearFilters((prevClearFilters) => !prevClearFilters)
								}
								mt={{ base: "15px", md: "0px" }}
								w={{ base: "100%", md: "125px" }}
							>
								Clear Filters
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AgentPayWallModal
				isOpen={paywallTriggered}
				setPaywallTriggered={setPaywallTriggered}
			/>
		</>
	)
}
