import { useEffect, useState, useContext } from "react"
import DatePicker from "react-datepicker"
import Autocomplete from "react-google-autocomplete"
import { useDisclosure } from "@chakra-ui/react"
import {
	Button,
	Center,
	Heading,
	FormErrorMessage,
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	IconButton,
	Stack,
	Spinner,
	Tooltip,
} from "@chakra-ui/react"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Select } from "chakra-react-select"
import { query, collection, where, getDocs } from "firebase/firestore"
import { db } from "../firebase"
import { ReactMultiEmail } from "react-multi-email"
import "react-multi-email/dist/style.css"
import "../select.css"
import "../date-picker.css"
import "../email-input.css"
import { UserContext } from "../Contexts/UserContext"

export const JobRequestGeneralInformation = ({
	formData,
	formError,
	handleChange,
	handleGenericSelectChange,
	clientList,
	setClientList,
	setFormData,
	verifyJobRequestGeneralInformation,
}) => {
	const { userPlus } = useContext(UserContext)
	const [teamMembersSelect, setteamMembersSelect] = useState([])
	const [pageLoading, setPageLoading] = useState(false)
	const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
	const [focused, setFocused] = useState(false)
	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY

	const handleOnBehalfOf = (selectedOptions) => {
		setFormData({
			...formData,
			teamMemberId: selectedOptions,
		})
	}
	useEffect(() => {
		setPageLoading(true)
		const getTeamMembers = async () => {
			if (userPlus.teamId !== null && userPlus.isAdmin) {
				try {
					// Query users with the specified teamId
					const q = query(
						collection(db, "users"),
						where("teamId", "==", userPlus.teamId),
						where("userType", "==", "agent")
					)
					const doc = await getDocs(q)

					const teamMembersSelect = doc.docs.map((doc) => {
						return { label: doc.get("email"), value: doc.get("id") }
					})
					setteamMembersSelect(teamMembersSelect)
				} catch (error) {
					console.error("Error fetching user emails:", error)
				}
			}
		}
		const getClients = async () => {
			try {
				let q
				if (userPlus.isAdmin) {
					q = query(
						collection(db, "agentClient"),
						where("teamId", "==", userPlus.teamId)
					)
				} else {
					q = query(
						collection(db, "agentClient"),
						where("agentId", "==", userPlus.id)
					)
				}
				const doc = await getDocs(q)
				const chunk = (arr, size) =>
					Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
						arr.slice(i * size, i * size + size)
					)

				const clientIds = doc.docs.map((doc) => doc.data().clientId)
				const clientIdsChunks = chunk(clientIds, 30)
				let clientListCreate = []

				for (const ids of clientIdsChunks) {
					const q2 = query(collection(db, "users"), where("id", "in", ids))
					const doc2 = await getDocs(q2)
					const chunkClientList = doc2.docs.map((doc) => ({
						label: doc.data().email,
						value: doc.data().id,
					}))
					clientListCreate = [...clientListCreate, ...chunkClientList]
				}

				setClientList(clientListCreate)
			} catch (error) {
				console.error("Error fetching client emails:", error)
			}
		}
		getClients()
		getTeamMembers()
		setPageLoading(false)
	}, [userPlus])

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
					<Heading size="lg" fontWeight="bold" mt="30px">
						{" "}
						Loading Job Request{" "}
					</Heading>
				</Stack>
			</Center>
		)
	}

	return (
		<>
			<Center
				flexDirection="column"
				maxW="629px"
				margin={{ base: "15px", md: "auto" }}
				gap={3}
			>
				<FormControl isInvalid={formError && formData.locationAddress === ""}>
					<FormLabel>Location Address</FormLabel>
					<Autocomplete
						isInvalid={formError && formData.locationAddress === ""}
						style={{
							width: "100%",
							height: "40px",
							borderRadius: "5px",
							outline: "2px solid transparent",
							border:
								formError && formData.locationAddress === ""
									? "1px solid red"
									: "1px solid #e2e2ea",
							paddingLeft: "15px",
						}}
						apiKey={apiKey}
						onPlaceSelected={(place) => {
							handleGenericSelectChange(
								{ value: place.formatted_address },
								"locationAddress"
							)
						}}
						options={{ types: [] }}
					/>
					{formError && formData.locationAddress === "" && (
						<FormErrorMessage>Location address is required.</FormErrorMessage>
					)}
				</FormControl>
				{(userPlus.userType === "agent" || userPlus.userType === "TC") && (
					<>
						<FormControl
							isInvalid={formError && formData.clientIds.length === 0}
						>
							<FormLabel>Clients</FormLabel>
							<Select
								isMulti
								border="1px solid #E2E2EA"
								placeholder="Select clients"
								options={clientList}
								name="clientIds"
								value={
									formData.clientIds.length > 0
										? clientList.filter((option) =>
												formData.clientIds.includes(option.value)
										  )
										: null
								}
								onChange={(e) => handleGenericSelectChange(e, "clientIds")}
							/>
							{formError && formData.clientIds === "" ? (
								<FormErrorMessage>Client is required.</FormErrorMessage>
							) : null}
						</FormControl>
						<FormControl
							isInvalid={
								formError &&
								formData.clientEmailAddress.length +
									formData.clientIds.length ===
									0
							}
						>
							<FormLabel>
								Add Clients{" "}
								<Tooltip
									label="Add your clients to organize your job requests by clients. Quiplo will never contact your client without you inviting them. Clients will have access to their job requests and vendor estimates.
							"
									fontSize="sm"
									isOpen={isOpen}
								>
									<IconButton
										variant="tooltip"
										height="auto"
										onMouseEnter={onOpen}
										onMouseLeave={onClose}
										onClick={onToggle}
										icon={<InfoOutlineIcon />}
									/>
								</Tooltip>
							</FormLabel>
							<ReactMultiEmail
								placeholder="Enter email address"
								emails={formData.cleintEmailAddress}
								onChange={(_emails: string[]) => {
									handleChange("clientEmailAddress", _emails)
								}}
								autoFocus={false}
								onFocus={() => setFocused(true)}
								onBlur={() => setFocused(false)}
								getLabel={(email, index, removeEmail) => {
									return (
										<div data-tag key={index}>
											<div data-tag-item>{email}</div>
											<span data-tag-handle onClick={() => removeEmail(index)}>
												×
											</span>
										</div>
									)
								}}
							/>
							{formError &&
								formData.clientEmailAddress.length +
									formData.clientIds.length ===
									0 && (
									<FormErrorMessage>
										Client Email Address is required.
									</FormErrorMessage>
								)}
						</FormControl>
					</>
				)}
				{userPlus.isAdmin && (
					<FormControl isInvalid={formError && !formData.teamMemberIds}>
						<FormLabel>Submitted on behalf of</FormLabel>
						<Select
							border="1px solid #E2E2EA"
							placeholder="Select team member"
							options={teamMembersSelect}
							name="teamMemberId"
							value={teamMembersSelect.find(
								(option) => option.value === formData.teamMemberId
							)}
							onChange={(e) => handleOnBehalfOf(e)}
						/>
						{formError && !formData.teamMemberId ? (
							<FormErrorMessage>Team Member is required.</FormErrorMessage>
						) : null}
					</FormControl>
				)}
				<FormControl isInvalid={formError && !formData.estimatedSquareFeet}>
					<FormLabel>Estimated Square Feet</FormLabel>
					<Input
						bgColor="white"
						type="number"
						name="estimatedSquareFeet"
						placeholder="Enter estimated square feet"
						value={formData.estimatedSquareFeet}
						onChange={(e) => {
							handleChange("estimatedSquareFeet", e.target.value)
						}}
					/>
					{formError && !formData.estimatedSquareFeet ? (
						<FormErrorMessage>
							Estimated square feet is required.
						</FormErrorMessage>
					) : null}
				</FormControl>
				<FormControl isInvalid={formError && formData.areUtilitiesOn === null}>
					<FormLabel>Are utilities on?</FormLabel>
					<Select
						border="1px solid #E2E2EA"
						options={[
							{ value: true, label: "yes" },
							{ value: false, label: "no" },
						]}
						value={
							formData.areUtilitiesOn === null
								? null
								: formData.areUtilitiesOn
								? { value: true, label: "yes" }
								: { value: false, label: "no" }
						}
						onChange={(e) => handleGenericSelectChange(e, "areUtilitiesOn")}
					/>
					{formError && formData.areUtilitiesOn === null ? (
						<FormErrorMessage>Utility status is required.</FormErrorMessage>
					) : null}
				</FormControl>
				<FormControl
					isInvalid={formError && formData.isPropertyOccupied === null}
				>
					<FormLabel>Is the property occupied?</FormLabel>
					<Select
						colorScheme="white"
						options={[
							{ value: true, label: "yes" },
							{ value: false, label: "no" },
						]}
						name="isPropertyOccupied"
						value={
							formData.isPropertyOccupied === null
								? null
								: formData.isPropertyOccupied
								? { value: true, label: "yes" }
								: { value: false, label: "no" }
						}
						onChange={(e) => handleGenericSelectChange(e, "isPropertyOccupied")}
					/>
					{formError && formData.isPropertyOccupied === null ? (
						<FormErrorMessage>
							Property occupied status is required.
						</FormErrorMessage>
					) : null}
				</FormControl>
				<FormControl
					isInvalid={formError && formData.isDealUnderContract === null}
				>
					<FormLabel>Is this a deal under contract?</FormLabel>
					<Select
						border="1px solid #E2E2EA"
						options={[
							{ value: true, label: "yes" },
							{ value: false, label: "no" },
						]}
						name="isDealUnderContract"
						value={
							formData.isDealUnderContract === null
								? null
								: formData.isDealUnderContract
								? { value: true, label: "yes" }
								: { value: false, label: "no" }
						}
						onChange={(e) =>
							handleGenericSelectChange(e, "isDealUnderContract")
						}
					/>
					{formError && !formData.isDealUnderContract ? (
						<FormErrorMessage>
							Property contract status is required.
						</FormErrorMessage>
					) : null}
				</FormControl>
				{formData.isDealUnderContract && (
					<>
						<FormControl
							isInvalid={formError && formData.inspectionPeriod === null}
						>
							<FormLabel>Inspection Period</FormLabel>
							<DatePicker
								placeholderText="Select date"
								selected={formData.inspectionPeriod}
								onChange={(e) => {
									handleChange("inspectionPeriod", e)
								}}
								dateFormat="MM/dd/yyyy"
							/>
							{formError && formData.inspectionPeriod === null ? (
								<FormErrorMessage>Inspection date required.</FormErrorMessage>
							) : null}
						</FormControl>
						<FormControl isInvalid={formError && !formData.closingDate}>
							<FormLabel>Closing Date</FormLabel>
							<DatePicker
								placeholderText="Select date"
								selected={formData.closingDate}
								onChange={(e) => {
									handleChange("closingDate", e)
								}}
								dateFormat="MM/dd/yyyy"
							/>
							{formError && !formData.closingDate ? (
								<FormErrorMessage>Closing date required.</FormErrorMessage>
							) : null}
						</FormControl>
					</>
				)}
				<Button
					onClick={verifyJobRequestGeneralInformation}
					bgColor="red.500"
					color="white"
					w="100%"
					mt="10px"
					mb="40px"
				>
					Add Vendors & Services
				</Button>
			</Center>
		</>
	)
}
