import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import theme from "./theme"
import Login from "./Pages/Login"
import Reset from "./Pages/Reset"

import { NotFound } from "./Pages/NotFound"
import { Register } from "./Pages/Register"
import { Wrapper } from "./Pages/Wrapper"
import { VendorProfile } from "./Pages/VendorProfile"
import { VendorList } from "./Pages/Vendors/VendorList"
import { InsightsList } from "./Pages/Insights/InsightsList"
import { ClientInsight } from "./Pages/Insights/ClientInsight"
import { ServicesList } from "./Pages/Services/ServicesList"
import { ServiceForm } from "./Pages/Services/NewServiceForm"
import { CreateJobRequest } from "./Pages/JobRequests/JobRequestForm"
import { JobRequestResponse } from "./Pages/JobRequests/JobRequestResponse"
import { JobRequestListWrapper } from "./Pages/JobRequests/JobRequestListWrapper"
import { ClientAgentJobRequestResponsesListView } from "./Pages/JobRequests/ClientAgentJobRequestResponsesListView"
import { Invite } from "./Pages/Invite"
import { UserProvider } from "./Contexts/UserContext"

function App() {
	return (
		<UserProvider>
			<ChakraProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/register/:id" element={<Register />} />
						<Route path="/reset" element={<Reset />} />
						<Route
							path="/profile"
							element={
								<Wrapper>
									<VendorProfile />
								</Wrapper>
							}
						/>
						<Route
							path="/services"
							element={<Wrapper component={ServicesList} />}
						/>
						<Route
							path="/services/new"
							element={
								<Wrapper>
									<ServiceForm />
								</Wrapper>
							}
						/>
						<Route
							path="/job-requests"
							element={
								<Wrapper>
									<JobRequestListWrapper />
								</Wrapper>
							}
						/>
						<Route
							path="/job-requests/new"
							element={
								<Wrapper>
									<CreateJobRequest />
								</Wrapper>
							}
						/>
						<Route
							path="/job-requests/responses/:id"
							element={
								<Wrapper>
									<ClientAgentJobRequestResponsesListView />
								</Wrapper>
							}
						/>

						<Route
							path="/vendors"
							element={
								<Wrapper>
									<VendorList />
								</Wrapper>
							}
						/>
						<Route
							path="/invite"
							element={
								<Wrapper>
									<Invite />
								</Wrapper>
							}
						/>
						<Route
							path="/insights"
							element={
								<Wrapper>
									<InsightsList />
								</Wrapper>
							}
						/>
						<Route
							path="/insights/:id"
							element={
								<Wrapper>
									<ClientInsight />
								</Wrapper>
							}
						/>
						<Route
							path="/job-request/:id"
							element={
								<Wrapper>
									<JobRequestResponse />
								</Wrapper>
							}
						/>

						<Route path="/*" element={<NotFound />} />
					</Routes>
				</BrowserRouter>
				{/* <Box >
        <Login />
      </Box> */}
			</ChakraProvider>
		</UserProvider>
	)
}

export default App
